.songWrapper {
   position: relative;
}

.song {
    width: 100%;
    padding: 10px 20px;
    height: 65px;
    display: flex;
    transition: all 0.14s ease-in-out;
    &:nth-child(even) {
        background: rgba(0, 0, 0, 0.1);
    }

    

    .song_inner {
        float: left;
        order: 1;

        .song__album {
            width: 45px;
            float: left;
            margin-right: 10px;
    
            img {
                width: 100%;
                height: auto;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
        }
        


        .song__details {
            float: left;
            color: #fff;

            .song__actions__repeat {
                border: none;
                position: absolute;
                left: 3px;
                padding: 3px 4px;
                border-radius: 4px;
                top: 4px;
                opacity: 0.17;
                outline: none;
            }
            
            span.song__title {
                font-size: 13px;
                margin-bottom: 7px;
                margin-top: 3px;

                dl{
                    display: inline-block;
                    margin: 0;
                    padding: 2px 7px;
                    margin-left: 5px;
                    background: rgba(255, 255, 255, 0.12);
                    font-size: 10px;
                    border-radius: 3px;
                    color: rgba(255, 255, 255, 0.6);
                }
            }
            
            span.song__artist {
                font-size: 12px;
                opacity: 0.5;
            }
            &.repeatOn .song__actions__repeat, .song__actions__repeat:hover {
                visibility: visible;
                opacity: 1;
                background: rgba(255, 255, 255, 0.04);
                border-radius: 4px;
            }
        }
        
        .song__details > span {
            display: block;
        }
    }    
    

    .song__duration {
        padding: 12px;
        color: #fff;
        font-size: 14px;
        opacity: 0.6;
        margin-left: auto;
        order: 2;
    }
    .show_actions {
        cursor: pointer;
    }
    
    .songActionsWrapper {
        display: block;
        position: absolute;
        z-index: 2;
        right:60px;
        width: 80px;
        &>button, .show_actions {
            display: inline-block;
            visibility: hidden;
            opacity: 0;
            margin-top: 8px;
            color: #fff;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 28px;
            border-radius: 50%;
            border: none;
            margin-right: 10px;
        }
        .show_actions {
            letter-spacing: -1px;
            font-size: 14px;
            position: relative;
            padding-top: 3px;
        }
        button:hover, .show_actions:hover {
            background: rgba(255, 255, 255, 0.08);
        }
    }
    

    .song__actions {
        position: absolute;
        right:10px;
        width: 200px;
        font-size: 13px;
        background: #0f111c;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        margin-top: 0px;
        color: #ddd;
        line-height: 16px;
        z-index: 3;

        & > button {
            display: block;
            padding: 5px;
            width: 100%;
            text-align: left;
            padding: 6px 8px;
            opacity: 0.6;
            border: none;
            outline: none;
            svg {
                position: relative;
                top: -2px;
            }
        }
        & > button:nth-child(odd) {
            background: rgba(32, 30, 44, 0.4);
        }
        & > button:hover {
            background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
            opacity: 1;
        }

        .song__actions__confirm {
            width: 200px;
            min-height: 100px;
            position: absolute;
            right: 0px;
            font-size: 12px;
            top: 0px;
            padding: 10px;
            background: #0f111c;
            color: #999;
            text-align: center;

            input[type="text"] {
                border: none;
                width: 50px;
                background: rgba(255, 255, 255, 0.1);
                text-align: center;
                margin: 6px;
                padding: 3px 0;
                border-radius: 5px;
                color: #aaa;
                outline:  none;
            }

            button {
                display: inline-block;
                border: none;
                outline: none;
                background: #333;
                color: #fff;
                margin: 0 7px;
                margin-top: 10px;
                border-radius: 15px;
                padding: 3px 15px;
                opacity: 0.7;
                transition: all 0.1s linear;
            }
            button.confirm_yes {
                background: #2f9763;
            }
            button.confirm_no {
                background: #393a4b;
            }
            button:hover{
                opacity: 1;
            }

            &.song__actions__confirm-delete button.confirm_yes {
                background: #a04a4a;
            }
            &.song__actions__confirm.song__actions__confirm-duration{

                input{
                    width: 55px;
                }
                input:focus {
                    background: #4f4f52;
                    box-shadow: 0 0 3px rgba(0, 0, 0, 0.6) inset;
                }
            }
            &.song__actions__confirm.song__actions__confirm-repeat{
                input:focus {
                    background: #4f4f52;
                    box-shadow: 0 0 3px rgba(0, 0, 0, 0.6) inset;
                }
            }
            &.song__actions__confirm.song__actions__confirm-copy{
                & > span {
                    margin-bottom: 10px;
                    display: block;
                }
                .rc-select{
                    margin: 0px 10px;
                }
            }

        }
        &.song__actions.song__actions-active > button {
            opacity: 0.3;
        }
        
        &.song__actions-add button.song__actions__add {
            opacity: 1;
            background: rgba(255, 255, 255, 0.1);
        }
        &.song__actions-repeat button.song__actions__repeat {
            opacity: 1;
            background: rgba(255, 255, 255, 0.1);
        }
        &.song__actions-duration button.song__actions__duration {
            opacity: 1;
            background: rgba(255, 255, 255, 0.1);
        }
        &.song__actions-delete button.song__actions__delete {
            opacity: 1;
            background: rgba(255, 255, 255, 0.1);
        } 

    }
    
    &:hover .songActionsWrapper, &.selectedSong .songActionsWrapper{
        z-index: 5;
        &>button, .show_actions {
            visibility: visible;
            opacity: 1;
        }
    }

    &:hover, &.selectedSong {
        background: rgba(255, 255,255, 0.06);
        .show_actions {
            opacity: 1;
        }
        .song__details .song__actions__repeat {
            opacity: 0.6;
        }
    }
    &.selectedSong {
        outline: 1px solid rgba(255, 255, 255, 0.1);
        z-index: 4!important;
       .show_actions{
            background: #131420!important;
            border-radius: 3px 3px 0 0;
       }
    }

    &.active{
        background: -moz-linear-gradient(left, rgba(110, 92, 209, 0.5) 0%, #262d47 100%);
        background: -webkit-linear-gradient(left, rgba(110, 92, 209, 0.5) 0%, #262d47 100%); 
        background: linear-gradient(to right, rgba(110, 92, 209, 0.5) 0%, #262d47 100%);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);

        .song__title{
            color:#9b64ff;
        }
        .song__details .song__actions__repeat {
            color: #fff;
            background: rgba(255, 255, 255, 0.1)!important;
        }
    }
    &.active:after {
        content: "";
        width: 300px;
        height: 65px;
        position: absolute;
        z-index: -1;
        background: linear-gradient(to right, #384680 0%, transparent 100%);
        margin-left: -20px;
        margin-top: -10px;
    }

    .song__title, .song__artist, .song__duration{
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    &.song-drag{
        background: -moz-linear-gradient(left, rgba(89, 55, 182, 0.5) 0%, #262d47 100%);
        background: -webkit-linear-gradient(left, rgba(89, 55, 182, 0.5) 0%, #262d47 100%); 
        background: linear-gradient(to right, rgba(89, 55, 182, 0.5) 0%, #262d47 100%);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
        opacity: 1;
        width: calc(100% - 590px);
        min-width: 280px;
        z-index: 999999;
    }
    &.songSelected {
        background: rgba(48, 44, 77, 0.72);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
}

.songStack  {
    width: calc(100% - 590px);
    height: 67px;
    position: absolute;
    z-index: -1;

    div{
        display: block;
        width: 100%;
        height: 67px;
        position: absolute;
        z-index: 0;
        background: #2e2a51;
        top: -60px;
        margin-left: 2px;
        -webkit-transform: rotate(-1deg);
        -ms-transform: rotate(-1deg);
        transform: rotate(-1deg);
    }
    div:nth-child(2) {
        top: -57px;
        margin-left: 12px;
        transform: rotate(-1.4deg);
        background: #37306e;
        z-index: -1;
        width: 99%;
    }
}