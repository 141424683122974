
.titleBar {
    display: none;
}
.electron{
    .titleBar {
        display: block;
        -webkit-app-region: 'drag';
        width: 100%;
        float: left;
        text-align: right;
        position: fixed;
        z-index: 99999999;
        min-height: 42px;
        cursor: move;
        background: rgba(32, 32, 48, 0.5);
        top: 0px;

        .titleBar__minimize, .titleBar__maximize, .titleBar__close {
            display: inline-block;
            margin: 8px 5px;
            min-width: 40px;
            min-height: 25px;
            text-align: center;
        }
        .titleBar__unmax{
            display: none;
        }

        button {
            border: none;
            color: #fff;
            cursor: pointer;
            -webkit-app-region: no-drag;
            outline: none;
        }
    }

    header {
        margin-top: 40px;

        .quick_helpBtn{
            top: 62px;
        }
        .quickLook_wrapper video{
            max-height: 65vh;
        }
        
        *::-webkit-media-controls-panel {
            background-color: #111 !important;
        }
    }

    .logo {
        top: 30px;
        position: relative;
        margin-bottom: 30px;
    }


    #User .User__topbar{
        top: 60px;
    }

    #playlist_mini{
        top: 105px;
    }


    #SearchLibrary .SearchLibrary__wrap {
        height: calc(100vh - 190px);
        top: 60px;
    }


    //ADD SONG
    #addSong {
        top: 40px;
        height: calc(100vh - 150px);
    }
    
    .tracks .track .track_img_wrap img {
        width: 190px;
    }
    
    .tracks .track {
        width: 190px !important;
    }
    
    .tracks .track .track_content_wrap {
        width: 190px;
    }

    .tracks .track .track_content_wrap h3 {
        width: 170px;
    }

    //PLAYER
    .player__video__wrap.player__video__wrap-full{
        top: 46px;
    }


    //DISCOVER
    #discover {
        
        #discover_content {
            margin-bottom: 0px;

            .discover_content__wrap-all .tracks__pagination {
                margin-top: 10px;
            }

            .genChoices{
                margin-right: 10px;
            }

            .discover_content__wrap {
                width: 95%;
                padding: 15px 0;
                padding-bottom: 0;

                .tracks .track {
                    margin: 10px;

                    .track__addToPlaylist{
                        width: 210px;
                        margin-left: -5px;
                    }
                }
                .tracks.tracks--listView{
                    .track{
                        margin: 0;
                        width: 96%!important;
                    }
                }

            }
        }
        
    }

    //TRACKS
    .trackScroll_wrap > div {
        overflow: auto;
        will-change: transform;
        height: calc(100vh - 275px);
    }
    .trackScroll_wrap.trackScroll_wrap--pagination > div {
        height: calc(100vh - 350px);
    }

    //ARTIST
    #artist{
        .artist_back_button{
            top: 170px;
        }
    }

    .customScrollbar{
        //Always show Scroll Thumb
        &.trackScroll_wrap > div{
          width: 102.8%!important;
        }
    }
    
}




