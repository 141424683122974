.page-station{

    #RightSidebar{
        display: none;
    }
    header .menu {
      width: 400px;
   }
    main {
        width: calc(100% - 270px);
        margin-right: 0px;
    }

    .stationActiveCheck.custom_checkbox {
         // right: 400px;
         margin-top: 25px;
         color: #999;
         display: inline-block;
         margin-bottom: 0;
        label{
            transform: scale(0.7);
        }
        span {
            width: 105px;
            padding-left: 10px;
        }
    }
    .station__leave {
      margin-top: 25px;
      color: rgba(255, 255, 255, 0.6);
      font-size: 13px;
      cursor: pointer;
      display: inline-block;
      margin-right: 20px;
    }

    .station__left{
        width: calc(100% - 420px);
        float: left;

        .stationMessage {
            color: rgba(255, 255, 255, 0.6);
            text-align: center;
            margin-top: 100px;
            font-size: 12px;

            .stationMsgOnline {
                width: 10px;
                height: 10px;
                display: inline-block;
                border-radius: 50%;
                background: #6fe6b2;
                box-shadow: 0 0 5px #96ffd1;
                position: relative;
                margin: 0;
                margin-right: 8px;
                top: 1px;
            }
            button {
                cursor: pointer;
                background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                color: #fff;
                font-size: 11px;
                padding: 7px 18px;
                opacity: 1;
                display: inline-block;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                border: none;
                border-radius: 20px;
                display: block;
                margin: 0 auto;
                margin-top: 25px;
                outline: none;
                text-transform: uppercase;
                letter-spacing: 0.7px;
            }
            .cancelJoinRequestBtn {
                display: inline-block;
                background: #464461;
                padding: 4px 12px;
            }

            .stationCounter{
                display: block;
                font-size: 30px;
                margin-top: 10px;
            }
         }
         .playlist__header{
            padding: 0;
            .playlist__header_right {
               float: right;
               padding-right: 20px;
            }
            .playlist__edit {
               display: inline-block;
            }
         }
         h4 {
            color: #fff;
            letter-spacing: 0.4px;
            font-size: 14px;
            padding-left: 20px;
            margin-top: 10px;
            float: left;
            margin-bottom: 0;

            span {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                overflow: hidden;
                display: inline-block;
                position: relative;
                top: 10px;
                margin-right: 10px;

                img {
                    width: 30px;
                    height: 30px;
                }
            }
            .stationActiveIcon,  .stationInactiveIcon{
                width: 12px;
                height: 12px;
                display: block;
                position: absolute;
                background: #9292b1;
                z-index: 9;
                border-radius: 50%;
                margin-top: -4px;
                box-shadow: 0 0 5px #aaa;
            }
            .stationActiveIcon{
                background: #6fe6b2;
                box-shadow: 0 0 5px #96ffd1;
            }
        }
    

        
        .station__playlist .song {
            //position: relative!important;
            display: block;
            .song__artist{
                margin-top: -5px;
            }
            .song__duration{
               float: right;
            }

            &.futureSong {
                display: none;
            }

            .song_inner {
               width: calc(100% - 52px);
            }

            &.song--liked .song__actions__love svg {
                color: #ff5e5d;
            }

            .song__album{
                margin-right: 15px;
                overflow: hidden;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                border: 2px solid rgba(255, 255, 255, 0.4);

                img{
                    width: auto;
                    height: 100%;
                }
            }

            .song__actions {
                background: transparent;
                box-shadow: none;
                right: 70px;
                margin-top: 6px;
                width: auto;
                z-index: auto;

                .song__actions__all {
                    position: absolute;
                    top: 30px;
                    width: 130px;
                    background: #0c0e15;
                    right: 10px;
                    z-index: 4;
                    
                    &>button {
                        display: block;
                        width: 100%;
                        border: none;
                        text-align: right;
                        padding: 10px;
                        font-size: 12px;
                        &:hover {
                            background: rgba(255, 255, 255, 0.1);
                        }
                    }
                }
                .song__actions__play {
                    width: 30px;
                    background: none;
                    float: left;
                    margin-top: 3px;
                    font-size: 14px;
                }
                
                .song__actions__pause {
                    text-align: center;
                    span {
                        width: 2px;
                        height: 10px;
                        background: #fff;
                        display: inline-block;
                        margin-right: 3px;
                    }
                }

                .song__actions__love, .song__actions__more {
                    width: 40px;
                    float: left;
                    border-radius: 0;
                    padding-top: 8px;
                    letter-spacing: 2px;
                    background: none;
                    &:hover {
                        background: transparent;
                    }
                }

                &.song__actions-active.song__actions-add, &.song__actions.song__actions-active.song__actions-delete  {
                    z-index: 99;
                }
                &.song__actions-active button.song__actions__add, &.song__actions-active button.song__actions__delete {
                    border-radius: 4px;
                }
                .song__actions__confirm.song__actions__confirm-remove span {
                    display: block;
                    margin-bottom: 12px;
                    z-index: 9999;
                }
                .song__actions__confirm.song__actions__confirm-remove {
                    top: 25px;
                    right: 0px;
                }
                .song__actions__confirm.song__actions__confirm-copy {
                    top: 25px;
                }
            }

            
            .song_inner .song__details {
               width: calc(100% - 180px);
               white-space: nowrap;
               overflow: hidden;
               text-overflow: ellipsis;
                span.song__title {
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: inline-block;
                }
            }

            .song__actions__love__wrap{
                position: relative;
                width: 45px;
                float: left;

                button{
                    display: block;
                    padding: 5px;
                    width: 50px!important;
                    text-align: left;
                    padding: 6px 8px;
                    opacity: 0.6;
                    border: none;
                    outline: none;
                    font-size: 12px;
                }

                .song__actions__love__likes {
                    position: absolute;
                    z-index: 9;
                    right: 45px;
                    background: #0c0e15;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
                    border-radius: 5px;
                    width: 120px;
                    padding: 8px 6px;
                    visibility: hidden;
                    opacity: 0;
                    transition: all 0.1s linear;
    
                    img {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        margin-right: 4px;
                    }
    
                    & > div {
                        width: 100%;
                        float: left;
                    }
                }

                &:hover .song__actions__love__likes {
                    opacity: 1;
                    visibility: visible;
                }
                

            }
 
            &.song--active{
                background: -moz-linear-gradient(left, rgba(110, 92, 209, 0.5) 0%, #262d47 100%);
                background: -webkit-linear-gradient(left, rgba(110, 92, 209, 0.5) 0%, #262d47 100%); 
                background: linear-gradient(to right, rgba(110, 92, 209, 0.5) 0%, #262d47 100%);
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
        
                .song__details .song__actions__repeat {
                    color: #fff;
                    background: rgba(255, 255, 255, 0.1)!important;
                }
            }
        }

        .station__playlist  {
            margin: 25px 0;
            overflow: hidden;
            float: left;
            width: 100%;
            .loader {
                text-align: center;
                margin-top: 100px;
            }

            h5{
                margin: 0;
                padding: 10px 20px;
                color: rgba(255, 255, 255, 0.5);
                font-weight: normal;
                letter-spacing: 1px;
                text-transform: uppercase;
                background: rgba(0, 0, 0, 0.2);
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            }

            .station_playlist_wrap > div {
                overflow: auto;
                will-change: transform;
                height: calc(100vh - 250px);
                width: 100%!important;
                color: #514cf8;
            }
        }
    }

    .station__right{
        padding: 70px 0 0 0;
        width: 420px;
        float: right;
        position: fixed;
        right: 0px;
        top: 0;
        height: 100vh;
        background-image: url('../../assets/rightShadow.png');
        background-size: 100%;
        z-index: 9;

        h4{
            padding-left: 20px;
            font-size: 13px;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-top: 25px;
        }

        .station__chat{
            h4 span{
                display: none;
            }
        }
    }



    .station__player{
        float: left;
        width: 100%;
        box-sizing: border-box;
        position: fixed;
        bottom: 0px;
        background: rgba(32, 32, 48, 1);
        height: 110px;
        left: 0;
        z-index: 12;



        .station__player__wrap {
            position: absolute;
            width: 270px;
            left: 0;
            bottom: 0;
            height: 250px;
            z-index: 9;

            button{
                outline: none;
            }

            .station__player__inner {
                width: 270px;
                height: 140px;
                background: #000;
                position: relative;
                overflow: hidden;
            }
            .station__player__loading{
                position: absolute;
                top: 22%;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 10;
                color: #fff;
                text-align: center;
            }
            .station__player__block{
                width: 270px;
                height: 140px;
                position: absolute;
                z-index: 8;

                .station__player__block__play {
                    display: none;
                    width: 50px;
                    height: 50px;
                    margin: 0 auto;
                    margin-top: 22%;

                    .station__player__block__pauseIcon {
                        text-align: center;
                        cursor: pointer;

                        span {
                            width: 4px;
                            height: 18px;
                            background: #fff;
                            display: inline-block;
                        }
                        span:first-child {
                            margin-right: 5px;
                        }
                    }
                }
                &:hover .station__player__block__play{
                    display: block;
                }
            }
            .soundcloud__cover {
                width: 270px;
                height: 140px;
                position: absolute;
                z-index: 7;
                overflow: hidden;
                background-size: contain;
                img {
                    height: 80%;
                    width: auto;
                    margin: 0 auto;
                    display: block;
                    margin-top: 6%;
                    box-shadow: 0 0 30px rgba(0, 0, 0, .3);
                    position: absolute;
                    z-index: 4;
                    left: 0;
                    right: 0;
                    border-radius: 50%;
                }
                img.soundcloud__cover__bg {
                    position: absolute;
                    z-index: 1;
                    width: 104%;
                    top: 0;
                    height: auto;
                    margin-top: -30%;
                    filter: blur(5px);
                    opacity: 0.7;
                    margin-left: -2%;
                    border-radius: 0;
                }
                span{
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    font-size: 11px;
                    letter-spacing: 0px;
                    color: #fff;
                }
            }


            .station__player__block__paused {
                width: 100%;
                height: 140px;
                position: absolute;
                z-index: 8;
                overflow: hidden;
                background: #0d0e16;

                & > img {
                    position: absolute;
                    width: 140%;
                    top: -28%;
                    z-index: -1;
                    filter: blur(5px);
                    opacity: 0.6;
                }
                p {
                    color: rgba(255, 255, 255, 0.7);
                    font-size: 13px;
                    text-align: center;
                    margin-top: 15%;
                    img {
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                    }
                }
                .station__player__block__playIcon {
                    text-align: center;
                    color: #fff;
                    font-size: 22px;
                    cursor: pointer;
                    width: 40px;
                    margin: 0 auto;
                }

            }
            


            #react-player__station {
                pointer-events: none;
            }

            .player__nowplaying {
                color: #999;
                font-size: 13px;
                padding: 10px;

                .player__songInfo__artist {
                    display: block;
                    margin-top: 5px;
                    .player__songInfo__artist__avatar {
                        width: 25px;
                        height: 25px;
                        margin-right: 8px;
                        overflow: hidden;
                        border-radius: 50%;
                        display: inline-block;
                        margin-top: 0px;
                        vertical-align: bottom;
                        position: relative;
                        top: 6px;
                        img {
                            height: 100%;
                        }
                    }

                }
                .player__songInfo__title {
                    color: #fff;
                }
                .player__songInfo__count {
                    margin-right: 6px;
                    font-size: 11px;
                }

                .player__nowplaying__songInfo__details {
                    width: 100%;
                    float: left;
                }
                
                
                
            }

            .channelMaxScreen__btn, .channelFullScreen__btn {
                position: absolute;
                top: 10px;
                right: 10px;
                color: #fff;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: none;
                background: rgba(255, 255, 255, 0.2);
                z-index: 9;
            }
            .channelFullScreen__btn{
                display: none;
                right: 50px;
            }
        }

        &.station__player--max {
            .station__player__wrap {
                position: fixed;
                width: calc(100% - 418px);
                height: calc(100vh - 192px);
                background: #191c2c;
                top: 80px;
            }
            .station__player__inner {
                width: 100%;
                height: 60vh;
            }
            .station__player__block{
                width: 100%;
                height: 60vh;
                position: absolute;
                z-index: 8;
            }
            .soundcloud__cover{
                width: 100%;
                height: 60vh;
            }
            .station__player__block__paused{
                height:60vh;

                & > img {
                    width: 140%;
                    top: -27%;
                    z-index: -1;
                    filter: blur(15px);
                }
            }
            .station__player__loading{
                top:30%;
            }

            .channelMaxScreen__btn{
                display: none;
            }
            &:hover{
                .channelMaxScreen__btn, .channelFullScreen__btn{
                    display: block;
                }
            }

            .station__player__block .station__player__block__play{
                bottom: 42%;
                position: absolute;
                left: 0;
                right: 0;
                .station__player__block__pauseIcon{
                    transform: scale(1.7);
                }
            }

            .station__player__block__paused .station__player__block__playIcon{
                font-size: 38px;
            }

            .player__nowplaying__songInfo__actions {
                float: right;
                width: 30%;
                text-align: right;

                .song__actions__love__wrap{
                    position: relative;
                    width: 45px;
                    display: inline-block;
    
                    button{
                        display: block;
                        padding: 5px;
                        width: 70px!important;
                        text-align: left;
                        padding: 6px 8px;
                        opacity: 0.6;
                        border: none;
                        outline: none;
                        font-size: 16px;
                    }
    
                    .song__actions__love__likes {
                        position: absolute;
                        z-index: 9;
                        right: 45px;
                        margin-top: -32px;
                        text-align: left;
                        background: #0c0e15;
                        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
                        border-radius: 5px;
                        width: 120px;
                        padding: 8px 6px;
                        visibility: hidden;
                        opacity: 0;
                        transition: all 0.1s linear;
        
                        img {
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;
                            margin-right: 4px;
                        }
        
                        & > div {
                            width: 100%;
                            float: left;
                        }
                    }
    
                    &:hover .song__actions__love__likes {
                        opacity: 1;
                        visibility: visible;
                    }

                }
            }

            .soundCloudCover {
                width: 100%;
                height: 68vh;
                .soundCloudCover__wave {
                    width: 350px;
                    height: 350px;
                    left: 55%;
                    top: auto;
                    bottom: 10%;
                }
                .soundCloudCover__title {
                    width: 200px;
                    height: 200px;
                    padding: 40px;
                    padding-top: 66px;
                    top: auto;
                    bottom: 24%;
                    font-size: 16px;
                }
            }

        }
        

        .station__bottom {
            width: 100%;
            height: 110px;
    
            .station__bottom__wrap {
                height: 110px;
                overflow: hidden;
                position: relative;
            }
    
            .station__bottom__inner {
                width: 450px;
                margin: 0 auto;
                text-align: center;
                color: #3f3576;
                position: relative;
                left: 50px;
                padding: 20px;
    
                &> div {
                    display: inline-block;
                    margin-right: 8px;
                    color: #bababa;
                }
            }
    
            .player_glow {
                opacity: 0.4;
                position: absolute;
                z-index: -1;
                width: 600px;
                height: 550px;
                margin: 0 auto;
                margin-top: -450px;
                left: 0;
                right: 0;
                background: radial-gradient(ellipse at center, rgba(139, 135, 229, 0.25) -10%, rgba(240, 232, 251, 0) 74%, rgba(255, 255, 255, 0) 100%);
            }
    
            .player__play{
                background: linear-gradient(to bottom, #28282a 0%,#080808 100%);
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.6) inset;
                position: relative;
                z-index: 2;
                width: 55px;
                height: 55px;
                padding: 7px;
                box-sizing: initial;
                border-radius: 50%;
                box-shadow: 0 4px 2px rgba(0, 0, 0, 0.8) inset;
                cursor: pointer;
    
                span {
                    display: block;
                    width: 55px;
                    height: 47px;
                    padding-top: 8px;
                    box-sizing: initial;
                    background: linear-gradient(to bottom, #3c3c3c 0%, #232222 100%);
                    cursor: pointer;
                    border-radius: 50%;
                    svg{
                        display: inline-block;
                        margin-left: 3px;
                        margin-top: -2px;
                    }
                    
                }
    
                i.play_outline {
                    width:57px;
                    height: 47px;
                    display: block;
                    position: absolute;
                    z-index: 2;
                    top: 6px;
                    background: url("../../assets/play_outline.png");
                }
            }
    
    
            .addToStation, .stationRepeat, .player__volume {
                background: linear-gradient(to bottom, #28282a 0%, #080808 100%);
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.6) inset;
                position: relative;
                z-index: 2;
                width: 30px;
                height: 30px;
                padding: 5px;
                box-sizing: initial;
                border-radius: 50%;
                display: inline-block;
                margin-right: 8px;
                color: #bababa;
    
                button {
                    display: block;
                    width: 19px;
                    height: 27px;
                    padding-top: 2px;
                    -webkit-box-sizing: initial;
                    box-sizing: initial;
                    border: none;
                    border-radius: 50%;
                    background: linear-gradient(to bottom, #3c3c3c 0%, #232222 100%);
                    cursor: pointer;
                    outline: none;
                }
            }

            .stationRepeat-active{
                button {
                    color: rgba(96, 71, 248, 1)!important;
                    box-shadow: 0 0 10px rgba(96, 71, 248, 0.7) inset;
                }
            }
            

            .player__volume span{
                position: absolute;
                margin-left: -8px;
                font-size: 16px;
                color: #805bff;
                font-weight: bold;
                margin-top: -2px;

                // button {
                //     display: block;
                //     width: 19px;
                //     height: 27px;
                //     padding-top: 2px;
                //     -webkit-box-sizing: initial;
                //     box-sizing: initial;
                //     border: none;
                //     border-radius: 50%;
                //     background: linear-gradient(to bottom, #3c3c3c 0%, #232222 100%);
                //     cursor: pointer;
                // } 
            }

            .player__volume__control {
                width: 70px;
                height:20px;
                padding: 1px;
                background: -moz-linear-gradient(top, #28282a 0%, #080808 100%);
                background: -webkit-linear-gradient(top, #28282a 0%,#080808 100%);
                background: linear-gradient(to bottom, #28282a 0%,#080808 100%);
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.6) inset;
                border-radius: 0 10px 10px 0;
                margin-left: -18px;
                top: 0px;
                padding-left: 10px;
                padding-right: 8px;
                position: relative;
                z-index: 1;
            
                input {
                    width: 100%;
                    position: relative;
                    top: -2px;
                }
            
                input[type=range]{
                    z-index: 3;
                    -webkit-appearance: none;
                    cursor: pointer;
                }
                input[type=range]::-webkit-slider-runnable-track {
                    width: 100%;
                    height: 7px;
                    background: rgba(255, 255, 255, 0.1);
                    border: none;
                }
                input[type=range]::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    border: none;
                    height: 7px;
                    width: 10px;
                    background: rgba(255, 255, 255, 0.1);
                    margin-top: 0px;
                    margin-left:0px
                }
                input[type=range]:focus {
                    outline: none;
                }
            
                input[type=range]::-moz-range-track {
                    width: 100%;
                    height: 7px;
                    background: rgba(255, 255, 255, 0.1);
                    border: none;
                }
                input[type=range]::-moz-range-thumb {
                    border: none;
                    height: 7px;
                    width: 10px;
                    background: rgba(255, 255, 255, 0.1);
                    margin-top: 0px;
                    margin-left:0px
                }
                input[type=range]::-moz-focusring{ 
                    outline: none;
                    border:none;
                }
            
            }
            
            @supports (-moz-appearance:none) {
                .player__controls_inner .player__volume__control { 
                    top: 6px;
                } 
            }
    
            .station__bottom__addModal {
                width: 190px;
                position: fixed;
                bottom: 80px;
                right: 235px;
                margin: 0 auto;
                left: 100px;
                background: #0c0e15;
                border-radius: 4px;
                padding: 10px;
                color: #999;
                font-size: 12px;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
                button {
                    border: none;
                    padding: 8px 6px;
                    width: 100%;
                    text-align: left;
                    outline: none;
                }
            }

        }
        .SoundCloudImporter {

            .Modal__content{
                min-height: 220px;
            }

            .SoundCloudImporter__error{
                font-size: 13px;
                color: #e47676;
                background: rgba(212, 123, 123, 0.2);
                border: 1px solid rgba(138, 55, 55, 0.6);
                border-radius: 4px;
                padding: 10px;
                position: relative;

                button {
                    padding: 0;
                    border: none;
                    color: #c06464;
                    position: absolute;
                    right: 10px;
                    margin-top: 5px;
                    outline: none;
                }
            }

            .SoundCloudImporter__form {
                width: 100%;
                margin-bottom: 25px;
                position: relative;
                input {
                    width: 100%;
                    padding: 10px;
                    border: 1px solid #333;
                    color: #fff;
                    font-size: 16px;
                }
                button {
                    position: absolute;
                    right: 10px;
                    padding: 6px;
                    border: none;
                    color: #888;
                }
            }

            #react-player__soundCloud {
                display: none;
            }
            
            .SoundCloudImporter__song__content {
                color: #fff;
                line-height: 1.6em;
            }
            
            .SoundCloudImporter__song {
                width: 100%;
                padding: 15px;
                margin-bottom: 20px;
                background: rgba(255, 255, 255, .05);

                h3 {
                    color: #fff;
                    text-transform: none;
                    margin-bottom: 5px;
                    letter-spacing: 0.5px;
                    font-weight: bold;
                    font-size: 14px;
                    margin-top: 0;
                }

                .SoundCloudImporter__song__cover {
                    width: 110px;
                    height: 110px;
                    overflow: hidden;
                    margin-right: 15px;
                    float: left;
                    border-top: 3px solid #f50;
                    position: relative;
                    img {
                        width: 100%;
                        height: auto;
                    }
                    span {
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                        padding: 3px;
                        text-align: center;
                        color: #fff;
                        background: rgba(147, 78, 40, 0.86);
                        text-transform: uppercase;
                        font-size: 11px;
                        letter-spacing: 1px;
                    }
                }
                .SoundCloudImporter__song__artist, .SoundCloudImporter__song__duration {
                    opacity: 0.7;
                    margin-bottom: 4px;
                }

                button{
                    background: #f50;
                    border-radius: 3px;
                    border: none;
                    font-size: 11px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    padding: 2px 7px;
                    margin-top: 8px;
                }
                
            }

              //Always show Scroll Thumb
            .customScrollbar > div{
                color: #514cf8;
            }
            .customScrollbar > div::-webkit-scrollbar {
                background: rgba(0, 0, 0, 0.2);
                border: 0px solid rgba(0, 0, 0, 0.2);
            }
            .customScrollbar > div::-webkit-scrollbar-thumb{
                border: 3px solid rgba(0, 0, 0, 0.2);
                box-shadow: inset 0 0 0 3px;
            }
            
        }

    }

    
    .pendingUsers {
        position: fixed;
        right: 30px;
        bottom: 130px;
        z-index: 9999;
        width: 300px;

        .pendingUser {
            background: #0c0e15;
            border-radius: 4px;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
            padding: 20px;
            color: #999;
            font-size: 13px;

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 5px;
            }
            button {
                margin: 0 5px;
                border: none;
                background: #2d2e3c;
                padding: 5px 10px;
                border-radius: 3px;
                text-transform: uppercase;
                font-size: 11px;
                color: #fff;
                letter-spacing: 0.5px;
            }
            p {
                text-align: right;
                margin-bottom: 0;
            }
            .pendingUser__approveJoin {
                background: #34c291;
            }
            .pendingUser__error {
                background: rgba(150, 72, 72, 0.3);
                border-radius: 3px;
                color: #ca9494;
                padding: 6px 8px;
                text-align: left!important;
                font-size: 12px;
                border: 1px solid #824545;
                button {
                    background: #824545;
                    margin-top: 5px;
                    letter-spacing: 1px;
                    margin-left: 0;
                }
            }
        }
    }

    .station__connectionLost{
        position: fixed;
        right: 0;
        left: 0;
        margin:0 auto;
        top: 160px;
        z-index: 9999;
        width: 45%;
        color: #999;
        font-size: 13px;
        background: #0c0e15;
        border-radius: 4px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
        padding: 20px;
        text-align: center;

        button {
            margin: 0 5px;
            border: none;
            background: #2d2e3c;
            padding: 5px 10px;
            border-radius: 3px;
            text-transform: uppercase;
            font-size: 11px;
            color: #fff;
            letter-spacing: 0.5px;
        }
    }

    .stationUsers {
        position: absolute;
        z-index: 99;
        right: 20px;

        
        .usersMini {
            float: right;
            padding-right: 10px;
            border-radius: 24px 4px 0 0;
            cursor: pointer;

            .userMini__thumbs {
                display: inline-block;
            }
            .userMini__counts {
                display: inline-block;
                font-size: 13px;
                color: #999;
                text-transform: uppercase;
                margin-left: 5px;
                position: relative;
                top: -6px;
            }

            &.usersMini--active{
                background: #0c0e15;
            }
        }
        


        .stationUser {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            overflow: hidden;
            display: inline-block;
            margin-left: -8px;

            img {
                height: 100%;
                width: auto;
            }
        }

        .stationUsers__all {
            position: absolute;
            right: 0px;
            width: 300px;
            background: #0c0e15;
            border-radius: 4px;
            padding: 15px;
            margin-top: 25px;

            .stationUsers__all__user__left {
                width: 50px;
                height: 50px;
                margin-right: 15px;
                float: left;
                .stationUsers__all__user__avatar{
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    overflow: hidden;
                    .stationUsers__all__user__fullAccess {
                        position: absolute;
                        z-index: 3;
                        width: 20px;
                        height: 20px;
                        padding-top: 0px;
                        background: #ffb700;
                        text-align: center;
                        border-radius: 50%;
                        display: inline-block;
                        line-height: 20px;
                        color: #8b6b18;
                        font-size: 18px;
                    }
                }
                .stationUsers__all__user__pending, .stationUsers__all__user__owner {
                    font-size: 11px;
                    color: #fff;
                    background: #ffab00;
                    padding: 1px 5px;
                    border-radius: 2px;
                    margin-top: -5px;
                    display: block;
                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    box-sizing: unset;
                    width: 45px;
                }

                .stationUsers__all__user__time {
                    font-size: 11px;
                    color: rgba(255, 255, 255, 0.5);
                    padding: 1px 3px;
                    margin-top: 1px;
                    display: block;
                    position: relative;
                    text-align: center;
                    box-sizing: unset;
                    width: 62px;
                    background: transparent;
                    margin-left: -11px;
                }
                
                .stationUsers__all__user__owner {
                    background: #514bb7;
                }
            }

            .stationUsers__all__user__right {
                float: left;
                width: calc(100% - 65px);
                text-align: left;
            }
            
            .stationUsers__all__user {
                width: 100%;
                float: left;
                padding-bottom: 3px;
                margin-bottom: 12px;
                border-bottom: 1px solid #222;

                &:last-child {
                    border-bottom: none;
                }
                p {
                    color: #888;
                    font-size: 12px;
                }
                button {
                    display: inline-block;
                    border: none;
                    background: #2d2e3c;
                    color: #aaa;
                    padding: 3px 8px;
                    margin-right: 4px;
                    border-radius: 3px;
                    margin-left: 3px;
                }
                h4 {
                    color: rgba(255, 255, 255, 1);
                    font-size: 13px;
                    padding-left: 0px;
                    margin-top: 10px;
                    margin-bottom: 10px;

                    span {
                        display: inline-block;
                        font-size: 11px;
                        text-transform: none;
                        opacity: 0.5;
                        margin-left: 6px;
                        
                    }
                }

                img {
                    height: 100%;
                    width: auto;
                }
                &.stationUsers__all__user--guest{
                    h4 span{
                        display: block;
                        margin-left: 0;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }
            }
        }
        


    }

    .PlaylistSelector {
        width: 290px;
        position: fixed;
        bottom: 80px;
        right: 0;
        margin: 0 auto;
        left: 100px;
        background: #0c0e15;
        border-radius: 4px;
        padding: 10px;
        color: #999;
        font-size: 12px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

        .PlaylistSelector__close {
            position: absolute;
            right: 5px;
            cursor: pointer;
        }

        h3{
            width:200px;
            font-size: 11px;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: #fff;
            float: left;
            padding-left: 5px;
            margin: 10px 0;
        }

        .PlaylistSelector__content {
            padding-top: 20px;

           & > div {
                overflow: auto;
                will-change: transform;
                max-height: 240px;
                width: 100% !important;
            }
            & > div::-webkit-scrollbar {
                width: 6px;
            }
            & > div::-webkit-scrollbar-thumb {
                background: #2a3042; 
    
            }
            & > div::-webkit-scrollbar-track {
                background: #0c0e14; 
            }

            .playlist__selector_pl {
                width: 100%;
                float: left;
                border-bottom: 1px solid #191c25;
    
                h4 {
                    width: 200px;
                    overflow: hidden;
                    float: left;
                    font-size: 13px;
                    padding-left: 5px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    line-height: 12px;

                    span {
                        background: rgba(255, 255, 255, 0.1);
                        font-size: 11px;
                        padding: 1px 8px;
                        display: inline-block;
                        border-radius: 3px;
                        margin-left: 4px;
                    }
                }
    
                button {
                    padding: 4px 12px;
                    border: none;
                    background: rgba(255, 255, 255, 0.2);
                    border-radius: 3px;
                    margin-top: 12px;

                    &:hover {
                        background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                        color: #fff;
                    }
                }
                &:last-child {
                    border-bottom: none;
                }
                 
            }
        }

        .PlaylistSelector__confirm {
            position: absolute;
            z-index: 9999999999;
            top: 25%;
            background: #191c27;
            color: #abaab1;
            padding: 20px;
            border-radius: 5px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
            width: 93%;
            line-height: 1.7em;
            button {
                float: right;
                margin-left: 10px;
                border: none;
                background: rgba(255, 255, 255, 0.2);
                padding: 4px 14px;
                border-radius: 3px;
                margin-top: 5px;

                &.PlaylistSelector__confirm__confirmbtn{
                    background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                    color: #fff;
                }
            }
        }
        

    }

    .stationNotif {
         position: absolute;
         font-size: 13px;
         width: 92%;
         padding: 8px 18px;
         background: rgba(71, 64, 95, 0.4);
         color: #a2a0c0;
         bottom: 195px;
         z-index: 999;
         text-align: center;
         margin: 0 auto;
         margin-bottom: 15px;
         left: 0;
         right: 0;
         border-radius: 5px;
        button {
            background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
            color: #fff;
            font-size: 12px;
            border: none;
            padding: 5px 10px;
            border-radius: 3px;
            margin-left: 5px;
        }
    }

}