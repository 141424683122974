//PlayList
.Playlist {
    width: 100%;
    float: left;
    height: calc(100vh - 180px);
    .songWrapper.customScrollbar {
      height: calc(100vh - 260px);
      overflow: auto;
      float: left;
   }
}

.playlist__header {
    padding: 20px 25px;
    float: left;
    width: 100%;
    color: #fff;
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 5px;

    .playlist__edit {
        position: relative;
        margin-right: 0;

        .playlist__edit__icon {
            padding: 2px 4px;
            border-radius: 5px 5px 0 0;
            cursor: pointer;
            transition: all 0.2s linear;
        }

        ul {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            right: 0;
            margin: 0;
            padding: 0;
            list-style-type: none;
            width: 120px;
            font-size: 12px;
            background: rgb(46, 50, 67);
            border-radius: 5px 0 5px 5px;
            z-index: 9;
            transition: all 0.1s linear;
        }
        li {
            display: block;
            width: 100%;
            padding: 4px 10px;
            opacity: 0.5;
            cursor: pointer;
        }
        li:hover {
            opacity: 1;
            background: rgba(255, 255, 255, 0.1);
        }
        &.active ul{
            visibility: visible;
            opacity: 1;
        }
        &.active .playlist__edit__icon{
            background: #2e3243;
        }
    }
    .playAll button {
        border: none;
        font-size: 13px;
        opacity: 0.5;
        outline: none;

        &:hover {
            opacity: 1;
        }
    }

}


.playlist__header__left > div, .playlist__header__right > div {
    display: inline-block;
    margin-right: 15px;
}


.playlist__header__left {
    float: left;
    width: 70%;

    .playlist__title {
        font-weight: bold;

        svg {
            opacity: 0.4;
            margin-right: 5px;
        }
    
        span {
            background: rgba(255, 255, 255, 0.1);
            font-size: 10px;
            padding: 1px 7px;
            border-radius: 4px;
            margin-left: 5px;
            color: #888;
        }
    }
}

.playlist__header__right {
    float: left;
    width: 30%;
    text-align: right;

    .playlist__add button {
        border-radius: 50%;
        padding: 2px 3px;
        background: transparent;
        border: none;
        opacity: 0.6;
        outline: none;
        transition: all 0.2s linear;
    }
    
    .playlist__add button:hover {
        opacity: 1;
        background: rgba(255, 255, 255, 0.1);
    }


    .clearPlaylist {
        position: relative;

        .clearPlaylist__button {
            border: none;
            font-size: 12px;
            opacity: 0.6;
            outline: none;
        }
        .clearPlaylist__button:hover {
            opacity: 1;
        }

        .clearPlaylist__confirm {
            position: absolute;
            /* top: 0px; */
            width: 160px;
            text-align: left;
            right: 0px;
            font-size: 12px;
            background: #0f111c;
            border-radius: 5px;
            padding: 15px;
            z-index: 3;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
            p {
                line-height: 18px;
                margin-top: 0px;
                margin-bottom: 20px;
                letter-spacing: 0.25px;
            }
            button {
                display:inline-block;
                margin: 0 5px;
                border: none;
                background: #484b63;
                border-radius: 25px;
                opacity: 0.8;
                padding: 0px 10px;
                outline: none;
            }
            button:hover {
                opacity: 1;
            }
            button:nth-child(2):hover {
                background: #b34b4b;
            }
        }

    }

    
}

.playlist__category, .playlist__likes, .playlist__save, .playlist__privacy {
    opacity: 0.5;
}
.playlist__category.playlist__category--owner {
    position: absolute;
    font-size: 12px;
    margin-top: 22px;
    margin-left: 25px;
    opacity: 0;
    transition: all 0.2s linear;
}

.playlist__header:hover .playlist__category.playlist__category--owner {
    opacity: 0.5;
}

.playlist__likes{
    cursor: pointer;
    transition: all 0.2s linear;
    &:hover{
        opacity: 1;
    }
    &.liked{
        opacity: 1;
        svg{
            color: rgb(163, 42, 42);
        }
    }
}

.playlist_songs, .playlist_songs-mini {
    width: 100%;
    float: left;
    //height: calc( 100vh - 260px);

    p.nosongs {
        padding: 50px;
        width: 100%;
        float: left;
        text-align: center;
        color: #686b7c;
        font-size: 14px;
        letter-spacing: 0.3px;
    }
}


#createPlaylistModal {
    width: 100%;
    position: fixed;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.7);
    height: 100vh;
    top: 0;
    left: 0;
    transition: all 0.3s linear;


    &.hide{
        visibility: hidden;
        opacity: 0;

    }
    &.show{
        visibility: visible;
        opacity: 1;
    }

    .createPlaylistModal_wrapper {
        font-size: 13px;

        input[type="text"] {
            width: 100%;
            padding: 15px 20px;
            border-radius: 26px;
            border: none;
            background: rgba(0, 0, 0, 0.29);
            outline: none;
        }

        button{
            color: #fff;
            padding: 10px;
            width: 100%;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0.6px;
            text-transform: uppercase;
            display: block;
            border-radius: 30px;
            background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
            opacity: 0.8;
            outline: none;
            border: none;
            padding: 14px;
            margin-top: 30px;
            display: block;
            transition: all 0.2s linear;
        }
        button:hover{
            opacity: 1;
        }

    }
    &.show .modal_iner{
        top: 25%;
        opacity: 1;
    }
    

    .select_wrapper, .input_wrapper {
        margin: 20px 0;
        position: relative;
    }
    .select_wrapper > span, .input_wrapper > span {
        display: inline-block;
        font-size: 11px;
        text-transform: uppercase;
        padding-left: 10px;
        color: #fff;
        opacity: 0.6;
        letter-spacing: 0.6px;
        margin-bottom: 10px;
    }


    
    .closeCreateModal{
        padding:10px;
        display: inline-block;
        position: absolute;
        right:10px;
        top: 10px;
        cursor: pointer;
        opacity: 0.4;
        transition: all 0.2s linear;

        &:hover {
            opacity: 1;
        }
    }

    .errorMsg{
        width: 100%;
        float: left;
        color: #c54848;
        text-align: center;
        border-radius: 20px;
        background: rgba(179, 65, 65, 0.2);
        padding: 10px;
        margin: 0px;
        font-size: 13px;
        margin-bottom: 15px;
    }

}


.playlistActionModals{
    .playlist_edit_form > label > input {
        width: 100%;
        padding: 15px 20px;
        border: none;
        border-radius: 35px;
        background: rgba(0, 0, 0, 0.3);
        margin-bottom: 20px;
        outline: none;
    }
    
    .playlist_edit_form button {
        color: #fff;
        width: 100%;
        display: block;
        font-size: 11px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 30px;
        padding: 15px;
        background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
        cursor: pointer;
        transition: all 0.2s linear;
        border: none;
        outline: none;
        margin-bottom: 10px;
    }
    .playlist_edit_form .SelectField{
        margin-bottom: 20px;
    }
    .playlist_edit_form label {
        position: relative;
        width: 100%;
        float: left;
        margin-top: 25px;
    }
    .playlist_edit_form label > span {
        display: inline-block;
        font-size: 11px;
        text-transform: uppercase;
        padding-left: 10px;
        color: #fff;
        opacity: 0.6;
        letter-spacing: 0.6px;
        position: absolute;
        margin-top: -20px;
    }

    .removePlaylistAction{
        p {
            margin-top: 0;
            line-height: 24px;
            color: #b9b9bd;
        }
        span {
            color: #fff;
            font-weight: bold;
            letter-spacing: 0.4px;
        }
        button {
            padding: 12px 35px;
            margin: 7px;
            border-radius: 35px;
            border: none;
            background: #111;
            outline: none;
        }
        button.playlistRemoveButton {
            background: #ad3d3d;
            margin-left: 0;
        }

    } 

    .shareBox{
        label {
            margin-bottom: 10px;
            display: block;
        }
        input{
            width: 100%;
            padding: 10px;
            border: none;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 4px;
        }

    }

}



//REACT SELECT

.rc-select-dropdown-menu-item {
    white-space: nowrap;
    font-size: 12px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #11131f;
    border: none;
}

.rc-select-enabled .rc-select-selection:active, .rc-select-enabled .rc-select-selection:hover {
    border-color: transparent;
    box-shadow: none;
}

body .rc-select-selection--multiple .rc-select-selection__rendered .rc-select-selection__choice {
    margin-top: 8px;
    background-color: rgba(84, 66, 152, 0.57);
    color: #fff;
    border-radius: 5px;
    padding: 4px 15px;
    margin-right: 6px;
}

body .rc-select .rc-select-selection__choice__remove {
    padding-left: 0px;
    right: 6px;
    font-size: 18px;
    color: #fff;
    top: 3px;
}
body .rc-select .rc-select-selection__choice__remove:hover {
    color: #ae80ff;
}
.rc-select-selection--multiple .rc-select-selection__clear {
    padding: 0 3px;
    cursor: pointer;
}

body .rc-select-selection.rc-select-selection--multiple {
    height: auto;
    border-radius: 10px;
}

body .rc-select-dropdown {
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999999999999;
}


body .rc-select-selection {
    background-color: #242531;
    border-radius: 35px;
    border: none;
    outline: none;
    box-shadow: none;
}

body .rc-select-selection--single .rc-select-selection-selected-value {
    color: #ccc;
}

body .rc-select-dropdown-menu-item {
    max-width: none;
    padding: 10px;
    font-size: 13px;
}
body li.rc-select-dropdown-menu-item-active {
    color: #fff;
    background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
}

body li.rc-select-dropdown-menu-item-selected {
    background-color: #40404e;
    color: #ccc;
}

body li.rc-select-dropdown-menu-item-disabled {
    color: #535353;
}

body .rc-select-dropdown > div {
    max-height: 200px;
}

body .rc-select-dropdown > div::-webkit-scrollbar {
  width: 10px;
  background: #11131f;
}
 
body .rc-select-dropdown > div::-webkit-scrollbar-thumb {
  background: #4b3c83;
  border-radius: 20px;
}

body .rc-select-dropdown > div::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}


.fatSelect .rc-select-selection {
    height: 44px;
    line-height: 44px;
    background: rgba(0, 0, 0, 0.3);
}

.fatSelect .rc-select-selection--single .rc-select-selection__rendered {
    height: 44px;
}

.fatSelect .rc-select-selection--single .rc-select-selection-selected-value {
    top: 7px;
    left: 10px;
    text-transform: capitalize;
}

.fatSelect .rc-select-arrow {
    top: 7px;
    right: 5px;
}

.fatSelect .rc-select-search--inline {
    position: relative;
    top: 5px;
    left: 10px;
}

.mobile_addSong__buttons__wrap{
   .libPlaylists button{
      display: block;
      width: 100%;
      padding: 10px;
      border: none;
      outline: none;
      text-align: left;
      color: #fff;
      padding-left: 25px;
      svg {
         position: absolute;
         left: 5px;
      }
   }
}