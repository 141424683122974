.artists {
    position: relative;

    .artist {
        display: inline-block;
        border-radius: 6px;
        width: calc(33.33% - 20px);
        height: 100px;
        color: #fff;
        margin: 20px 10px;
        font-size: 13px;
        padding: 10px;
        cursor: pointer;
        transition: all 0.2s linear;

        h3 {
            font-size: 13px;
            margin: 6px 0;
        }

        .artist_left {
            width: 80px;
            height: 80px;
            float: left;
            border-radius: 50%;
            overflow: hidden;
            transition: all 0.2s linear;
            position: relative;
            background: #353548;

            img {
                width: 100%;
                height: auto;
            }
        }

        .artist_right {
            width: calc(100% - 80px);
            float: left;
            padding-left: 10px;

            span {
                opacity: 0.7;
                margin-bottom: 2px;
                letter-spacing: 0.3px;
                display: block;
            }
            .artist__genres{
                opacity: 0.4;
                max-width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-transform: capitalize;
                overflow: hidden;
            }
        }

        &:hover {
            background: #303549;
            background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

            .artist_left {
                border: 4px solid #c7c5e6;
                box-shadow: 0 0 25px rgba(0, 0, 0, 0.6);
                transform: rotate(-10deg) translate(-15px,-5px) scale(1.17);
            }
        }

    }


}

#artist{
    width: 100%;
    float: left;
    margin-top: 20px;

    .artist_header{
        width: 100%;
        float: left;
        text-align: center;
        margin-bottom: 30px;

        .artist_header__wrap {
            width: 87%;
            margin: 0 auto;
            color: #fff;
        }
        .artist_left{
            float: left;
            width: 120px;
            height: 120px;

            .artist_left__img {
                float: left;
                width: 120px;
                height: 120px;
                margin: 0 auto;
                border-radius: 50%;
                overflow: hidden;
                border: 7px solid rgba(201, 189, 243, 0.2);
                img {
                    width: 100%;
                    height: auto;
                }
            }
            h3 {
                top: -10px;
                margin-left: 140px;
                font-size: 24px;
                position: relative;
                letter-spacing: 1px;
            }
        }
        
        .artist_right {
            width: calc( 100% - 120px);
            text-align: left;
            padding-left: 20px;
            float: left;

            span{
                margin-right: 15px;
                text-transform: capitalize;
            }
            
        }
        
    }
    
    .artist_tracks{
        float: left;
        width: 100%;
        margin-bottom: 40px;

        .artist_content{
            width: 94%;
            margin: 0 auto;

            .artist_bio {
                font-size: 14px;
                color: #a8acbe;
                line-height: 26px;
                margin-bottom: 30px;
                padding-left: 40px;
                padding-right: 40px;
    
                button {
                    color: #fff;
                    margin-left: 10px;
                    cursor: pointer;
                    border: none;
                    outline: none;
                }
            }

            h2{
                color: #fff;
                font-size: 16px;
                margin-left: 35px;
                margin-top: 50px;
            }

            .tracks{
                height: auto;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
    
                .track{
                    margin: 10px;
                }
            }
        }

    }

    
    .artist_back_button {
        position: absolute;
        top: 130px;
        left: 280px;
        z-index: 2;
        button {
            border-radius: 50%;
            padding: 5px;
            border: none;
            background: rgba(255, 255, 255, 0.1);
            color: rgba(255, 255, 255, 0.7);
            outline: none;
        }
    }

    .trackScroll_wrap.trackScroll_wrap--pagination > div {
        height: calc(100vh - 220px);
        width: 100%!important;
    }
}