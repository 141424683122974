#friends{
    width: 100%;
    float: left;

    .friends_wrap, .the_friends {
        width: 100%;
        float: left;
    }

    .friends_header{
        width: 100%;
        float: left;
        text-align: center;
        padding: 20px 40px;
        transition: all 0.3s linear;
        position: relative;
        background: linear-gradient(135deg, rgba(59, 64, 152, 0.7) 0%, rgba(76, 59, 133, 0.7) 100%);
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.21) inset;

        h3 {
            font-size: 14px;
            letter-spacing: 0.7px;
            color: #fff;
        }

        .profile_image{
            display: inline-block;
            width: 80px;
            border-radius: 50%;
            height: 80px;
            border: 7px solid rgba(255, 255, 255, 0.1);
            overflow: hidden;

            img{
                max-width: 100%;
                height: 80px;
                object-fit: cover;
            }
        }
    }

    .friends_header h3 {
        font-size: 14px;
        letter-spacing: 0.7px;
        color: #fff;
        margin-bottom: 0;
    }
    
    .the_friends__nav {
        float: left;
        width: 100%;
        color: #fff;
        font-size: 14px;
        line-height: 23px;
        padding-left: 30px;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            text-align: left;

            li {
                display: inline-block;
                color: #fff;
                list-style-type: none;
                display: inline-block;
                margin: 0 5px;
                padding: 7px 15px;
                border-radius: 30px;
                font-size: 15px;
                font-weight: bold;
                letter-spacing: 1px;
                text-transform: uppercase;
                cursor: pointer;
                opacity: 0.5;

                span {
                    background: rgba(255, 255, 255, 0.15);
                    font-size: 11px;
                    padding: 2px 7px;
                    border-radius: 10px;
                    margin-left: 5px;
                }

                &:hover {
                    opacity: 0.7;
                }
                &.react-tabs__tab--selected {
                    opacity: 1;
                    &:after {
                        content: "";
                        width: 40px;
                        height: 4px;
                        background: #6459d9;
                        display: block;
                        top: 7px;
                        position: relative;
                    }
                }
            }
        }
    }


    .the_firends__wrapper {
        width: 100%;
        float: left;

        .the_firends__wrapper__inner{
            width: 100%;
            max-width: 1000px;
            margin:auto;
            padding: 40px;

            p{
                text-align: center;
                color: #fff;
                font-size: 13px;
                opacity: 0.4;
            }


            .friend {
                display: inline-block;
                width: 120px;
                margin-bottom: 20px;
                text-align: center;
                img {
                    width: 80px;
                    height: 80px;
                    -o-object-fit: cover;
                    object-fit: cover;
                    border-radius: 50%;
                    background: rgba(255, 255, 255, 0.1);
                    padding: 7px;
                }
                h4 {
                    font-size: 13px;
                    margin: 5px;
                    text-decoration: none;
                }
                a {
                    text-decoration: none;
                    color: rgba(255, 255, 255, 0.6);
                }

                &:hover a {
                    color: rgba(255, 255, 255, 1);
                }
                &:hover img {
                    background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                }
                .friend_actions {
                    position: relative;
                    width: 80px;
                    margin: 0 auto;
                    color: #fff;


                    ul {
                        display: none;
                        margin: 0 auto;
                        padding: 0;
                        list-style-type: none;
                        font-size: 12px;
                        width: 80px;
                        background: #212436;
                        box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
                        border-radius: 5px;
                        position: absolute;
    
                        li {
                            padding: 7px 4px;
                            opacity: 0.5;
                            color: #fff;
                            border-radius:7px;
                            cursor: pointer;

                            &:hover {
                                opacity: 1;
                                background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                            }
                        }
                    }

                    & > span {
                        display: block;
                        padding: 3px;
                        background: rgb(62, 66, 85);
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin: 0 auto;
                        margin-top: -15px;
                        cursor: pointer;
                        opacity: 0;
                    }

                }

                &:hover .friend_actions{
                    & > span {
                        opacity: 1;
                    }
                    &.active{
                        ul {
                            display: block;
                        }
                    }
                }

            }

        }
    }

}