#footer{
    position: fixed;
    z-index: 999999;
    bottom: -180px;
    right: 45px;
    width: 150px;
    transition: all 0.12s linear;


    .footerLogo{
        width: 36px;
        height: 27px;
        float: right;
        background: #0d0f17;
        border-radius: 5px 5px 0 0;
        cursor: pointer;
        opacity: 0.3;
        transition: all 0.12s linear;

        &:hover{
            opacity: 1;
        }

        div{
            width: 28px;
            height: 30px;
            float: right;
            background-position: -4px -2px;
            background-image: url('../../assets/logo_white.png');
        }
    }

    &.footerActive{
        bottom: 0;
        .footerLogo{
            opacity: 1;
        }
    }

    .footerMenu{
        background: #0d0f17;
        border-radius: 5px 0 0 0;
        padding: 10px 0;
        width: 100%;
        float: right;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            color: #fff;
            font-size: 14px;
    
            li {
                padding: 5px 20px;
                padding-left: 35px;
                text-align: right;
                opacity: 0.6;
                transition: all 0.2s linear;
                
                &:hover {
                    opacity: 1;
                }

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }

}
