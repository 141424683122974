.page-channel {

    .install-button {
        margin-right: 292px;
    }

    main {
        width: calc(100% - 270px);
        margin-right: 0px;
    }

    #RightSidebar {
        display: none;
    }

    h4{
        color: rgba(255, 255, 255, 0.5);
        font-size: 13px;
        font-weight: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding-left: 20px;
    }

    .channels__left{
        width: calc(100% - 420px);
        float: left;
        
        .channels__playlist .song {
            position: relative!important;

            .song__artist{
                margin-top: -5px;
            }
            &.futureSong {
                display: none;
            }

            .song_inner {
                width: 90%;
            }

            
            .song__actions {
                background: transparent;
                box-shadow: none;
                right: 70px;
                margin-top: 6px;

                .song__actions__add {
                    width: 30px;
                    float: right;
                    border-radius: 50%;
                    padding-top: 8px; 
                }

                &.song__actions-active.song__actions-add {
                    z-index: 99;
                }
                &.song__actions-active button.song__actions__add {
                    border-radius: 4px;
                }

                .song__actions__confirm.song__actions__confirm-copy {
                    top: 25px;
                }
            }

            
            .song_inner .song__details {
                width: calc(100% - 130px);
                span.song__title {
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: inline-block;
                }
            }

        }

        .channels__player {
            position: relative;
            height: 320px;

            .channelFullScreen__btn {
                margin-left: 10px;
                border-radius: 50%;
                padding: 3px 4px;
                border: none;
                background: rgba(255, 255, 255, 0.2);
                color: #fff;
                position: absolute;
                right: 10px;
                top: 10px;
            }

            .channels__player__songInfo {
                position: absolute;
                z-index: 3;
                bottom: 0px;
                color: #fff;
                font-size: 10px;
                font-weight: normal;
                padding: 15px;
                width: 100%;
                padding-top: 40px;
                background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);

                .channelSong__add {
                    margin-left: 10px;
                    border-radius: 50%;
                    padding: 3px 4px;
                    border: none;
                    background: rgba(255, 255, 255, 0.2);

                    &:hover {
                        background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                    }
                }


                h3 {
                    width: 70%;
                    float: left;
                }
                
                .player__duration {
                    width: 20%;
                    float: right;
                    text-align: right;
                    margin-top: 0px;
                    right: 0;
                    margin-right: 15px;
                    font-size: 13px;
                }

                .channels__player__songInfo__nowPlaying {
                    position: absolute;
                    margin-top: -16px;
                    opacity: 0.6;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }

                .song__actions__confirm {
                    position: absolute;
                    background: #0f111c;
                    color: #999;
                    box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
                    padding: 10px;
                    text-align: center;
                    border-radius: 4px;
                    bottom: 110px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 200px;
                    & > span{
                     display: block;
                    }

                    .minPlaylistSelect {
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }

                        button {
                            display: inline-block;
                            border: none;
                            outline: none;
                            background: #333;
                            color: #fff;
                            margin: 0 7px;
                            margin-top: 10px;
                            border-radius: 15px;
                            padding: 3px 15px;
                            opacity: 0.7;
                            transition: all 0.1s linear;
                        }
                        button.confirm_yes {
                            background: #2f9763;
                        }
                        button.confirm_no {
                            background: #393a4b;
                        }
                        button:hover{
                            opacity: 1;
                        }

                }
                
            }
            
            .channels__player__overlay {
                width: 100%;
                height: 320px;
                position: absolute;
            }
            
            .channels__player__songInfo h3 {
                margin: 0;
            }

            .channelPlayer__volume__control {
                width: 75px;
                height: 20px;
                bottom: 18px;
                position: absolute;
                z-index: 1;
                right: 90px;

                .player__volume {
                    float: left;
                    cursor: pointer;
                }
                
            
                input[type=range] {
                    z-index: 3;
                    -webkit-appearance: none;
                    cursor: pointer;
                    display: inline-block;
                    width: 50px;
                    margin-top: 10px;
                }

                input[type=range]::-webkit-slider-runnable-track {
                    width: 100%;
                    height: 3px;
                    margin-top: 2px;
                    background: rgba(255, 255, 255, 0.2);
                    border: none;
                }
                input[type=range]::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    border: none;
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    background: rgba(255, 255, 255, 0.4);
                    margin-top: -3px;
                    margin-left:0px
                }
                input[type=range]:focus {
                    outline: none;
                }
            
            
            
                input[type=range]::-moz-range-track {
                    width: 100%;
                    height: 7px;
                    background: rgba(255, 255, 255, 0.1);
                    border: none;
                }
                input[type=range]::-moz-range-thumb {
                    border: none;
                    height: 7px;
                    width: 10px;
                    background: rgba(255, 255, 255, 0.1);
                    margin-top: 0px;
                    margin-left:0px
                }
                input[type=range]::-moz-focusring{ 
                    outline: none;
                    border:none;
                }
            
            }
        }


        
        .channels__playlist  {
            margin: 20px;
            border-radius: 5px;
            overflow: hidden;

            h5{
                margin: 0;
                padding: 10px 20px;
                color: rgba(255, 255, 255, 0.5);
                font-weight: normal;
                letter-spacing: 1px;
                text-transform: uppercase;
                background: rgba(0, 0, 0, 0.2);
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            }

            .channel_playlist_wrap > div {
                overflow: auto;
                will-change: transform;
                height: calc(100vh - 620px);
                width: 100%!important;
                color: #484863;
               //  color: #514cf8;
                &::-webkit-scrollbar {
                  width: 12px;
                  border-radius: 12px;
                  background: transparent;
                  border: 0px solid transparent;
               }
                 
               &::-webkit-scrollbar-thumb {
                  width: 10px;
                  border-radius: 16px;
                  border: 3px solid transparent;
                  box-shadow: inset 0 0 0 3px;
               }
            }
        }

        

        
    }



    .channels__right{
        padding: 70px 0 0 0;
        width: 420px;
        float: right;
        position: fixed;
        right: 0px;
        top: 0;
        height: 100vh;
        background-image: url('../../assets/rightShadow.png');
        background-size: 100%;
        z-index: 9;
    }
}
