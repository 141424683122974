header{
    color:white;
    padding:15px 30px;
    width: calc(100% - 270px);
    float: right;


    #searchBar {
        width: calc(100% - 500px);
        min-width: 300px;
        max-width: 600px;
        float: left;
        position: relative;
        input {
            padding: 10px 10px;
            border: none;
            background: rgba(0, 0, 0, 0.3);
            width: 100%;
            border-radius: 30px;
            outline: none;
            padding-left: 85px;
            font-size: 14px;
        }
        .searchButton {
            position: absolute;
            right: 6px;
            border: none;
            background: rgba(255, 255, 255, 0.1);
            width: 28px;
            height: 28px;
            border-radius: 50%;
            top: 5px;
        }

        .searchBarOptions button {
            position: absolute;
            border: none;
            background: rgba(255, 255, 255, 0.1);
            font-size: 12px;
            padding: 10px 6px;
            border-radius: 30px 0 0 30px;
            min-width: 75px;
            outline: none;
            i {
                font-size: 7px;
                position: relative;
                top: -1px;
                opacity: 0.5;
            }
        }
        
        .searchBarOptions {
            ul {
                position: absolute;
                width: 80px;
                text-align: center;
                list-style-type: none;
                background: #131624;
                margin: 0;
                padding: 0;
                margin-top: 36px!important;
                margin: 0;
                font-size: 12px;
                z-index: 8;
                li {
                    cursor: pointer;
                    span{
                        padding: 6px 10px;
                        display: block;
                    }
                }   
                li:hover {
                    background: rgba(255, 255, 255, 0.1);
                }     
            }
        }
        &.searchBar-active .searchBarOptions button {
            background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
            border-radius: 4px;
        }

    }

    .menu{
        float: right;
        margin: 0;
        padding: 0;
        list-style-type: none;
        position: fixed;
        right: 20px;
        width: 300px;
        text-align: right;
        z-index: 11;
        li {
            display: inline-block;
            &.active a {
                background: rgba(255, 255, 255, 0.15);
                opacity: 0.9;
            }

            &.menu-item--friends {
                float: left;
                margin-top: 10px;
                margin-left: 8px;
            }
            &.menu-item--signup {
                float: left;
                margin-left: 15px;
                margin-top: 5px;
                button {
                    cursor: pointer;
                    background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                    font-size: 12px;
                    padding: 7px 18px;
                    opacity: 1;
                    border: none;
                    border-radius: 20px;
                    outline: none;
                    display: inline-block;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                }
            }

        }

        a{
            color: #fff;
            margin-right: 20px;
            text-decoration: none;
            opacity: 0.7;
            font-size: 13px;
            letter-spacing: 0.5px;
            padding: 8px 10px;
            border-radius: 30px;
        }
        a:hover{
            opacity: 1;
        }

        .menu__account {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 2px solid #fff;
            display: inline-block;
            overflow: hidden;
            top: 8px;
            position: relative;
            cursor: pointer;
            margin-right: 15px;
            padding: 0;
    
            img {
                width: 100%;
                height: auto;
                border-radius: 50%;
                height: 25px;
                object-fit: cover;
            }
        }
    }

    .header__glow {
        position: absolute;
        left: 0;
        z-index: -1;
        margin: 0;
        margin-top: -227px;
        margin-left: -40px;
        background: radial-gradient(ellipse at center, #4960ff -37%, rgba(240, 232, 251, 0) 70%, rgba(255, 255, 255, 0) 100%);
        width: 350px;
        height: 300px;
    }

    .menu__glow {
        width: 500px;
        height: 80px;
        position: absolute;
        z-index: -1;
        right: 0px;
        top: 2px;
        overflow-y: hidden;

        span {
            margin: 0;
            margin-top: -30px;
            background: radial-gradient(ellipse at center, #4960ff -37%, rgba(240, 232, 251, 0) 70%, rgba(255, 255, 255, 0) 100%);
            width: 100%;
            height: 500px;
            display: block;
            opacity: 0.6;
        }
    }

    .upgrade_button {
        position: absolute;
        right: 335px;
        border: none;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        outline: none;
        margin-top: 5px;
        width: 100px;
        svg {
            position: relative;
            margin-right: -5px;
        }
    }
    .menu__leftButtons{
        margin-right: 305px;
        position: absolute;
        right: 0;
        margin-top: 5px;
        width: 210px;

        .install-button {
            border: none;
            font-family: sans-serif;
            font-size: 14px;
            letter-spacing: 0.4px;
            width: 90px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 30px;
            padding: 5px 10px;
            display: inline-block;
        }
        .myStation-button{
            border: none;
            font-family: sans-serif;
            font-size: 12px;
            width: 108px;
            padding: 5px 10px;
            display: inline-block;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-weight: bold;
            margin-right: 0;
        }
    }


    .quick_helpBtn {
        position: fixed;
        right: 70px;
        top: 22px;
        padding: 6px 12px;
        border: none;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        font-size: 13px;
        outline: none;

        &.notChecked:after{
            content: "";
            border: 3px solid rgba(255, 255, 255, 0.3);
            border-radius: 30px;
            height: 30px;
            width: 30px;
            position: absolute;
            margin-top: -7px;
            margin-left: -16px;
            animation: blinky 1.5s ease-out;
            animation-iteration-count: infinite;
            opacity: 0;
        }
    }
    
    
    .notificationIcon{
        cursor: pointer;
        
        .notificationCount {
            padding: 1px 3px;
            position: absolute;
            background: red;
            font-size: 11px;
            border-radius: 3px;
            margin-top: 8px;
            margin-left: -7px;
        }
    }

    #notifications {
        display: block;
        position: absolute;
        z-index: 99;
        width: 260px;
        background: #2e2e5f;
        right: 15px;
        top: 58px;
        border-radius: 5px;
        box-shadow: 0 5px 40px rgba(0, 0, 0, 0.3);

        &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 6px 6px 6px;
            border-color: transparent transparent #2e2e5f transparent;
            position: absolute;
            margin-top: -6px;
            right: 10px;
        }
        & > p {
            color: #7f86a3;
            font-size: 12px;
            text-align: center;
        }
        .notification {
            width: 100%;
            float: left;
            font-size: 12px;

            .notif_left {
                width: 36px;
                float: left;
            }
            
            dl {
                margin: 0;
                margin-top: 5px;
                font-size: 11px;
                opacity: 0.7;
            }

            a {
                text-decoration: none;
                color: #9097b5;
                padding: 10px;
                display: block;
                border-bottom: 1px solid rgba(255, 255, 255, 0.04);
                
                &:hover {
                    background: rgba(0, 0, 0, 0.1);
                }

            }
            &:nth-last-child(1) a{
                border: none;
            }
            span {
                color: #bdc3de;
            }
            p {
                margin: 0;
            }
            img {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                margin-right: 10px;
            }
            
        }


    }

    //Quick Look Modal
    .quickLook_wrapper{
        video{
            max-width: 100%;
            max-height: 100%;
            width: 100%;
        }

        .quickLook__nav .react-tabs__tab-list {
            margin: 0;
            padding: 0;
            list-style-type: none;
            text-align: center;
            margin-bottom: 20px;

            li {
                display: inline-block;
                margin: 5px 10px;
                padding: 7px 20px;
                border-radius: 30px;
                color: rgba(255, 255, 255, 0.5);
                letter-spacing: 0.3px;
                cursor: pointer;

                &.react-tabs__tab--selected {
                    background: rgba(0, 0, 0, 0.4);
                    color: #fff;
                }
            }
        }

    }
    
}

// @media (min-width: $desktop-breakpoint){
//     .header{
//         margin-bottom: $xl-size;
//     }
// }