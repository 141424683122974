#playlist_mini{
    width: 320px;
    position: relative;
    left: 0px;
    top: 80px;
    height: calc(100vh - 230px);
    z-index: 5;
    transition: all 0.2s linear;

    #queueMenu {
        color: #fff;
        padding: 5px;
        margin-bottom: 10px;
        position: relative;
        .queueHeader {
            font-size: 12px;
            width: calc(100% - 60px);
            margin: 0 auto;
            position: relative;
            z-index: 2;
            text-transform: uppercase;
            letter-spacing: 0.4px;
            color: rgba(255, 255, 255, 0.8);
        }

        .queue_tools {
            display: inline-block;
            margin-left: 12px;
            button {
                border: none;
                margin-right: 5px;
                opacity: 0.5;
                outline: none;
            }
            button:hover {
                opacity: 1;
            }
        }

        
        .queueNav {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            .handles button {
                margin: 0;
                padding: 5px;
                border: none;
                opacity: 0.6;
                &:nth-child(2) {
                    float: right;
                }
                &:nth-child(1) {
                    float: left;
                }
                &:hover {
                    opacity: 1;
                }
            }
        }

    }
    

    .noSongsQueu {
        font-size: 13px;
        text-align: center;
        margin-top: 50px;
        color: rgba(255, 255, 255, 0.6);
    }


    .playlistQueue {
        width: 320px;
        position: absolute;
        z-index: 5;
        overflow: hidden;
        height: calc(100vh - 280px);

        .playlist__header {
            padding: 15px;

            .playlist__title {
                margin-right: 0px;
                position: relative;
                width: 100%;
                top: 8px;

                i {
                    font-size: 10px;
                    font-style: normal;
                    letter-spacing: 0.7px;
                    opacity: 0.7;
                    position: absolute;
                    margin-top: -20px;
                }
                a {
                    font-size: 12px;
                    display: inline-block;
                    max-width: 110px;
                    white-space: nowrap;
                    overflow: hidden;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    line-height: 12px;
                }
                span {
                    display: inline-block;
                    line-height: 13px;
                }

            }

            .playlist__header__right a {
                padding:3px 5px;
                margin-right: -5px;
                cursor: pointer;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.46);
                opacity: 0.8;
                z-index: 9;
                position: absolute;
                right: 15px;
            }
        }

        .publicPlaylist_msg {
            position: absolute;
            z-index: 8;
            background: rgba(23, 22, 42, 0.8);
            padding: 30px;
            box-sizing: border-box;
            height: 100%;
            color: #fff;
            font-size: 12px;

            p {
                margin-top: 50px;
                margin-bottom: 25px;
                text-align: left;
                color: #999;
                line-height: 1.5em;
            }
            span {
                color: #fff;
            }
            a {
                display: inline-block;
                padding: 4px 8px;
                color: #fff;
                text-decoration: none;
                border-radius: 4px;
                border: 1px solid;
                margin: 0 4px;
                cursor: pointer;
                transition: all 0.2s linear;
            }
            a:hover {
                opacity: 0.7;
            }
        }
        

        .playlist_songs-mini{
            height: calc(100% - 65px);
            //background: rgba(0, 0, 0, 0.1);
            transition: all 0.5s linear;

            .custom-scrollbars > div:nth-child(2){
                display: none;
            }

            .song__album{
                display: none;
            }

            .song{
                padding: 6px 12px;
                height: auto;
                position: relative;
                width: calc(100% - 8px);

                .song__details {
                    width: 240px;
                    padding-left: 17px;
                    white-space: nowrap;
                    overflow: hidden;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                }
                
                .song__duration {
                    padding: 0px;
                    padding-top: 4px;
                    font-size: 12px;
                }

                .song__title {
                    width: auto;
                    opacity: 0.7;
                    letter-spacing: 0.2px;
                }
                
               .song__details > span {
                    display: inline-block;
                }
                
                .song_inner .song__artist {
                    margin-left: 12px;
                    opacity: 0.3;
                }
                
                .song_inner .song__artist:before {
                    content: " - ";
                }
                .songActionsWrapper {
                    right: 0px;
                    padding-right: 5px;
                    width: 84px;
                    height: 37px;
                    top: 0px;
                }
                
                .songActionsWrapper > button, .songActionsWrapper .show_actions{
                    margin-top: 0px;
                    margin-right: 0px;
                    width: 26px;
                    height: 100%;
                    line-height: 12px;
                    border-radius: 0;
                    float: left;
                }
                .songActionsWrapper .show_actions{
                    padding-top: 14px;
                    
                }

                .song__actions__confirm{
                    background: #222436;
                }

                .song__actions {
                    right: 5px;
                    top:37px;
                    z-index: 2;
                    background: #222436;
                    
    
                    & > button{
                        background: rgba(23, 25, 46, 0.8);
                    }
                    &  > button:hover {
                        background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                    }
                    button.song__actions__play, button.song__actions__repeat {
                        display: none;
                    }
                    .song__actions__confirm.song__actions__confirm.song__actions__confirm-copy .rc-select {
                        margin-left: 0px;
                    }
                    .rc-select-selection{
                        background-color: #41435b;
                    }
                }

                &.active .song__title {
                    opacity: 1;
                    color: #fff;
                }
                &.active:after{
                    display: none;
                }
                &.selectedSong .show_actions{
                    background: #222436!important;
                }
                &:hover .song__duration{
                    opacity: 0.3;
                }
                &:hover .songActionsWrapper{
                    background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
                }
            }
        }
    }

}
