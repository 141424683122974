#contactForm {
    input, textarea,select {
        width: 100%;
        margin: 10px 0;
        padding: 8px 12px;
        border: none;
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        outline: none;
    }
    textarea{
        height: 150px;
    }
    option {
        background: #141623;
    }
    button {
        background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
        border: none;
        border-radius: 3px;
        padding: 5px 29px;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #fff;
    }

    .errorMsg{
        width: 100%;
        float: left;
        color: #c54848;
        text-align: left;
        border-radius: 5px;
        background: rgba(179, 65, 65, 0.2);
        padding: 10px;
        margin: 5px;
        margin-bottom: 15px;
        font-size: 13px;
        line-height: 1.4em;
    }

    .successMsg{
        width: 100%;
        float: left;
        color: #44b790;
        text-align: left;
        border-radius: 5px;
        background: rgba(2, 253, 148, 0.15);
        padding: 10px;
        margin: 5px;
        margin-bottom: 15px;
        font-size: 13px;
        line-height: 1.4em;
    }

    .sendingMsg{
        opacity: 0.2;
    }
    .loading {
        text-align: center;
        z-index: 9;
        position: absolute;
        left: 0;
        right: 0;
        margin-top: 120px;
    }
}
