//Colors

$off-black: #20222b;
$off-white: #a5afd7;
$dark-blue: #333745;
$darker-blue: #2d303b;
$blue: #5e30a1;
$light-blue: #393e4d;

//Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;

$desktop-breakpoint: 45rem;