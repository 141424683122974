#User {
    position: fixed;
    z-index: 9999999;
    width: 360px;
    right: 0;
    top:0;
    height: 100vh;
    padding: 30px;
    padding-top: 70px;
    color: #fff;
    font-size: 14px;
    background: linear-gradient(135deg, rgba(30,36,70, 0.95) 0%,rgba(74,59,132, 0.8) 100%); 
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease;
    transform: translateZ(0); 

    &.hideUserArea{
        visibility: hidden;
        right: -370px;
    }
    &.showUserArea{
        visibility: visible;
        right: 0;
    }
    
    .UserInner {
        width: 300px;
    }
    .close_sidebar{
      cursor: pointer;
    }
    .User__topbar {
        position: absolute;
        top: 20px;
        z-index: 2;
        width: 100%;
        left: 0;
        padding: 0 30px;

        a {
            cursor: pointer;
            display: inline-block;
            font-weight: bold;
            opacity: 0.7;
        }
        .logout_button {
            float: right;
        }
        a:hover {
            opacity: 1;
        }
    }
    
    .errorMsg{
        width: 100%;
        float: left;
        color: #c54848;
        text-align: center;
        border-radius: 5px;
        background: rgba(179, 65, 65, 0.2);
        padding: 10px;
        margin: 5px;
        font-size: 13px;
        line-height: 1.4em;
    }
    .successMsg{
        width: 100%;
        float: left;
        color: #44b790;
        text-align: center;
        border-radius: 5px;
        background: rgba(2, 253, 148, 0.15);
        padding: 10px;
        margin: 5px;
        font-size: 13px;
        line-height: 1.4em;
    }
    .custom-scrollbars > div:nth-child(3) > div {
        background-color: rgb(73, 66, 152)!important;
        width: 10px!important;
    }
    .custom-scrollbars > div:nth-child(2) {
        display: none;
    }

    h3 {
        text-align: center;
        margin-bottom: 30px;
    }
    input[type="text"], input[type="email"], input[type="password"], textarea {
        margin-bottom: 20px;
        width: 100%;
        border: none;
        background: rgba(0, 0, 0, 0.2);
        padding: 15px 20px;
        border-radius: 30px;
        outline: none;
        color: #fff;
        &::placeholder { 
         color: #999;
         opacity: 1;
        }
    }
    input[type="text"]:disabled {
        color: rgba(255, 255, 255, 0.4);
        cursor: not-allowed;
    }
    textarea{
        min-height: 150px;
    }
    .profile_update_button, .login_wrapper .social_login button, .login_wrapper .email_login_wrapper button, #signup_form button {
        color: #fff;
        padding: 15px;
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        display: block;
        border-radius: 30px;
        border: none;
        margin-top: 20px;
        background: -webkit-linear-gradient(-45deg, #704293 0%, #2a56e3 100%);
        background: -webkit-linear-gradient(315deg, #704293 0%, #2a56e3 100%);
        background: -o-linear-gradient(315deg, #704293 0%, #2a56e3 100%);
        background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
        opacity: 0.8;
        outline: none;
        transition: all 0.2s linear;

        &:hover{
            opacity: 1;
        }
    }

    .login_wrapper .social_login .facebook_login {background: #5d56c7;}
    .login_wrapper .social_login .twitter_login {background: #1e9bd7;}
    .login_wrapper .social_login .google_login {background: #c54646;}

    .logout_button{
      outline: none;
      border: none;
      cursor: pointer;
    }

    //LOGIN

    #login {
        
        .login_wrapper {
            text-align: center;
            p {
                font-size: 13px;
            }
        }
        
        .email_login_wrapper{
            h4 {
                display: block;
                padding: 10px;
                text-align: center;
                margin-bottom: 20px;
                background: #444;
                border-radius: 30px;
                cursor: pointer;
            }
            
        }

        
        .social_login {

            a {
                display: block;
                padding: 10px;
                text-align: center;
                margin-bottom: 20px;
                background: #444;
                border-radius: 30px;
                cursor: pointer;
            }
        
            a.facebook_login {
                background: #4267b2;
            }
            
            
            a.twitter_login {
                background: #5195ca;
            }
            

            
            a.google_login {
                background: #c8463d;
            }
        }
        
    }

    .signup_msg{
        font-size: 13px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 25px;
        text-align: center;
        margin-top: 0px;
    }

    //Signup
    #UserSettings, #signup, #reset{
        &.loading_data #signup_form{
            opacity: 0.5;
        }
        .Select, .signup_gender, button, .signup_bio{
            float: left;
            width: 100%;
        }
        .Select input {
            padding: 10px 0px;
            background: none;
        }
          .Select-control {
            border: none;
            color: #fff;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 30px;
            padding: 5px;
          }
          
          .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label {
            color: #fff;
            padding: 10px;
            top: 3px;
            position: relative;
          }
          
          .Select.is-focused > .Select-control, .Select.is-focused:not(.is-opened) > .Select-control {
            background:#fff;
            color: #444;
          }
          .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label{
            color: #444;
          }
          .Select--multi .Select-value {
            background-color: rgba(86, 64, 146, 0.37);
            border: 1px solid rgb(87, 64, 146);
            color: #fff;
          }
          
          .Select--multi .Select-value-icon {
            border-right: 1px solid rgb(85, 65, 150);
          }
        .signup_gender {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        #signup_form label span , .AccountSettings label span{
            position: absolute;
            left: 12px;
            top: -12px;
            font-size: 10px;
            visibility: hidden;
            color: #9997ff;
        }
        #signup_form label span.error_msg , .AccountSettings label span.error_msg{
            color: #c46464;
            right: 10px;
            left: auto!important;
        }

        #signup_form > label, .AccountSettings > label, .signup_social > label {
            position: relative;
            width: 100%;
            float: left
        }
        #signup_form label.error span.error_msg, .AccountSettings label.error span.error_msg{
            visibility: visible;
        }
        #signup_form label.label_pass, #signup_form label.label_passagain {
            position: relative;
            width: 100%;
            float: left
        }

        .loading {
            position: absolute;
            z-index: 9;
            left: 0;
            right: 0;
            margin: 0 auto;
            display: inline-block;
            width: 40px;
            top: 200px;
        }

        .hasVals label span:nth-child(1) {
            visibility: visible!important;
        }

        .AccountSettings {
            color: #fff;
            dt{
                position: absolute;
                z-index: 1;
                background: #36cc92;
                width: 20px;
                text-align: center;
                border-radius: 50%;
                top: 0;
            }
        }
    
    }

    //COMPLETE SIGNUP
    #signup.complete_complete{
        display: none;
    }
    .signup_form-complete {
        label.label_email, label.label_pass, label.label_passagain {
            display: none;
        }
        label span:nth-child(1) {
            visibility: visible!important;
        }
    }
    .signup_form-complete-email{
        label.label_pass, label.label_passagain {
            display: none;
        }
        label span:nth-child(1) {
            visibility: visible!important;
        } 
    }

    //LOGIN
    #login{
        .loading_data{
            opacity: 0.5;
        }
        .loginError{
            margin-bottom: 20px;
        }
        .loading {
            position: absolute;
            z-index: 9;
            left: 0;
            right: 0;
            margin: 0 auto;
            display: inline-block;
            width: 40px;
            top: 200px;
        } 
    }

    .signuploginroutes {
        text-align: center;
        width: 100%;
        float: left;
        font-size: 13px;
        color: #fff;
        margin-top: 12px;

        p{
            margin-bottom: 5px;
        }

        a, button {
            cursor: pointer;
            color: #fff;
            text-decoration: none;
            opacity: 0.4;
            border: none;
            outline: none;
            transition: all 0.2s linear;
            &:hover {
                opacity: 1;
            }
        }
    }

    #reset{
        .resetEmail_disabled{
            cursor: not-allowed;
            opacity: 0.6;
        }
    }
    


    //Settings
    #UserSettings{

        .UserSettings__header {
            width: 100%;
            float: left;
            margin-bottom: 15px;
            margin-top: -10px;
            h4 {
               margin: 0;
               text-align: center;
               margin-top: 25px;
               a {
                  color: #fff;
                  text-decoration: none;
               }
            }
        }
        
        .profile_photo {
            width: 100px;
            height: 100px;
            margin: 0 auto;
            position: relative;

            img {
                width: 100px;
                border: 5px solid rgba(255, 255, 255, 0.2);
                border-radius: 50%;
                height: 100px;
                object-fit: cover;
                display: block;
            }
            
            .fileContainer {
                overflow: hidden;
                background: #5e57ba;
                float: left;
                position: absolute;
                right: 0;
                padding: 5px;
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.2);
            }
            .fileContainer [type=file] {
                cursor: inherit;
                display: block;
                font-size: 999px;
                filter: alpha(opacity=0);
                min-height: 100%;
                min-width: 100%;
                opacity: 0;
                position: absolute;
                right: 0;
                text-align: right;
                top: 0;
                cursor: pointer;
            }

            .publicProfileLink {
                overflow: hidden;
                background: #62608c;
                color: #d9d9df;
                float: left;
                position: absolute;
                right: 38px;
                bottom: -12px;
                padding: 2px;
                width: 23px;
                cursor: pointer;
                text-align: center;
                border-radius: 50%;
                border: 1px solid rgba(0, 0, 0, 0.2);

                a{
                    color: #d9d9df;
                }
            }

            .loading__file{
                position: absolute;
                left: 40px;
                top: 40px;
            }

        }
        

        .react-tabs__tab-panel {
            display: none;
            width: 100%;
            float: left;
            padding-bottom: 50px;
        }
        
        .react-tabs__tab-panel.react-tabs__tab-panel--selected {
            display: block;
        }
        ul.react-tabs__tab-list {
            margin: 0;
            padding: 0;
            list-style-type: none;
            text-align: center;
            margin-bottom: 20px;

            li {
                display: inline-block;
                margin: 10px;
                padding: 7px 20px;
                border-radius: 30px;
                font-size: 13px;
                cursor: pointer;
            }
        }
        
        ul.react-tabs__tab-list li.react-tabs__tab--selected {
            background: rgba(0, 0, 0, 0.3);
        }
        
        #userSettings__form {
            position: relative;
        }

        &.UserSettings__saving .react-tabs__tab-panel {
            opacity: 0.4;
        }

        .AppSettings {
            color: #fff;
            .select_wrapper {
                margin: 12px 0px;
                width: 100%;
                float: left;
                position: relative;
            }
        }
        .select_wrapper > span {
            position: absolute;
            left: 12px;
            top: -15px;
            font-size: 10px;
            color: #9997ff;
        }

         .musicPrefFieldLabel {
            background: rgba(0,0,0,.3);
            border-radius: 5px;
            padding: 10px;
            border: 1px solid #1d1a38;
            cursor: pointer;
            margin-top: 10px;
            span {
               margin-bottom: 8px;
               background-color: rgba(84,66,152,.57);
               color: #fff;
               border-radius: 5px;
               padding: 4px 15px;
               margin-right: 6px;
               display: inline-block;
            }
         }

         .musicPrefFieldOptions {
            background: #17152e;
            position: absolute;
            height: 250px;
            overflow: auto;
            width: 100%;
            border-radius: 5px;
            span {
               display: block;
               padding: 7px 15px;
               cursor: pointer;
               color: #ccc;
               &.activeOption{
                  background: #1f1e3f;
               }
               &:hover{
                  color: #fff;
                  background: linear-gradient(135deg,#704293,#2a56e3);
               }
            }
         }

        
    }

    .submit__wrap {
        width: 360px;
        margin-left: -30px;
        background: rgba(0, 0, 0, 0.1);
        position: fixed;
        bottom: 0px;
        padding: 15px 30px;

        button{
            margin-top: 0;
        }
    }
    .userSettings__form__error {
        margin-top: -20px;
        margin-bottom: 20px;
        width: 100%;
        float: left;
    }

    .saving {
        position: absolute;
        z-index: 9;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: inline-block;
        width: 40px;
        top: 100px;
    }

    .user_loader {
        text-align: center;
        margin-top: 150px;
        position: absolute;
        width: 100%;
        left: 0;
    }



}



//Logout