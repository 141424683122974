
#addSong{
    background: transparent;
    width: calc(100% - 590px);
    left: 270px;
    height: calc(100vh - 110px);;
    position: fixed;
    z-index: 999999;
    top: 0;
    transition: all 0.3s linear;
    &.hide{
        visibility: hidden;
        opacity: 0;

    }
    &.show{
        visibility: visible;
        opacity: 1;
    }

    .addSongWrap {
        width: 100%;
        height: 100%;
        top: 0px;
        background: #191c2c;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        border-radius: 5px;
        color: #fff;
        font-size: 13px;
        transition: all 0.3s linear;
        opacity: 1;
    }
    &.show .addSongWrap{
        top: 15%;
        opacity: 1;
    }


    .addSong__header {
        width: 100%;
        padding: 15px 30px;
        background: rgba(0, 0, 0, 0.15);
        border-radius: 5px 5px 0 0;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }
        ul li {
            display: inline-block;
            padding: 10px 12px;
            margin: 0 5px;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #657297;
            cursor: pointer;
        }
        ul li.active {
            background: rgba(0, 0, 0, 0.4);
            border-radius: 25px;
            color: #8e9abc;
        }

        svg {
            position: absolute;
            right: 15px;
            top:20px;
            opacity:0.4;
            transition: all 0.2s linear;
            cursor:pointer;
        }
        
        svg:hover{
            opacity:1;
        }
    }
    
    .addSongLoader .loader {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        margin-top: 23%;
    }
    
    .searchbar {
        margin: 25px 0;
        padding: 0 40px;

        input[type="text"] {
            padding: 20px 30px;
            padding-right: 60px;
            border: none;
            font-size: 16px;
            color: #fff;
            width: 100%;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 40px;
            outline: none;
        }
        button {
            position: absolute;
            border: none;
            margin-left: -60px;
            padding: 15px;
            opacity: 0.4;
            transition: all 0.2s linear;

            &:hover{
                opacity: 1;
            }
        }

        .importSongInfoCheck.custom_checkbox {
            margin-top: 5px;
            margin-bottom: 0px;
            position: relative;
            top: 11px;
            margin-left: 10px;
            span {
                top: 4px;
                position: relative;
                color: #888b99;
            }
            label{
                transform: scale(0.7);
            }
        }

        @keyframes autofill {
            to {
                color: #e9e9e9;
                background: rgba(0, 0, 0, 0.3);
            }
        }
        @-webkit-keyframes autofill {
            to {
                color: #e9e9e9;
                background: rgba(0, 0, 0, 0.3);
            }
        }  
        input:-webkit-autofill {
            animation-name: autofill;
            animation-fill-mode: both;
            -webkit-animation-name: autofill;
            -webkit-animation-fill-mode: both;
        }
        
    }



    .searchresults {
        width: 100%;
        float: left;
        height: calc( 100vh - 315px);
        overflow: hidden;
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.2s linear;

        &.loading{
            opacity: 0.5;
        }

        .searchresults__left {
            width: 100%;
            float: left;
        }
        
        .searchresults__middle,
         .searchresults__right {
            width: 25%;
            float: left;
        }

        .searchresults__left__head, .searchresults__middle__head, .searchresults__right__head {
            padding: 20px;
            background: #1b2031;
            text-align: center;
            color: #727b92;
            font-weight: bold;
            font-size: 14px;
            letter-spacing: 0.4px;
        }

        .searchresults__left__head span, 
        .searchresults__middle__head  span, 
        .searchresults__right__head  span {
            display: inline-block;
            width: 26px;
            height: 21px;
            padding-top: 4px;
            box-sizing: unset;
            border-radius: 50%;
            background: #111;
            margin-right: 6px;
        }
    }
    
    .tracks {
        height: auto;
        padding-left: 20px;
        .track {
            margin: 10px;
        }
        .track__addToPlaylist p {
            text-align: left;
            margin-top: 0px;
            opacity: 1;
        }
        .track__addToPlaylist__success{
            display: none;
        }
    }


    //YOUTUBE VIDEO
    .youtubeSongs__wrap {
        padding: 10px;
        width: 100%;
        float: left;
        overflow: hidden;
        position: relative;

        .youtubeVideo {
            width: 50%;
            padding: 10px;
            float: left;
            cursor: pointer;

            &.active{
                background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
            }
            &:not(.active).hover{
                background: rgba(255, 255, 255, 0.05);
            }
            img {
                max-width: 100%;
            }
            h4 {
                line-height: 18px;
                margin-bottom: 0;
                height: 40px;
                overflow: hidden;

                span {
                    font-size: 11px;
                    opacity: 0.4;
                    display: inline-block;
                    margin-left: 6px;
                }
            }
            svg {
                position: absolute;
                background: #6d4295;
                border-radius: 50%;
                margin-left: -10px;
                margin-top: -10px;
            }
        }

    }

    //SONGINFO
    #songInfo{
        .songInfo__wrap{
            background: rgba(255, 255, 255, 0.05);
            a {
                display: block;
                padding: 8px 10px;
                padding-left: 20px;
                cursor: pointer;
                color:#868c9e;
                transition: all 0.1s linear;
            }
            a.active {
                background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                color: #fff;
            }
            a.active svg {
                position: absolute;
                margin-left: -17px;
            }
            a:not(.active):nth-child(even) {
                background: rgba(255, 255, 255, 0.04);
            }
            a:not(.active):hover{
                background: rgba(255, 255, 255, 0.1);
                color: #abb1c2;
            }

        }
        

    }

    #userPlaylists{
        .playlistInfo__wrap {

            a {
                display: block;
                padding: 8px 10px;
                padding-left: 20px;
                cursor: pointer;
                color:#868c9e;
                transition: all 0.1s linear;
            }
            a.active {
                background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                color: #fff;
            }
            a.active svg {
                position: absolute;
                margin-left: -17px;
            }
            a:not(.active):hover{
                background: rgba(255, 255, 255, 0.07);
                color: #abb1c2;
            }
        }
    }


    //Default Message
    .searchresults__iner p {
        text-align: center;
        margin-top: 60px;
        opacity: 0.3;
    }

    .addtoPlaylistButton {
        position: absolute;
        bottom: 10px;
        right: 15px;
        
        button{
            color: #fff;
            width: 100%;
            display: block;
            text-transform: uppercase;
            padding: 15px 35px;
            border: none;
            font-size: 12px;
            opacity: 1;
            letter-spacing: 0.7px;
            filter: grayscale(60%);
            cursor: default;
            font-weight: bold;
            border-radius: 30px;
            background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
            opacity: 0.7;
            cursor: pointer;
            outline: none;
            transition: all 0.2s linear;
        }

        &.active button{
            opacity: 1;
            filter: none;
            cursor: pointer;
        }
    }


    //ScrollBar
    .custom-scrollbars > div:nth-child(3){
        background: rgba(255, 255, 255, 0.1);
        //right:30px!important;
        div{
            background-color: rgb(81, 76, 248)!important;
        }
    }

    //Artists
    #SearchArtists {
        .searchresults__left {
            width: 30%;
        }
        .searchresults__middle {
            width: 70%;
        }

        .customScrollbar.addSongScroll_wrap > div{
            height: calc(100vh - 385px);
            overflow-x: hidden;
        }
        .artistSearch__loadMore{
            width: 100%;
            float: left;
            text-align: center;
            padding: 10px;
            cursor: pointer;
            color: #a089ff;
        }
        #artistAlbums{
            color: #9493ac;

            .artistHeader {
                width: 100%;
                float: left;
                background: #242a3d;
    
                .artistHeader_left {
                    width: 100px;
                    float: left;
                    padding: 15px;
                    margin-right: 15px;
    
                    img {
                        max-width: 100px;
                    }
    
                }
    
                .artistHeader_right {
                    width: calc(100% - 115px);
                    float: left;
                    padding: 0 15px;
    
                    h4 {
                        margin-bottom: 10px;
                        color: #fff;
                    }
    
                    p {
                        margin: 0!important;
                        text-align: left!important;
                        opacity: 1!important;
                        font-size: 12px;
                        line-height: 20px;
                        max-height: 65px;
                        overflow: hidden;
                        width: 100%;
                    }
                }
            }


            .artistAlbums {
                width: 100%;
                float: left;

                .albumItem {
                    width: 100%;
                    float: left;
                    cursor: pointer;
                    transition: all 0.1s linear;

                    .albumItem__head {
                        width: 100%;
                        float: left;
                        padding: 12px 20px;
                        transition: all 0.15s linear;
                        
                        .album_left {
                            width: 55px;
                            float: left;
                            img {
                                max-width: 100%;
                            }
                        }
                        
                        .album_right {
                            float: left;
                            width: calc( 100% - 55px);
                            padding: 0 15px;
                            &> div {
                                margin-top: 7px;
                            }
                            .albumArtist {
                                opacity: 0.6;
                            }
                        }
                        & > span {
                            position: absolute;
                            right: 15px;
                            margin-top: 15px;
                            opacity: 0.2;
                        }
                    }

                    .album_tracks {
                        width: 100%;
                        float: left;
                        min-height: 150px;

                        ul {
                            margin: 0;
                            padding: 0;
                            list-style-type: none;
                        }
                        li {
                            margin: 5px 0;
                            padding: 7px 30px;
                            font-size: 12px;
                            opacity: 0.8;
                            transition: all 0.1s linear;

                            &:hover {
                                background: rgba(255, 255, 255, 0.1);
                                opacity: 1;
                            }
                            &.active {
                                background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                                color: #fff;
                            }
                            span {
                                margin-left: 3px;
                                font-size: 11px;
                                padding: 2px 4px;
                                background: rgba(255, 255, 255, 0.15);
                                opacity: 0.7;
                                border-radius: 4px;
                            }
                        }
                    }

                    &:nth-child(even) .albumItem__head {
                        background: rgba(255, 255, 255, 0.05);
                    }
                    &:hover .albumItem__head, &.active .albumItem__head  {
                        background: rgba(125, 138, 247, 0.26);
                    }

                }

            }


        }

    }

 
}