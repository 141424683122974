
//PlayListGroup
.playlists_wrapper {
    float: left;
    width: 270px;
    height: 100vh;
    position: fixed;
    padding-top: 20px;
    background-image: url('../../assets/leftShadow.png');
    background-size: 100% 100%;
    background-color: rgba(0, 0, 0, 0.15);
    
}

.logo {
    padding-left: 30px;
    margin-bottom: 20px;
}

.playlists__Button {
    padding-left: 10px;
    text-align: left;
    position: relative;
    button {
        color: #fff;
        width: calc(100% - 24px);
        display: inline-block;
        font-size: 12px;
        letter-spacing: 0.4px;
        border-radius: 30px;
        padding: 12px;
        opacity: 0.7;
        text-align: left;
        border: none;
        outline: none;
        cursor: pointer;
        transition: all 0.2s linear;

        span{
            background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
            padding: 1px 5px;
            text-align: center;
            border-radius: 50%;
        }
        &:hover{
            opacity: 1;
        }
    }


    span.playlistGroup_dropdown_trigger {
        padding: 2px;
        display: inline-block;
        color: #fff;
        opacity: 0.4;
        margin-right: -7px;
        margin-left: 7px;
        border-radius: 7px;
        cursor: pointer;
        &.active, &:hover{
            background: rgba(255, 255, 255, 0.1);
        }
    }
}

#playlists {
    height: calc(100vh - 450px);
    position: relative;
    ul {
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    .custom-scrollbars > div:nth-child(3) div {
        background-color: rgba(72, 50, 207, 0.39);
    }

    .no_saved {
        margin: 0 auto;
        text-align: center;
        width: 100%;
        color: #bebbd59c;
        font-size: 11px;
        line-height: 1.7em;
        margin-bottom: 20px;
        text-align: left;
        padding-left: 25px;
    }

    .playingIcon{
        width:20px;
        display: inline-block;
    }

    .playlists__menu {
        padding: 20px;
        .playlists__menu__item {
            & > button {
               border: none;
               color: #bebbd5;
               font-size: 11px;
               letter-spacing: 0.4px;
               text-transform: uppercase;
               font-weight: bold;
               margin-bottom: 20px;
               outline: none;
               opacity: 0.5;

               &:hover{
                  opacity: 1;
               }
            }
        }

         .playlistGroupScroll_wrap.customScrollbar {
            overflow: auto;
            will-change: transform;
            height: 150px;
         }

        .active_menu {
            button{
                opacity: 1;
            }
        }
        .myPlaylists, .savedPlaylists {
            width: 100%;
            float: right;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;
        }

        & > div {
            width: 100%;
            float: left;
        }

    }

    .playListGroupDropZone {
        position: absolute;
        z-index: 2;
        background: rgba(52, 45, 85, 0.9);
        width: 100%;
        border-radius: 5px;
        color: #fff;
        text-align: center;
        font-size: 12px;
        height: 100%;
        padding-top: 10px;
        h4 {
            margin-top: 8px;
        }
        & > div {
            width: 85%;
            margin: 0px auto;
            height: 90%;
            border: 2px dashed #6d6884;
            border-radius: 10px;
            color: #b0abcd;
            text-align: center;
            font-size: 13px;
            padding-top: 10px;
        }
    }
    

}

.playslistsNavigation {
    position: absolute;
    z-index: 9;
    background: rgb(22, 25, 40);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    margin: 0;
    padding: 0;
    list-style-type: none;
    top: 43px;
    border-radius: 0px 5px 5px 5px;
    right: -86px;
    li {
        padding: 9px 10px;
        color: #fff;
        background: transparent;
        opacity: 0.3;
        font-size: 12px;
        cursor: pointer;
    }
    li:hover {
        opacity: 0.6;
    }
    li.active{
        background: rgba(255, 255, 255, 0.06);
        opacity: 1;
    }
}


//Should not be nested becuase of SortableHOC
li.playlistsItem, .playlists__menu__discover ul li, .playlists__menu__channels ul li {
    list-style-type: none;
    width: 100%;
    a, button{
        display: block;
        list-style-type: none;
        font-size: 12px;
        letter-spacing: 0.3px;
        opacity: 1;
        color: #a8a8b0;
        padding: 8px 24px;
        text-decoration: none;
        cursor: pointer;
    }

    &.active a{
        opacity: 1;
        color: #a089ff;
    }
    &:not(.active):hover a {
        color: #cfd7e8;
    }
    a span {
        background: rgba(255, 255, 255, 0.06);
        font-size: 11px;
        padding: 1px 10px;
        border-radius: 10px;
        margin-left: 8px;
    }
}
#playlists .playlists__menu__channels {

    button{
        opacity: 1;

        span {
            font-size: 9px;
            padding: 2px 3px;
            background: #514cf8;
            border-radius: 2px;
            margin-left: 4px;
        }
    }

    button:before {
        content: "";
        width: 7px;
        height: 7px;
        position: absolute;
        display: block;
        background: #6fe6b2;
        border-radius: 50%;
        margin-top: 8px;
        margin-left: 10px;
    }
}


li.playlistsItem.hovered {
    background: rgba(74, 51, 153, 0.35);
    border-radius: 3px;

    &:before {
        content: "+";
        position: absolute;
        color: #fff;
        margin-top: 7px;
        margin-left: 4px;
        font-size: 13px;
        font-weight: normal;
        opacity: 0.5;
    }
}
li.playlistsItem.disabled {
    opacity: 0.5!important;
}


.signupModal_wrapper {
    text-align: center;
    button {
        cursor: pointer;
        background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
        font-size: 13px;
        padding: 14px 18px;
        opacity: 1;
        display: inline-block;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        border: none;
        border-radius: 20px;
        width: 100%;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1px;
        margin-top: 15px;
    }
}

html body .song-sorting, html body .playlist-sorting, html body .song-sorting-mini {
    pointer-events: auto !important;
    cursor: grabbing !important;
    cursor: -moz-grabbing !important;
    cursor: -webkit-grabbing !important;
    z-index: 9;
    background: linear-gradient(135deg, rgba(112, 66, 147, 0.3) 0%, rgba(42, 86, 227, 0.3) 100%)!important;
}
html body .song-sorting-mini{
    .song__actions {
        right: 5px;
        z-index: 2;
    }
}