.import__nav{
    ul {
        margin: 0;
        padding: 20px 30px;
        list-style-type: none;

        li {
            display: inline-block;
            padding: 8px 20px;
            margin: 0 10px;
            border-radius: 20px;
            color: #95a0be;

            &.react-tabs__tab--selected {
                background: rgba(0, 0, 0, 0.4);
                color: #fff;
            }
        }
        
    }
}
.import__content {
    //padding: 15px 30px;
    
    .songUploadZone {
        padding: 20px;
    }

    .uploadedSongList {
        width: 100%;
        float: left;
        background: rgba(0, 0, 0, 0.2);
        color: #7f88a0;
        .uploadedSong {
            padding: 15px;
            width: 100%;
            box-sizing: border-box;
            float: left;
    
            span {
                float: right;
            }
            input {
                margin-right: 5px;
            }
    
            &:nth-child(even) {
                background: rgba(0, 0, 0, 0.3);
            }
        }
    }

}

//YOUTUBE PLAYLIST IMPORT
.importPlaylist {
    width: 100%;
    height: calc(100vh - 250px);
    float: left;

    .searchbar {
        margin: 10px 0 20px 0!important;
        button svg {
            transform: rotate(90deg);
        }
    }

    .importPlaylist__songs {
        width: 100%;
        height: calc(100% - 115px);
        float: left;
        background: rgba(0,0, 0, 0.3);

        .importPlaylist__title {
            padding: 15px 20px;
            font-size: 12px;
            box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
            background: #1f2432;

            h3 {
                margin: 0;
            }
            span {
                background: rgba(255, 255, 255, 0.1);
                font-size: 12px;
                margin-top: -2px;
                display: inline-block;
                font-weight: normal;
                padding: 3px 7px;
                border-radius: 4px;
                margin-left: 3px;
                color: #8f95a5;
            }

            .importPlaylist__title__selectAll {
                position: absolute;
                right: 7px;
                margin-top: -18px;
            }

            
            .importPlaylist__title__import {
                position: absolute;
                right: 50px;
                margin-top: -21px;
                span {
                    padding: 5px 15px;
                }
                button {
                    border: none;
                    padding: 5px 15px;
                    border-radius: 3px;
                    background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                }
            }

        }

        .ytPlaylistSong {
            width: 100%;
            float: left;
            padding: 10px 20px;
            color: #959bad;
            opacity: 0.4;

            .ytPlaylistSong__count {
                position: absolute;
                font-size: 20px;
                opacity: 0.3;
                margin-left: -10px;
                padding-top: 10px;
            }
        
            .ytPlaylistSong__left {
                width: 70px;
                height: auto;
                float: left;
                margin-left: 30px;

                img {
                    width: 100%;
                }
            }
            .ytPlaylistSong__right {
                width: calc(100% - 100px);
                float: left;
                padding-left: 15px;
                box-sizing: border-box;
                h4 {
                    margin-top: 0px;
                    margin-bottom: 10px;
                }
                span {
                    color: #787d8b;
                }
            }
    
            &:nth-child(even) {
                background: rgba(255, 255, 255, 0.02);
            }

            .ytPlaylistSong__check {
                position: absolute;
                right: 0;
                margin-top: 15px;
            }
            &.ytPlaylistSong--selected, &.ytPlaylistSong:hover{
                opacity: 1;
            }
            
        }


        .ytPlaylistWrap > div::-webkit-scrollbar {
            width: 6px;
        }
        .ytPlaylistWrap > div::-webkit-scrollbar-thumb {
            background: #2a3042; 

        }
        .ytPlaylistWrap > div::-webkit-scrollbar-track {
            background: #0c0e14; 
        }
    
    }

    .importPlaylistModal_wrapper {
        button {
            border: none;
            padding: 15px;
            width: 100%;
            border-radius: 30px;
            background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
            letter-spacing: 2px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
            margin-top: 30px;

            &.importButtonError {
                opacity: 0.6;
                cursor: not-allowed;
            }
        }
    }
    
    .select_wrapper {
        span {
            margin-bottom: 18px;
            display: block;
        }

        a.importPlaylistModal__createNew {
            text-decoration: underline;
            display: inline-block;
            margin-left: 3px;
            opacity: 0.7;
            cursor: pointer;

            &:hover{
                opacity: 1;
            }
        }
        
        i {
            opacity: 0.3;
        }
    }

    .importModal_wrapper {
        p{
            opacity: 0.5;
            margin-top: 0;
        }
        progress {
            border: none;
            width: 100%;
            height: 15px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 3px;
            margin-bottom:20px;
        }

        progress::-webkit-progress-value {
            background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
            border-radius: 3px 0 0 3px;
        }
        
        progress::-webkit-progress-bar {
            background: rgba(255, 255, 255, 0.1);
        }
        
        progress::-moz-progress-bar {
            background: lightcolor;
        }
        button{
            width:100%;
            border:none;
            background: #323342;
            padding:12px;
            border-radius:4px
          }
    }
      
      

}

