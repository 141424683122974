@import url('https://fonts.googleapis.com/css?family=Roboto');
html{
    font-size: 62.5%;
}

body{
    //font-family: Helvetica, Arial, sans-serif;
    font-family: 'Roboto', sans-serif;
    font-size: $m-size;
    width: 100%;
    float: left;
    height: 100vh;
    //background: -moz-linear-gradient(-45deg, rgba(18,18,27,1) 0%, rgba(26,30,46,1) 100%);
    //background: -webkit-linear-gradient(-45deg, rgba(18,18,27,1) 0%,rgba(26,30,46,1) 100%);
    //background: linear-gradient(135deg, rgba(18,18,27,1) 0%,rgba(26,30,46,1) 100%); 
    background: #191c2c;
    

    &.complete-registration #root:before {
      content: "";
      width: 100%;
      position: fixed;
      z-index: 12;
      height: 100vh;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.7);
  }
  .siteBG{
    background-image: url('../../assets/header2.jpg');
    width: 100%;
    height: 150px;
    position: fixed;
    z-index: -1;
    background-size:200%; 
    top:0;

    .siteBG_color {
      width: 100%;
      height: 100%;
      position: relative;
      background: rgba(53, 33, 122, 0.8);
    }
    .siteBG_fade {
        position: absolute;
        width: 100%;
        height: 150px;
        background: linear-gradient(to bottom, rgba(0,0,0,0.0) 0%,rgb(25, 28, 44) 100%);
        bottom: 0px;
    }
  }

  main {
    width: calc(100% - 590px);
    margin-right: 320px;
    float: right;
}

}


textarea{
  font-family: 'Roboto', sans-serif;
}
.site_background {
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    height: 100vh;
    left: 0;
    opacity: 0.15;
}

button{
    cursor: pointer;
}

button:disabled{
    cursor: default;
}


//Custom Radio Button
.customRadio {
    .control {
        display: inline-block;
        position: relative;
        padding-left: 23px;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 14px;
        margin-right: 25px;
        margin-left: 5px;
        margin-top: 10px;
      }
      .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }
      .control__indicator {
        position: absolute;
        top: 2px;
        left: 0;
        height: 15px;
        width: 15px;
        background: rgba(0, 0, 0, 0.6);
      }
      .control--radio .control__indicator {
        border-radius: 50%;
      }
      .control:hover input ~ .control__indicator,
      .control input:focus ~ .control__indicator {
        background: rgba(0, 0, 0, 0.6);
      }
      .control input:checked ~ .control__indicator {
        background: rgba(0, 0, 0, 0.6);
      }
      .control:hover input:not([disabled]):checked ~ .control__indicator,
      .control input:checked:focus ~ .control__indicator {
        background: rgba(0, 0, 0, 0.6);
      }
      .control input:disabled ~ .control__indicator {
        background: rgba(0, 0, 0, 0.6);
        opacity: 0.6;
        pointer-events: none;
      }
      .control__indicator:after {
        content: '';
        position: absolute;
        display: none;
      }
      .control input:checked ~ .control__indicator:after {
        display: block;
      }
      .control--checkbox .control__indicator:after {
        left: 8px;
        top: 4px;
        width: 3px;
        height: 8px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
      .control--checkbox input:disabled ~ .control__indicator:after {
        border-color: rgba(0, 0, 0, 0.6);
      }
      .control--radio .control__indicator:after {
        left: 4px;
        top: 4px;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #7c66ff;
      }
      .control--radio input:disabled ~ .control__indicator:after {
        background: rgba(0, 0, 0, 0.6);
      }

}

.custom_checkbox{
  margin-bottom: 20px;

  span {
    width: 250px;
    display: inline-block;
    font-size: 13px;
  }


  input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 42px;
    height: 25px;
    background: rgba(0, 0, 0, 0.6);
    display: inline-block;
    border-radius: 30px;
    position: relative;
  }
  
  label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 30px;
    transition: 0.3s;
  }
  
  input:checked + label {
    background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
  }
  
  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
  
  label:active:after {
    width: 30px;
  }
}
  //Styled Checkbox
  .styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }

    // Box.
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.15);
    }

    // Box hover
    &:hover + label:before {
      background: #4e29f3;
    }
    
    // Box focus
    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label:before {
      background: #4e29f3;
    }
    
    // Disabled state label.
    &:disabled + label {
      color: #242424;
      cursor: auto;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }
  }

//Animations
  //FADE
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 800ms ease-in;
  }




//TOOLTIP
.react-tooltip{
   font-size: 13px;
   background-color: #131318;
}
.__react_component_tooltip.place-top.type-dark {
  background-color: #131318;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.__react_component_tooltip.type-dark.place-top:after{
  border-top-color: #131318;
}

//ScrollBar
.custom-scrollbars > div:nth-child(3) div {
  background-color: rgba(136, 140, 161, 0.5)!important;
}

.customScrollbar{
   width: 100%!important;
   color: transparent;
   transition: color .3s ease;
   &:hover {
      color: #474a5d;
   }
   &::-webkit-scrollbar {
      width: 12px;
      border-radius: 12px;
      background: transparent;
      border: 0px solid transparent;
  }
     
   &::-webkit-scrollbar-thumb {
      width: 10px;
      border-radius: 16px;
      border: 3px solid transparent;
      box-shadow: inset 0 0 0 3px;
   }

   &.customScrollbar--alwaysShow {
      color: #474a5d;
   }

  //Always show Scroll Thumb
  &.trackScroll_wrap > div, &.addSongScroll_wrap > div{
    color: #474a5d;
  }
  &.trackScroll_wrap > div::-webkit-scrollbar, &.addSongScroll_wrap > div::-webkit-scrollbar  {
      width: 12px;
      border-radius: 12px;
      background: rgba(0, 0, 0, 0.1);
      border: 0px solid transparent;
  }
  &.trackScroll_wrap > div::-webkit-scrollbar-thumb, &.addSongScroll_wrap > div::-webkit-scrollbar-thumb {
      width: 10px;
      border-radius: 16px;
      border: 3px solid transparent;
      box-shadow: inset 0 0 0 3px;
  }

  &.notiScroll_wrap > div {
    max-height: 200px;
    overflow: auto;
    will-change: transform;
  }
  &.friendsScroll_wrap > div{
    max-height: calc(100vh - 300px);
    width: 104%!important;
    overflow: auto;
    will-change: transform;
  }
  &.addSongScroll_wrap > div {
    overflow: auto;
    will-change: transform;
    height: calc(100vh - 315px);
  }

//   &.playlistGroupScroll_wrap > div {
//     overflow: auto;
//     will-change: transform;
//     height: 150px;
//   }
  &.LibSongScroll_wrap > div {
    overflow: auto;
    will-change: transform;
    height: 180px;
  }
  &.userScroll_wrap > div{
    overflow: auto;
    will-change: transform;
    height: calc(100vh - 340px);
    overflow-x: hidden;
    padding-bottom: 20px;
  }
}

.noscriptMssg {
  width: 300px;
  text-align: center;
  color: #fff;
  margin: 0 auto;
  margin-top: 150px;
  h1 {
    font-size: 24px;
  }
  p {
    opacity: 0.7;
    font-size: 14px;
  }
}
.page-channel{
  header{
    .menu__leftButtons{
      margin-right: 290px;
    }
  }
}
.page-station {
  header .menu__leftButtons .myStation-button {
    display: none;
  }
  
}