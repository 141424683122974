#RightSidebar {
    width: 320px;
    float: right;
    position: fixed;
    right: 0px;
    top: 0;
    height: 100vh;
    background-image: url('../../assets/rightShadow.png');
    background-size: 100%; 
    z-index: 9;

    .queueTab {
        visibility: hidden;
        opacity: 0;
        transition: all 0.15s linear;
        
        &.queueTab-active {
            visibility: visible;
            opacity: 1;
        }
    }

    .queueDropzone {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(52, 45, 85, 0.6);
        z-index: 3;

        &> div {
            width: 85%;
            margin: 20px auto;
            height: 90%;
            border: 2px dashed #6d6884;
            border-radius: 10px;
            color: #b0abcd;
            text-align: center;
            padding-top: 50px;
            font-size: 13px;
        }
    }
}