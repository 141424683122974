.compare_pro {
    margin-top: 65px;
    position: relative;
    top: -25px;

    .compare_pro__inner {
        vertical-align: top;
        text-align: center;
    }

    .pricing_table__header {
        padding: 10px;

        h3 {
            font-size: 24px;
            margin-bottom: 0px;
        }
        span {
            text-transform: uppercase;
            font-weight: bold;
            margin-top: 10px;
            display: inherit;
            letter-spacing: 1px;
            opacity: 0.5;
        }
    }

    .pricing_table__body {
        position: relative;
        display: inline-block;
        line-height: 1.7em;
    }

    .pricing_table {
        width: 200px;
        display: inline-block;
        min-height: 420px;
        vertical-align: top;
        text-align: center;
        border-radius: 7px;
            box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
            margin-top: -20px;

        &.pricing_table_one {
            background: linear-gradient(to bottom, rgba(49, 25, 167, 0.2), rgba(127, 83, 189, 0.2));
        }
        &.pricing_table_two {
            background: linear-gradient(to bottom, #4568dc, #b06ab3);
            transform: scale(1.04);
        }

        .pricing_no{
            color: #b66363;
            font-size: 15px;
            line-height: 18px;
            font-family: monospace;
        }

        button {
            background: #fff;
            border-radius: 30px;
            border: none;
            padding: 4px 22px;
            position: relative;
            top: 10px;
            color: #8f5be6;
            font-weight: bold;
            letter-spacing: 0.4px;
            font-size: 11px;
            text-transform: uppercase;
            transition: all 0.1s linear;

            &:hover {
                transform: scale(1.05);
            }
            &.currently_using{
                background: #3e3662;
                color: #9082cf;
            }
        }

        .pricing_table_pro_overlay {
            position: absolute;
            top: 110px;
            opacity: 0.1;
            margin-left: -12px;
        }
    }
    
    .pricing_table__features {
        width: 290px;
        display: inline-block;
        padding: 20px;
        border-radius: 5px 0 0 5px;
        text-align: left;
        color: #aab8d5;
        line-height: 1.6em;
        margin-top: 10px;
        margin-right: 30px;

        h4 {
            color: #fff;
            font-size: 16px;
            letter-spacing: 0.4px;
            margin-top: 0px;
        }

        .pricing_table__body {
            span {
                font-size: 11px;
                top: 20px;
                position: relative;
                opacity: 0.6;
                letter-spacing: 0.4px;
            }

            i {
                color: #bc65d8;
                font-size: 15px;
                font-style: normal;
            }
        }
    }

}

.proModal_wrapper {
    position: relative;
    .proModal__heading {
        position: absolute;
        top: -142px;
        width: calc(100% + 60px);
        margin: 0px;
        text-align: left;
        margin-left: -30px;
        padding: 10px 60px;
        background: rgba(0, 0, 0, 0.2);
        p{
            color: #aab8d5;
            line-height: 1.7em;
        }
    }
}


.overlay_msg_inner {
    width: 100%;
    float: left;
    text-align: center;
    margin: 30px 0;
    p {
        margin: 32px!important;
        opacity: 0.5!important;
    }
    h3 {
        font-size: 20px;
    }
    button {
        cursor: pointer;
        background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
        font-size: 11px;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        padding: 7px 28px;
        opacity: 1;
        display: inline-block;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        border: none;
        border-radius: 30px;
    }
}

.localSongs_block .overlay_msg_inner{
    float: none;
}

.overlay_msg.allArtist_block {
    margin-bottom: 10px;
    width: 100%;
    float: left;

    .overlay_msg_inner {
        margin: 0px;
        padding: 10px;
        background: rgba(0, 0, 0, 0.2);
        color: #fff;
        p {
            margin: 10px!important;
            font-size: 13px;
        }

        .overlay_msg_content {
            float: left;
            width: calc(100% - 130px);
            text-align: left;
    
            h3 {
                margin: 0px;
                font-size: 14px;
                margin: 12px;
            }
        }
        
        .overlay_msg_action {
            width: 100px;
            float: left;
            margin: 15px;
        }
    }

}


