
.tracks {
    width: 100%;
    //height: 230px;

    .tracks_wrap {
        width: 100%;
        float: left;
    }
    .slick-list {
        padding-bottom: 20px;
    }

    .track {
        display: inline-block;
        position: relative;
        color: #fff;
        opacity: 0.8;
        width: 215px!important;
        margin: 5px;
        cursor: pointer;
        transition: all 0.2s linear;

        .track_img_wrap {
            height: 110px;
            overflow: hidden;
            display: inline-block;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
            margin-top: 15px;
            margin-left: 10px;
            position: relative;
            border-radius: 5px;
            will-change: transform;

            img {
                width: 200px;
                height: auto;
                margin-top: -20px;
            }
            span {
                position: absolute;
                bottom: 3px;
                right: 3px;
                font-size: 12px;
                padding: 2px 5px;
                background: rgba(0, 0, 0, 0.5);
                border-radius: 3px;
            }
            .play_buttons {
                position: absolute;
                z-index: 3;
                top: 30px;
                text-align: center;
                width: 100%;
                transition: all 0.2s linear;
                transform: scale(0.9);
                button {
                    padding: 9px 10px;
                    display: none;
                    margin: 0 10px;
                    background: rgba(0, 0, 0, 0.8);
                    border-radius: 50%;
                    border: none;
                    outline: none;
                }
                button.track__station_button {
                    border-radius: 4px;
                }
                button.track__add_button {
                    padding: 8px 13px;
                }
                button:hover {
                    background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                }
            }
            &:hover {
                img {
                    opacity: 0.5;
                }
                .play_buttons {
                    transform: scale(1);
                    button {
                        display:inline-block;
                    }
                }
            }
        }
        &.activeSong{
            .track_img_wrap {
                img {
                    opacity: 0.5;
                }
                .play_buttons {
                    transform: scale(1);
                    a {
                        display:inline-block;
                    }
                }
            }
        }

        .track_content_wrap {
            margin-left: 10px;
            width: 200px;
            padding: 4px;
            padding-left: 10px;
            padding-bottom: 10px;
            transition: all 0.2s linear;
            border-radius: 0 0 5px 5px;

            h3 {
                margin-top: 10px;
                font-size: 12px;
                margin-bottom: 3px;
                width: 180px;
                white-space: nowrap;
                overflow: hidden;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
            }
            a, button, i {
                font-size: 12px;
            }
            i{
               font-style: normal;
            }
            span {
                font-size: 12px;
                opacity: 0.5;
            }
        }

        &:hover {
            transform: scale(1.05);
            opacity: 1;

            .track_content_wrap{
                background: #292c3e;
                margin-top: -4px;
                box-sizing: border-box;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
            }
            .track_img_wrap {
                transform: scale(1.05);
            }
        }


        .track__addToPlaylist {
            position: absolute;
            z-index: 3;
            top: 0px;
            width: 240px;
            height: 200px;
            margin-left: -10px;
            background: rgba(0, 0, 0, 0.8);
            font-size: 12px;
            border-radius: 5px;

            .trackScroll_wrap > div {
                height: 160px;
                width: 100%!important;
            }

            ul {
                margin: 0px;
                padding: 15px;
                height: 140px;
                color: #9d9fb5;
            }
            p {
                margin: 0;
                margin-bottom: 7px;
                span {
                    color: #c0a8ff;
                    font-weight: bold;
                }
            }
            .track__addToPlaylist__success {
                position: absolute;
                z-index: 4;
                padding: 50px 0;
                background: rgba(65, 51, 143, 0.89);
                width: 86%;
                height: 86%;
                font-weight: bold;
                font-size: 13px;
                letter-spacing: 1px;
                text-align: center;
                border-radius: 5px;
            }
            label {
                display: block;
                margin-bottom: 4px;
                width: 180px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
                padding-left: 16px;
            }
            button {
                background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                padding: 6px 10px;
                border-radius: 30px;
                width: calc(100% - 95px);
                border: navajowhite;
                margin-top: 10px;
                position: absolute;
                bottom: 8px;
                margin: 0 3%;
                opacity: 0.9;
                transition: all 0.2s linear;
                
                &:hover {
                    transform: scale(1.04);
                    opacity: 1;
                }
            }
            
            .track__addToPlaylist__cancel {
                width: 70px;
                right: 0px;
                background: linear-gradient(135deg, #545563 0%, #282d3b 100%);
            }
            .track__addToPlaylist-scrollbars div:nth-child(3) > div {
                background: #4f4092!important;
            }
            .customRadio .control__indicator {
                background: rgba(87, 87, 87, 0.6);
                height: 12px;
                width: 12px;
            }
            
            .customRadio .control--radio .control__indicator:after {
                height: 6px;
                width: 6px;
                left: 3px;
                top: 3px;
            }
            
            .customRadio .control:hover input ~ .control__indicator, .customRadio .control input:focus ~ .control__indicator {
                background: rgba(125, 119, 145, 0.6);
            }
        }

    }

    &.tracks--listView{
        .track {
            width: 96%!important;
            margin: 0;
            transition:none;
            cursor: default;
            padding-bottom: 8px;
            opacity: 1;

            .track_img_wrap {
                width: 60px;
                height: 40px;
                border-radius: 0px;
                float: left;

                img {
                    max-height: 132%;
                    width: auto;
                    margin-top: -7px;
                }
            }

            .track_content_wrap {
                float: left;
                width: calc(100% - 80px);
                margin-left: 5px;

                h3 {
                    width: 100%;
                    font-weight: normal;
                }
            }

            .track__duration {
                position: absolute;
                right: 15px;
                top: 22px;
                opacity: 0.6;
                font-size: 14px;
            }

            .track__actions {
                position: absolute;
                right: 60px;
                top: 15px;
                button {
                    display: inline-block;
                    margin-right: 10px;
                    border-radius: 50%;
                    padding: 8px 9px;
                    font-size: 14px;
                    cursor: pointer;
                    border: none;
                    outline: none;

                    &.track__add_button {
                        font-size: 18px;
                        padding: 5px 10px;
                    }

                    &:hover {
                        background: rgba(255, 255, 255, 0.1);
                    }
                }
            }

            .track__addToPlaylist {
                right: 70px;
                top: 45px;
                z-index: 99999999;
            }

            &.track--Playlist_active a.track__add_button {
                background: rgba(0, 0, 0, 0.7);
                border-radius: 4px!important;
            }

            &:nth-child(even) {
                background: rgba(0, 0, 0, 0.1)
            }
            &:hover {
                transform: none;
                background: rgba(255, 255, 255, 0.06);

                .track_content_wrap {
                    margin-top: 0;
                    background: transparent;
                    box-shadow: none;
                }
            }
        }
        

    }
    
}

.trackScroll_wrap > div {
    overflow: auto;
    will-change: transform;
    height: calc(100vh - 240px);
    width: 104%!important;
}

.trackScroll_wrap.trackScroll_wrap--pagination > div {
    height: calc(100vh - 320px);
}
