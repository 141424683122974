

//Player
#player {
    float: left;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    bottom: 0px;
    background: rgba(32, 32, 48, 0.8);
    height: 110px;
    left: 0;
    z-index: 11;
}


.player__wrapper{
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.player_glow{
    position: absolute;
    z-index: -1;
    width: 600px;
    height: 550px;
    margin: 0 auto;
    margin-top: -450px;
    left: 0;
    right: 0;
    background: radial-gradient(ellipse at center, rgba(139, 135, 229, 0.25) -10%, rgba(240, 232, 251, 0) 74%, rgba(255, 255, 255, 0) 100%);
}


.player_line {
    width: 100%;
    height:1px;
    background: #3f3f3f;
    position: absolute;
    top: 0;
    width: 300px;
    margin: 0 auto;
    left: 220px;
    right: 0px;
    opacity: 0.7;
}

.player_line span {
    display: block;
}

.player_line span:nth-child(1){
    width: 200px;
    height:1px;
    background: #fff;
    margin-left: -200px;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(228,216,246,0) 22%, #3f3f3f 100%);
}
.player_line span:nth-child(2){
    width: 200px;
    height:1px;
    background: #fff;
    margin-left: 270px;
    margin-top: -1px;
    background: linear-gradient(to right, #3f3f3f 0%,rgba(228,216,246,0) 78%,rgba(255,255,255,0) 100%);
}

.player__mode_switch {
    color: #fff;
    position: absolute;
    z-index: 3;
    right: 3px;
    top: 3px;
    padding: 6px;
    cursor: pointer;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    width: 18px;
    height: 20px;
    opacity: 0;

    svg {
        margin-left: 2px;
    }
}


.player__video__wrap {
    width: 270px;
    min-height: 140px;
    position: absolute;
    bottom: 98px;
    padding-bottom: 15px;
    box-sizing: initial;
    left: 0px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    &.player__video-hide{
        .player__video{
            opacity: 0;
        }
    }
    .player__video{
        position: relative;
        .player__songInfo__alternate {
            position: absolute;
            color: rgba(255, 255, 255, 0.5);
            padding: 6px;
            font-size: 12px;
            bottom: 0px;
            left: 0px;

            i {
                border-radius: 50%;
                border: 1px solid;
                width: 12px;
                height: 12px;
                display: inline-block;
                text-align: center;
                font-size: 11px;
                font-style: normal;
                color: #7d97ff;
                margin-right: 3px;
            }
        }
    }


    
    &.player__video__wrap-left {

        .player__video .react-player {
            // max-height: 120px;
            overflow: hidden;

            video {
                margin-top: -30px;
                display: block;
                width: 100%;
            }
        }
    }

    
    &.player__video__wrap-full .react-player video {
        background: #000;
    }

    .player__nowplaying {
        width: 270px;
        float: left;
        font-size: 12px;
        color: #fff;
        padding: 5px 0;
        margin-top: 10px;
        box-sizing: unset;
        transition: all 0.2s linear;
        cursor: pointer;
        position: fixed;
        z-index: 99;
        bottom: 10px;
    
        .player__nowplaying__songInfo__album {
            float: left;
            width: 65px;
            height: 65px;
            margin-right: 10px;
            opacity: 0.7;
            transition: all 0.2s linear;
            display: none;
            img {
                max-width: 100%;
            }
        }
    
        .player__nowplaying__songInfo__details {
            float: left;
            width: calc( 100% - 15px);
            padding-left: 15px;
            min-height: 72px;
    
            .player__songInfo__count {
                font-size: 10px;
                letter-spacing: 0.5px;
                opacity: 0.7;
            }
            .nowPlaying_title{
                font-size: 10px;
                margin-bottom: 2px;
                opacity: 0.6;
                margin-top: -3px;
                color: #bdb8ff;
            }
        
            span{
                display: block;
                margin-bottom: 4px;
                white-space: nowrap;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        
            span.player__songInfo__title {
                letter-spacing: 0.5px;
                dl {
                    display: inline-block;
                    margin: 0;
                    padding: 2px 4px;
                    margin-left: 5px;
                    background: rgba(255, 255, 255, 0.12);
                    font-size: 10px;
                    border-radius: 3px;
                    color: rgba(255, 255, 255, 0.6);
                }
            }
        
            .player__songInfo__artist, .player__songInfo__album, .player__songInfo__pl {
                opacity: 0.6;
                font-size: 11px;
                letter-spacing: 0.3px;
            }
            .player__songInfo__pl {
                width: 140px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                height: 14px;
                a{
                    color: #c8aeff;
                    cursor: pointer;
                    text-decoration: none;
                }
                &:hover{
                    opacity: 1;
                }
            }
            
        }
    
        &:hover .player__nowplaying__songInfo__album{
            opacity: 1;
        }
    }

    &.player__video__wrap-full {
        width: calc(100% - 590px);
        position: fixed;
        left: 270px;
        top: 70px;
        background: #191c2c;

        .react-player {
            width: 100%;
            height: calc(100vh - 350px) !important ;
        }

        .player__mode_switch{
            right: -10px;
            top: -12px;
            background: #594f69;

            svg{
                transform: rotate(50deg);
                margin-left: 0px; 
            }
        }

        .player__nowplaying{
            width: 100%;
            position: relative;
            bottom: auto;
            .player__nowplaying__songInfo__details{
                width: 100%;
                .player__songInfo__artist, .player__songInfo__album {
                    font-size: 13px;
                    letter-spacing: 0px;
                }
                .player__songInfo__title{
                    letter-spacing: 0;
                    font-size: 18px;
                }
    
            }
        }
        

    }
    &:hover{
        .player__mode_switch{
            opacity: 1;
        }
    }
}




.player__controls {
    width: 100%;
    float: left;
    line-height: 16px;
    padding: 20px;

    .player__controls__blocker {
        position: absolute;
        width: 100%;
        text-align: center;
        padding-left: 50px;
        color: #888;
        z-index: 9;
        background: rgba(18, 15, 25, 0.7);
        height: 110px;
        bottom: 0;
        left: 0;
        padding-top: 50px;
        font-size: 11px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}

.player__controls_inner {
    width: 450px;
    margin: 0 auto;
    text-align: center;
    color: #3f3576;
    position: relative;
    left: 50px;
}

// .player__suffle {}

.player__controls_inner > div {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 8px;
    color: #bababa;
}

.player__controls_inner > div span{
    border-radius: 50%;
    background: -moz-linear-gradient(top, #3c3c3c 0%, #232222 100%);
    background: -webkit-linear-gradient(top, #3c3c3c 0%,#232222 100%);
    background: linear-gradient(to bottom, #3c3c3c 0%,#232222 100%);
    cursor: pointer;
}



.player__prev span, .player__next span {
    display: block;
    width: 30px;
    height: 25px;
    padding-top: 5px;
    box-sizing: initial;
}

.player__controls_inner .player__prev, .player__controls_inner .player__next {
    width: 30px;
    height: 30px;
    padding: 5px;
    box-sizing: initial;
    border-radius: 50%;
    background: -moz-linear-gradient(top, #28282a 0%, #080808 100%);
    background: -webkit-linear-gradient(top, #28282a 0%,#080808 100%);
    background: linear-gradient(to bottom, #28282a 0%,#080808 100%);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.6) inset;
}

.player__controls_inner .player__prev-disable span svg, .player__controls_inner .player__next-disable span svg {
    opacity: 0.3;
}

.player__play span {
    display: block;
    width: 55px;
    height: 47px;
    padding-top: 8px;
    box-sizing: initial;
}

.player__play i.play_outline {
    width:57px;
    height: 47px;
    display: block;
    position: absolute;
    z-index: 2;
    top: 6px;
    background: url("../../assets/play_outline.png");
}

.player__play span svg{
    display: inline-block;
    margin-left: 3px;
    margin-top: -2px;
}


.player__repeat span, .player__volume span {
    display: block;
    width: 25px;
    height: 22px;
    padding-top: 3px;
    box-sizing: unset;
}

.player__controls_inner .player__repeat, .player__controls_inner .player__volume {
    width: 25px;
    height: 25px;
    padding: 4px;
    box-sizing: initial;
    border-radius: 50%;

}

.player__controls_inner .player__play, .player__controls_inner .player__repeat, .player__controls_inner .player__volume{
    background: -moz-linear-gradient(top, #28282a 0%, #080808 100%);
    background: -webkit-linear-gradient(top, #28282a 0%,#080808 100%);
    background: linear-gradient(to bottom, #28282a 0%,#080808 100%);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.6) inset;
    position: relative;
    z-index: 2;
}

.player__repeat.player__repeat-active span{
    color: #6e64d9;
}

.player__controls_inner .player__play {
    width: 55px;
    height: 55px;
    padding: 7px;
    box-sizing: initial;
    border-radius: 50%;
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.8) inset;
    cursor: pointer;
}


.player__controls__small {
    width: auto!important;
    color: #fff;
}

.player__controls__small > div {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 8px;
    opacity: 0.6;
    cursor: pointer;
}

.player__duration {
    position: absolute;
    right: 50px;
    top: 45px;
    color: #fff;
    opacity: 0.5;
    font-size: 15px;
}
.player__controls__small {
    .player__video__button-active, .player__shuffle-active{
        color:#fff;
        opacity: 1;
    }
}


//Volume

.player__controls_inner .player__volume__control {
    width: 70px;
    height:20px;
    padding: 1px;
    background: -moz-linear-gradient(top, #28282a 0%, #080808 100%);
    background: -webkit-linear-gradient(top, #28282a 0%,#080808 100%);
    background: linear-gradient(to bottom, #28282a 0%,#080808 100%);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.6) inset;
    border-radius: 0 10px 10px 0;
    margin-left: -18px;
    top: 0px;
    padding-left: 10px;
    padding-right: 8px;
    position: relative;
    z-index: 1;

    input {
        width: 100%;
    }

    input[type=range]{
        z-index: 3;
        -webkit-appearance: none;
        cursor: pointer;
    }
    input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 7px;
        background: rgba(255, 255, 255, 0.1);
        border: none;
    }
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        height: 7px;
        width: 10px;
        background: rgba(255, 255, 255, 0.1);
        margin-top: 0px;
        margin-left:0px
    }
    input[type=range]:focus {
        outline: none;
    }



    input[type=range]::-moz-range-track {
        width: 100%;
        height: 7px;
        background: rgba(255, 255, 255, 0.1);
        border: none;
    }
    input[type=range]::-moz-range-thumb {
        border: none;
        height: 7px;
        width: 10px;
        background: rgba(255, 255, 255, 0.1);
        margin-top: 0px;
        margin-left:0px
    }
    input[type=range]::-moz-focusring{ 
        outline: none;
        border:none;
    }

}

@supports (-moz-appearance:none) {
    .player__controls_inner .player__volume__control { 
        top: 6px;
    } 
}



.player__controls_inner .player__playDirect {

    span{
        background: transparent;
    }

    &.player__playDirect--active {
        color: #694eff;
    }
}


//SEEK
.player_seek {
    position: absolute;
    bottom: 100px;
    z-index: 222;
    width: 100%;
    height: 12px;

    input, progress {
        width: 100%;
        position: absolute;
    }

    //Seek Progress
    progress {
        height: 7px;
        transition:all 0.15s linear;
        cursor:pointer;
        background: rgba(0, 0, 0, 0.2);
        border: none;
    }
    progress:nth-child(2){
        z-index: 2;
    }
    progress[value]::-webkit-progress-bar {
    background: rgba(0, 0, 0, 0.05);
    }

    progress[value]::-webkit-progress-value {
        background: -moz-linear-gradient(-45deg, rgba(43,28,63,1) 0%, rgba(112,66,147,1) 62%, rgba(42,86,227,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, rgba(43,28,63,1) 0%,rgba(112,66,147,1) 62%,rgba(42,86,227,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, rgba(43,28,63,1) 0%,rgba(112,66,147,1) 62%,rgba(42,86,227,1) 100%);
    }
    progress:nth-child(3) {
        opacity: 0.5;
        
    }
    progress:nth-child(3)[value]::-webkit-progress-value {
        background-image:none;
        background: rgba(255, 255, 255, 0.6);
    }
    //Seek Control
    input[type=range]{
        z-index: 3;
        -webkit-appearance: none;
        height: 0px;
        padding: 0px;
        top: -2px;
        transition:all 0.15s linear;
        cursor:pointer;
    }
    
    input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 15px;
        background: transparent;
        border: none;
    }
    
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        height: 16px;
        width: 10px;
        background: rgba(255, 255, 255, 0.3);
        margin-top: 0px;
        margin-left:-6px
    }
    
    input[type=range]:focus {
        outline: none;
    }
    
    input[type=range]:focus::-webkit-slider-runnable-track {
        background: transparent;
    }
    &:hover progress {
        height: 10px;
    }
    &:hover input[type=range] {
        height: 11px;
        cursor:pointer;
    }


    progress[value]::-moz-progress-bar {
        background: -moz-linear-gradient(-45deg, rgba(43,28,63,1) 0%, rgba(112,66,147,1) 62%, rgba(42,86,227,1) 100%);
        }

    input[type=range]::-moz-range-track {
        width: 100%;
        height: 15px;
        background: transparent;
        border: none;
        border-radius: none;
    }
    input[type=range]::-moz-range-thumb {
        border: none;
        height: 16px;
        width: 10px;
        background: rgba(255, 255, 255, 0.2);
        margin-top: 0px;
        margin-left:-6px;
        border-radius: none;
    }
    
    input[type=range]::-moz-focusring{ /*hide the outline behind the border*/
        background: transparent;
    }

}

.playerBG {
    position: absolute;
    height: 104px;
    overflow: hidden;
    right: 0;
    bottom: 0px;
    z-index: -1;
    img {
        margin-top: -50px;
        opacity: .1;
        max-width: 380px;
        max-height: 230px;
    }
    &:before {
        z-index: 1;
        content: "";
        width: 100px;
        height: 120px;
        position: absolute;
        background: linear-gradient(to right, rgb(31, 32, 47) 0%,rgba(0,0,0,0) 100%);
    }
}
