.playlistSkeleton {
    width: 100%;
    float: left;

    .playlistSkeleton__header {
        width: 100%;
        float: left;

        .playlistSkeleton__header__right {
            width: 25%;
            float: left;
        }
        .playlistSkeleton__header__title {
            width: 75%;
            float: left;
            box-sizing: border-box;
            padding-left: 30px;
            padding-top: 20px;
        }

        .playlistSkeleton__header__title div:nth-child(1) {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
            position: relative;
            overflow: hidden;
        }
        
        .playlistSkeleton__header__title div:nth-child(2) {
            width: 200px;
            height: 12px;
            display: inline-block;
            position: relative;
            overflow: hidden;
        }
        
        .playlistSkeleton__header__right > div {
            width: 30px;
            height: 10px;
            margin: 20px;
            float: right;
            position: relative;
            overflow: hidden;
        }

    }
    
    .playlistSkeleton__body {
        width: 100%;
        float: left;
        margin-top: 25px;

        .playlistSkeleton__song {
            width: 100%;
            float: left;
            box-sizing: border-box;
            padding: 7px 15px;
        }

        .playlistSkeleton__song:nth-child(even){
            background: rgba(0, 0, 0, 0.15);
        }  

        .playlistSkeleton__song div:nth-child(1) {
            width: 60px;
            height: 60px;
            display: inline-block;
            position: relative;
            overflow: hidden;
            
        }
        
        .playlistSkeleton__song div:nth-child(2) {
            width: 280px;
            height: 12px;
            position: relative;
            display: inline-block;
            margin-left: 15px;
            top: -40px;
            position: relative;
            overflow: hidden;
        }
        .playlistSkeleton__song div:nth-child(3) {
            width: 140px;
            height: 10px;
            position: relative;
            display: block;
            margin-left: 75px;
            top: -30px;
            position: relative;
            overflow: hidden;
        }

   

    }
}


.trackSkeleton__body {
    margin-top: 20px;
    width: 100%;
    float: left;

    .trackSkeleton__song {
        width: 210px;
        float: left;
        margin: 15px;
        margin-bottom: 20px;

        div:nth-child(1) {
            width: 210px;
            height: 110px;
            position: relative;
            overflow: hidden;
            border-radius: 5px;
        }
        div:nth-child(2) {
            width: 210px;
            height: 12px;
            margin-top: 12px;
            position: relative;
            overflow: hidden;
        }
        div:nth-child(3) {
            width: 140px;
            height: 8px;
            margin-top: 10px;
            position: relative;
            overflow: hidden;
        }
    }
}
.searchresults .trackSkeleton {
    padding: 10px 30px;
}


.artistSkeleton__body {
    width: 100%;
    float: left;
}

.artistSkeleton__artist {
        display: inline-block;
        width: calc(33.33% - 20px);
        height: 100px;
        margin: 20px 10px;
        padding: 10px;

        .artistSkeleton__artist_left {
            width: 80px;
            height: 80px;
            float: left;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            background: rgba(255, 255, 255, 0.04);
        }
        .artistSkeleton__artist_right{
            width: calc(100% - 80px);
            float: left;
            padding-left: 10px;

            div {
                width: 90%;
                height: 14px;
                background: rgba(255, 255, 255, 0.04);
                margin: 10px 0;
                position: relative;
                overflow: hidden;
            }

            div:nth-child(2) {
                width: 70%;
                height: 8px;
                position: relative;
                overflow: hidden;
            }
            
            div:nth-child(3) {
                width: 70%;
                height: 8px;
                position: relative;
                overflow: hidden;
            }
        }

}


.contentSekeleton div, .titleSkeleton{
    position: relative;
    overflow: hidden;
}
.contentSekeleton  {
    div{
        margin-bottom: 16px;
    }
    
    div:last-child {
        width: 65%!important;
    }
}

.playlistSkeleton__song div:nth-child(1), 
.playlistSkeleton__song div:nth-child(2), 
.playlistSkeleton__song div:nth-child(3),
.playlistSkeleton__header__title div:nth-child(1),
.playlistSkeleton__header__title div:nth-child(2),
.playlistSkeleton__header__right > div,
.trackSkeleton__song div:nth-child(1),
.trackSkeleton__song div:nth-child(2),
.trackSkeleton__song div:nth-child(3),
.artistSkeleton__artist_left,
.artistSkeleton__artist_right div,
.contentSekeleton div,
.titleSkeleton
{
    background: rgba(255, 255, 255, 0.04);
    &:after{
        content:'';
        top:0;
        transform:translateX(100%);
        width:100%;
        height:120px;
        position: absolute;
        z-index:1;
        animation: slide 1s infinite;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(128, 186, 232, 0.03) 99%, rgba(125, 185, 232, 0.02) 100%);            
    }
}


@keyframes slide {
	0% {transform:translateX(-100%);}
	100% {transform:translateX(100%);}
}

@keyframes slideLeft {
	0% {transform:translateX(100%);}
	100% {transform:translateX(-100%);}
}


