.toggle {
    margin: 10px 0;

    .toggle__title {
        background: rgba(0, 0, 0, 0.2);
        padding: 10px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.6);

        &:hover {
            color: rgba(255, 255, 255, 0.8);
        }
    }

    .toggle__content {
        padding: 20px;
        padding-top: 10px;
        background: rgba(0, 0, 0, 0.06);
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    &.toggleActive{
        .toggle__title {
            color: rgba(255, 255, 255, 1);
            background: rgba(255, 255, 255, 0.15);
        }
    }
}
