#about {
    color: #9e9eb5;
    line-height: 1.7em;
    font-size: 14px;

    .aboutIntro{
        margin-top: 30px;
    }

    .about__block{
        margin: 40px 0;

        img {
            max-width: 360px;
            display: inline-block;
            vertical-align: top;
        }

        .about__block__content{
            display: inline-block;
            width: calc( 100% - 450px);
            margin-left: 40px;
            top: 30px;
            position: relative;
            h3 {
                color: #8c62f5;
                span {
                    font-size: 11px;
                    background: #8c62f5;
                    color: #fff;
                    padding: 2px 6px;
                    border-radius: 3px;
                    margin-left: 8px;
                }
            }
            p {
                
                line-height: 1.7em;
                font-size: 14px;
                margin-top: 0px;
            }
        }



    }

    
}

.customScrollbar.helpAboutScroll > div {
    height: calc(100vh - 300px);
    color: #9e9eb5;
    overflow: auto;
    will-change: transform;
}
