#chatbox{
    color: #90909a;

    h4{
        span {
            float: right;
            margin-right: 20px;
            font-size: 11px;
            margin-top: 5px;
        }
    }

    
    .channels__chat__msgs {
        box-shadow: -4px 3px 10px rgba(0, 0, 0, 0.2) inset;
        padding: 0 15px;

        &> div {
            overflow: auto;
            will-change: transform;
            height: calc(100vh - 330px);
            width: 100%!important;
            color: #484863;
            &::-webkit-scrollbar {
               width: 12px;
               border-radius: 12px;
               background: transparent;
               border: 0px solid transparent;
            }
              
            &::-webkit-scrollbar-thumb {
               width: 10px;
               border-radius: 16px;
               border: 3px solid transparent;
               box-shadow: inset 0 0 0 3px;
            }
         
        }
        .channels__chat__prevBtn {
            width: 100%;
            padding: 5px;
            border: none;
            background: rgba(0, 0, 0, 0.3);
            color: #aaa;
            font-size: 13px;
            cursor: pointer;
         }
    }

    .chatMessage{
        width: 100%;
        float: left;
        border-bottom: 1px solid rgba(255, 255, 255, 0.06);
        font-size: 14px;

        p{
            color: #d0d0d0;
        }

        .chatMessage__header{
            font-size: 12px;
            padding-top: 10px;

            .chatMessage__name{
                display: inline-block;
                margin-right: 10px;
                margin-right: 8px;
                color: #bbb;
                a{
                    color: #bbb;
                    text-decoration: none;

                    &:hover{
                        color: #fff;
                    }
                }
            }
            .chatMessage__time{
                display: inline-block;
                font-size: 11px;
            }
        }

        .chatMessage__avatar {
            width: 30px;
            float: left;
            margin-right: 15px;
            margin-top: 12px;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 30px;
                height: 30px;
                display: block;
            }
        }
        
        .chatMessage__content {
            width: calc(100% - 40px);
            margin-left: 40px;
            color: #82828a;
            word-break: break-word;

            span.chat_youtubeLink {
                color: #fff;
                text-decoration: underline;
                cursor: pointer;
            }
        }


        &.botMessage{
            .chatMessage__avatar {
                display: none;
            }
            .chatMessage__content {
                width: 100%;
                margin-left: 0;
                padding: 8px 0;
                word-break: break-word;
                .emoji-mart-emoji {
                    position: relative;
                    top: 4px;
                }
                p {
                    display: inline;
                    font-size: 12px;
                    margin-left: 6px;
                    color: #9696a0;
                }
            }
            .chatMessage__header {
                display: inline;
                .chatMessage__name {
                    margin-left: 10px;
                    margin-right: 0;
                    img {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                    }
                }
            }
        }

    }

    .channels__chat__form {
        width: 100%;
        float: left;
        position: absolute;
        bottom: 110px;
        padding: 15px;
        padding-bottom: 0px;
        background: rgba(0, 0, 0, 0.1);

        textarea {
            width: calc(100% - 45px);
            border-radius: 7px 0 0 7px;
            height: 60px;
            font-size: 13px;
            padding: 10px;
            box-sizing: border-box;
            border-color: rgba(255, 255, 255, 0.1);
            outline: none;
            max-height: 60px;
        }
        textarea::-webkit-scrollbar {
            width: 12px;
            border-radius: 12px;
            background: transparent;
            border: 0px solid transparent;
        }
           
        textarea::-webkit-scrollbar-thumb {
            width: 10px;
            border-radius: 16px;
            border: 3px solid transparent;
            box-shadow: inset 0 0 0 3px;
        }
        
        button {
            border: none;
            color: #fff;
            padding: 16px 7px;
            top: -26px;
            position: relative;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 0 7px 7px 0;
        }

        .wordCout {
            position: absolute;
            right: 65px;
            bottom: 4px;
            font-size: 12px;
        }
        .emojiiPickerBtn {
            position: absolute;
            right: 112px;
            bottom: 0px;
            font-size: 22px;
            cursor: pointer;
            &.pickerActive{
               background: #6e4aff;
               color: #fff;
               border-radius: 3px;
            }
        }

        &.chatmsg_overlimit .wordCout {
            color: #b95a5a;
        }
    }


    .chatVideoPopup {
        position: absolute;
        top: 200px;
        width: 360px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        font-size: 13px;
        right: 35px;
        background: #10121d;
        border-radius: 5px;

        .chatVideoPopup__close {
            position: absolute;
            right: -6px;
            top: -10px;
            background: #000;
            padding: 2px 7px;
            border-radius: 50%;
            cursor: pointer;
        }

        .chatVideoItem__cover {
            width: 135px;
            float: left;
            margin-right: 10px;

            img {
                max-width: 100%;
            }
        }

        .chatVideoItem__content {

            .chatVideoItem__content__title {
                margin-bottom: 5px;
                padding-top: 8px;
                color: #fff;
            }
        }

        .chatVideoItem__add {
            margin-top: 10px;
            border: none;
            color: #fff;
            outline: none;
        }


        .song__actions__confirm {
            width: 100%;
            position: absolute;
            background: #0f1017;
            color: #999;
            -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
            padding: 10px;
            text-align: left;
            border-radius: 4px;

            button {
                display: inline-block;
                border: none;
                outline: none;
                background: #333;
                color: #fff;
                margin: 0 5px;
                margin-top: 0px;
                border-radius: 15px;
                padding: 3px 15px;
                opacity: 0.7;
                -webkit-transition: all 0.1s linear;
                -o-transition: all 0.1s linear;
                transition: all 0.1s linear;
            }
            
            button.confirm_yes {
                background: #2f9763;
            }
        }
        
        .minPlaylistSelect {
            margin-top: 10px;
            margin-bottom: 10px;
            width: 140px!important;
            margin-left: 10px;
        }
        
    }
    
    .overlay_msg_inner {
        margin: 10px 0;

        h3{
            display: none;
        }

        p {
            margin: 10px!important;
            font-size: 14px;
        }
    }
    
}