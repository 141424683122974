
//Page

#page {
    position: fixed;
    z-index: 9999;
    width: calc(100% - 590px);
    height: calc(100vh - 200px);
    background: rgb(25, 28, 44);
    top: 90px;
    left: 270px;
    padding: 30px;
    padding-top: 15px;
    color: #fff;
    font-size: 14px;

    .pageTitle{
        color: #735be1;
        font-size: 16px;
    }

    .pageContent{
        color: #acacb7;
        line-height: 1.6em;

        a{
            color: #8d76f7;
        }
        .customScrollbar > div {
            height: calc(100vh - 300px);
            color: #92939c;
            overflow: auto;
            will-change: transform;
        }
    }

    &:before {
        content: "";
        width: 100%;
        height: 30px;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(25, 28, 44, 1) 100%);
        position: absolute;
        left: 0;
        top: -25px;
    }

    .pageClose {
        position: absolute;
        right: 25px;
        border: none;
        opacity: 0.6;
        transition: all 0.14s linear;

        &:hover {
            opacity: 1;
            transform: rotate(-90deg);
        }
    }

}
