.Modal{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9999999999;
    background: rgba(0, 0, 0, 0.6);
    left: 0;
    top: 0;
    transition: all 0.2s linear;
    opacity: 0;
    visibility: hidden;

    .Modal__inner {
        width: 70%;
        min-width: 300px;
        background: #1f2035;
        margin: 13% auto;
        border-radius: 5px;
        color: #fff;
        font-size: 13px;
        transition: all 0.2s linear;

        .Modal__header {
            padding: 20px;
            background: rgba(0, 0, 0, 0.15);
            position: relative;
            width: 100%;
    
            .Modal__heade__close {
                position: absolute;
                z-index: 3;
                right: 0;
                top: 0;
                opacity: 0.5;
                padding: 15px;
                cursor: pointer;
                transition: all 0.1s linear;
            }
            .Modal__heade__close:hover {
                opacity: 1;
                transform: rotate(90deg);
            }
    
            .Modal__header__title {
                opacity: 0.6;
                color: #aab8d5;
                letter-spacing: 0.5px;
                font-size: 14px;
            }

            &.Modal__header--none{
                background: transparent;
                .Modal__header__title {
                    color: transparent;
                }
            }
        }
        
        .Modal__content {
            min-height: 85px;
            padding: 20px 30px;
        }

    }

    &.Modal-show{
        opacity: 1;
        visibility: visible;

        .Modal__inner {
            margin: 15% auto;
        }
    }

    &.Modal-small {
        .Modal__inner {
            width: 50%;
            max-width: 400px;

        }
    }
    &.Modal-medium {
        .Modal__inner {
            width: 50%;
            max-width: 800px;
        }
    }

    &.Modal-large {
        .Modal__inner {
            width: 75%;
            max-width: 1200px!important;
            margin: 5% auto;
        }
    }

        
}

//SONG ADDED NOTIFICATIOn
.ToastBox {
    position: absolute;
    z-index: 999999999;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    width: 50%;
    top: 15px;
    transition: all 0.2s linear;

    .message {
        padding: 15px;
        font-size: 14px;
        box-sizing: unset;
        margin: 0;
        border-radius: 5px;
        transition: all 0.2s linear;
        opacity: 0;
        strong {
            color: rgba(255, 255, 255, 0.8);
            letter-spacing: 0.4px;
        }
    }
    .message.success{
        opacity: 1;
        background: rgba(37, 180, 156, 0.7);
        color: #9ccbc7;
    }
    .message.error{
        opacity: 1;
        background: rgba(127, 44, 58, 0.7);
        color: #c99da0;
    }
}
