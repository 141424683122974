//LIBRARY
#SearchLibrary {

    .SearchLibrary__wrap{
        position: fixed;
        z-index: 999999;
        width: calc(100% - 380px);
        height: calc(100vh - 150px);
        left: 30px;
        background: #191c2c;
        top: 20px;
        font-size: 12px;
        color: #7c849b;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.6);
        border-radius: 5px;
    }

    .searchbar {
        margin: 25px 0;
        padding-left:40px;
        width: 100%;
        float: left;

        form {
            width: calc(100% - 55px);
            float: left;
        }

        input[type="text"] {
            padding: 20px 30px;
            padding-right: 60px;
            border: none;
            font-size: 16px;
            color: #fff;
            width: 100%;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 40px;
            outline: none;
        }
        button {
            position: absolute;
            border: none;
            margin-left: -60px;
            padding: 15px;
            opacity: 0.4;
            transition: all 0.2s linear;
            outline: none;

            &:hover{
                opacity: 1;
            }
        }
        .closeLibrary {
            float: right;
            position: relative;
            margin-left: 0;
        }
        .importSongInfoCheck.custom_checkbox {
            margin-top: 5px;
            margin-bottom: 0px;
            position: relative;
            top: 11px;
            margin-left: 10px;
            span {
                top: 4px;
                position: relative;
                color: #888b99;
            }
            label{
                transform: scale(0.7);
            }
        }

        @keyframes autofill {
            to {
                color: #e9e9e9;
                background: rgba(0, 0, 0, 0.3);
            }
        }
        @-webkit-keyframes autofill {
            to {
                color: #e9e9e9;
                background: rgba(0, 0, 0, 0.3);
            }
        }  
        input:-webkit-autofill {
            animation-name: autofill;
            animation-fill-mode: both;
            -webkit-animation-name: autofill;
            -webkit-animation-fill-mode: both;
        }
        
    }

    .searchresults__left {
         width: calc(100% - 220px);
         float: right;
         .customScrollbar{
            max-height: calc(100vh - 300px);
            overflow: auto;
         }
    }
    .searchresults__right {
        width: 220px;
        float: left;
        padding-top: 5px;
        height: calc(100vh - 258px);
        background: rgba(0, 0, 0, 0.2);

        button {
            background: #4a495f;
            border: none;
            padding: 7px;
            display: block;
            width: calc(100% - 30px);
            margin: 15px;
            color: #abaaaf;
            border-radius: 3px;
            margin-top: 6px;
            transition: all 0.2s linear;

            &:hover{
                background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                color: #fff;
            }
        }

        .searchresults__right__content {
            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
                
                ul {
                    margin-top: 10px;
                    background: rgb(22, 25, 40);
                    li {
                        padding-left: 8px;
                        padding-top: 6px;
                        max-width: 100%;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        color: #7c849b;
                        cursor: pointer;

                        &.active {
                            color: #fff;
                            background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                        }
                    }
                }


            }
            ul > li {
                min-height:20px;
                padding: 10px;

                a {
                    cursor: pointer;
                }

                &.active{
                    background: rgba(255, 255, 255, 0.06);
                }
            }
        }
    }

    table {
        width: 100%;
        thead tr th {
            padding: 12px 10px;
            background: rgba(0, 0, 0, 0.2);
            cursor: pointer;
            text-align: left;

            &:nth-child(1){
                width: 38%;
            }
        }

    }
    //Library Song
    tbody {

        tr{
            cursor:pointer;
            td {
                padding: 12px 10px;
                color: #7c849b;
                position: relative;
            }
            &:nth-child(even) {
                background: rgba(255, 255, 255, 0.03);
            }
            &:hover {
                background: rgba(93, 72, 170, 0.43);
            }
            &:hover td{
                color: #a8b0c7;
            }
            &.active{
                background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
            }
            &.active td {
                color: #fff;
            }
        }
    }
            
    .libSong {
        width: 100%;
        float: left;
        color: #7c849b;
        padding: 10px;
        position: relative;

        &:nth-child(even) {
            background: rgba(255, 255, 255, 0.03);
        }
        &:hover {
            background: rgba(93, 72, 170, 0.43);
        }
        &:hover > div{
            color: #a8b0c7;
        }
        &.active{
            background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
        }

        .playIcon{
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 12px solid #fff;
            height: 0px;
            margin-top: 5px;
        }

        .localSong {
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 11px 0 0;
            border-color: #5b51ab transparent transparent transparent;
        }

        .libSong__title {
            width: 40%;
            padding-right: 20px;
            float: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .libSong__artist {
            width: 30%;
            float: left;  
            padding-right: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .libSong__album {
            width: 30%;
            float: left;  
            padding-right: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .libsongActionsWrap {
            display: none;
            position: absolute;
            top: 0;
            width: 120px;
            right: 0;
            padding: 6px;
            height: 100%;
            color: #fff;
            text-align: right;
            background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);

            button {
                border: none;
                outline: none;
            }
            .libOptionsSong {
                font-size: 10px;
                letter-spacing: -0.5px;
                padding: 4px;
                margin-left: 5px;
            }

            .libsongActions {
                width: 160px;
                background: #0f111c;
                right: 6px;
                position: absolute;
                text-align: left;
                z-index: 7;
                border-radius: 4px;
                box-shadow: 0 10px rgba(0, 0, 0, 0.12);

                    ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    li {
                        padding: 10px;
                        font-size: 12px;
                        opacity: 0.7;
                        &:hover, &.active {
                            opacity: 1;
                            background: rgba(0, 0, 0, 0.15);
                        }
                    }

                }


                .libAddSong {
                    position: absolute;
                    background: #0f111c;
                    right: 0px;
                    width: 180px;
                    top: 0px;
                    z-index: 5;
                    border-radius: 4px;
                    box-shadow: 0 10px rgba(0, 0, 0, 0.12);

                    .libPlaylists{

                        button {
                            display: block;
                            padding: 7px 10px;
                            padding-left: 20px;
                            width: 100%;
                            position: relative;
                            color: #8b919e;
                            border: none;
                            outline: none;
                            text-align: left;
                        }
                        button.active {
                            color: #fff!important;
                        }
                        button:nth-child(even) {
                            background: rgba(0, 0, 0, 0.14);
                        }
                        svg {
                            position: absolute;
                            margin-left: -17px;
                        }
                        
                    }
                    
                }

                .libEditSong {
                    position: absolute;
                    background: #0f111c;
                    right: 0px;
                    width: 180px;
                    top: 0px;
                    box-shadow: 0 10px rgba(0, 0, 0, 0.12);
                    z-index: 5;
                    border-radius: 4px;

                    input {
                        border: none;
                        display: block;
                        border-radius: 3px;
                        padding: 7px;
                        background: rgba(129, 131, 163, 0.2);
                        margin: 6px 10px;
                        width: calc(100% - 20px);
                        box-shadow: 0 0 9px rgba(0, 0, 0, 0.3) inset;
                        border: 1px solid #272a3c;
                        font-size: 12px;
                        letter-spacing: 0.4px;
                    }
                }

                .libsongActions__buttons {
                    padding: 5px 10px;
                    text-align: center;
                    padding-bottom: 10px;
                    button{
                        width: 60px;
                        padding: 4px 7px;
                        opacity: 0.7;
                        display: inline-block;
                        margin: 0px 5px;
                        border-radius: 30px;                                   
                    }

                    button.libConform {
                        background: #0fab84;
                    }
                    
                    button.libCancel {
                        background: #303446;
                    }
                    button:hover{
                        opacity: 1;
                    }
                }
                
            }
        }
    }

    .folderSelector_wrapper{
        .libPath {
            padding: 15px 10px;
            background: rgba(0, 0, 0, 0.1);
            margin: 6px 0;
            border-radius: 3px;
            color: #9293b1;
            width: 100%;
            position: relative;

            a {
                display: inline-block;
                width: calc(100% - 130px);
                line-height: 20px;
            }
        
            span {
                background: #9293b1;
                color: #111;
                font-size: 11px;
                padding: 5px 4px;
                border-radius: 2px;
            }
    
            button {
                float: right;
                border: none;
                background: #383c54;
                border-radius: 3px;
                margin: 0 4px;
                padding: 5px 6px;
                font-size: 12px;
            }

            .libPath__stopScanBtn {
                margin-top: -3px;
                right: -5px;
                position: relative;
            }

            .libPath__actions {
                position: absolute;
                right: 12px;
                top: 12px;
            }            

            .libPath__removeBtn:hover{
                background: rgb(145, 74, 74);
                color: rgb(223, 154, 154);
            }
        }

        .scanning__notification {
            padding: 10px;
            margin: 0;
            text-align: center;
            color: #7379a9;
            margin-top: 10px;
            border-radius: 3px;
        }

        .addtoLibButn {
            display: block;
            width: 100%;
            padding: 10px;
            margin-top: 25px;
            border-radius: 3px;
            border: none;
            background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);

            &.addtoLibButn--scan {
                background: #36344a;
                color: #9293b1;
                margin-top: 10px;
            }
        }
    
    }



    
    .libSong.active .libsongActionsWrap, .libSong:hover .libsongActionsWrap {
        display: block;
    }
    .libSong.active button.libOptionsSong {
        background: #0f111c;
        border-radius: 3px 3px 0 0;
    }
    .libSong.obsecureLibSong {
        opacity: 0.5;
    }
    .SearchLibrary__bg {
        width: calc(100% - 320px);
        height: calc(100vh - 110px);
        left: 0px;
        background: rgba(11, 10, 16, 0.7);
        top: 0px;
        position: absolute;
        display: block;
        z-index: 999;
    }

    .lib_nosongs{
        margin: 40px 0px;
        text-align: center;
    }
}

