.playlistlikers {
    position: relative;

    .playlistlikers__count {
        position: absolute;
        top: -20px;
        left: -30px;
        padding: 8px;
        text-align: center;
        width: calc(100% + 60px);
        background: rgba(255, 255, 255, 0.05);
        font-size: 12px;
        color: #6c7488;
    }

    p {
        margin-top: 0;
        opacity: 0.7;
    }

    ul {
        margin: 0;
        padding: 0;
        padding-top: 10px;
        list-style-type: none;
        li {
            display: inline-block;
            width: 33.33%;
            padding: 10px;
            text-align: center;

            img {
                width: 70px;
                height: 70px;
                margin-bottom: 5px;
                padding: 7px;
                border-radius: 50%;
                object-fit: cover;
                background: rgba(255, 255, 255, 0.1);
            }

            span {
                display: block;
                color: #c7cbd8;
            }

            a {
                text-decoration: none;
            }
            span:nth-child(2) {
                font-weight: bold;
                margin-bottom: 3px;
            }
            span:nth-child(3) {
                opacity: 0.5;
            }

            &:hover img {
                border-radius: 50%;
                background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
            }
            
            &:hover span:nth-child(2) {
                color: #fff;
            }
        }
    
    }
}