.welcomeTour {
    position: fixed;
    z-index: 9999999;
    color: #fff;
    padding: 25px;
    font-size: 13px;
    line-height: 1.7em;
    width: 450px;
    border-radius: 5px;
    bottom: 120px;
    right: 20px;
    background:url('../../assets/welcomebg.png') no-repeat 110px -20px, linear-gradient(to left, rgba(103,68,157,1) 0%, rgba(103,68,157,1) 8%, rgba(59,37,102,1) 35%, rgba(65,54,145,1) 77%, rgba(92,86,200,1) 100%);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.3);
    
    .closeTour {
        position: absolute;
        right: 5px;
        border: none;
        top: 5px;
        font-family: cursive;
        outline: none;
    }

    .welcomeTour__inner{
        h4 {
            font-size: 15px;
            margin-top: 10px;
        }
        p {
            max-width: 265px;
            opacity: 0.65;
        }

        button {
            background: linear-gradient(135deg, #8e5bd0 0%, #3453d7 100%);
            font-size: 12px;
            padding: 5px 18px;
            opacity: 1;
            display: inline-block;
            border-radius: 30px;
            border: none;
            margin-top: 5px;
            margin-bottom: 10px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        }
    }
}
.reactour__popover {
   background-color: #4d18ab!important;
   background: linear-gradient(304deg, #29174a, transparent);
   color: #fff!important;
   font-size: 14px;
   line-height: 1.6em;
   border-radius: 7px!important;
   z-index: 999999999999!important;

    .tour_content{
        p{
            opacity: 0.7;
            b {
                color: #fff;
            }
        }
    }

    .eTpeTG {
        width: 12px;
        height: 12px;
        
        &:hover {
            color: #fff;
        }
    }
    .ONXfv:hover, .kigxVq:hover{
        color: #fff;
    }

}


.tour_addSong_methods {
    li:after {
        content: "";
        border: 3px solid #6e51f3;
        border-radius: 30px;
        height: 18px;
        width: 18px;
        position: absolute;
        margin-top: 15px;
        margin-left: -25px;
        animation: blinky 1.5s ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
    }
    li:nth-child(1):after{
        display:none;
    }
}


.blinky{color:white;}
.blinky:after{
    content:"";
    border: 3px solid #999;
    border-radius: 30px;
    height: 18px;width: 18px;
    position: absolute;
    left:130px;
    margin-top:-5px;
    animation: blinky 1s ease-out;animation-iteration-count: infinite; 
    opacity: 0.0
}

@keyframes blinky {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}
