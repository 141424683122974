#profile_box {
    width: 100%;
    float: left;
    transition: all 0.3s linear;
    position: relative;
    margin-bottom: 30px;
    // background: linear-gradient(135deg, rgba(59, 64, 152, 0.7) 0%, rgba(76, 59, 133, 0.7) 100%);
    // box-shadow: 0 0 30px rgba(0, 0, 0, 0.21) inset;

    .profile_box__inner {
        width: 100%;
        float: left;
        transition: all 0.3s linear;
        height: 0px;
        overflow: hidden;
        opacity: 0;
    }
    &.active .profile_box__inner {
        height: 140px;
        padding: 20px 40px;
        opacity: 1;
    }

    .profile_box__left {
        width: calc(100% - 250px);
        float: left;

        .friend_action_wrap {
            display: inline-block;

            .friend_action {
                padding: 2px 15px;
                padding-right: 15px;
                text-align: center;
                color: rgba(255, 255, 255, 0.7);
                cursor: pointer;
                display: inline-block;
                border-radius: 20px;
                font-size: 12px;
                margin-left: 6px;
                line-height: 20px;
                background: rgba(255, 255, 255, 0.15);

                // border-radius: 3px;
                // border: none;
                // background: #10a385;

                &.actionable:hover{
                    color: #fff;
                    background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                    border-color: transparent;
                    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
                }
            }

            .renderFriendActionDropdown {
                display: inline-block;
                margin-left: 4px;
                position: relative;

                span {
                    padding: 0px 5px;
                    cursor: pointer;
                    background: rgba(255, 255, 255, 0.15);
                    border-radius: 50%;
                    display: inline-block;
                }

                div {
                    position: absolute;
                    padding: 8px;
                    font-size: 11px;
                    right: 0px;
                    line-height: 14px;
                    width: 120px;
                    background: #212434;
                    text-align: center;
                    border-radius: 4px;
                    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
                    cursor: pointer;
                    color: rgba(255, 255, 255, 0.6);

                    &:hover {
                        color: #fff;
                    }
                }
            }
            
        }

        .profile_content__title {

            h3 {
                letter-spacing: 0.7px;
                font-size: 14px;
                color: #fff;
                .profile_station_button a {
                    font-size: 11px;
                    text-decoration: none;
                    display: inline-block;
                    padding: 0px 10px;
                    margin-left: 10px;
                    background: rgba(194, 172, 255, 0.18);
                    color: rgba(255, 255, 255, 0.65);
                    border-radius: 3px;
                    font-weight: normal;
                    text-transform: uppercase;
                    transition: all 0.1s linear;

                    &:hover {
                        background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                        color: #fff;
                    }
                }

            }
        }
        
        .profile_content__bio p {
            margin-top: 3px;
        }
        

        .profile_image {
            float: left;
            margin-right: 20px;
            width: 100px;
            border-radius: 50%;
            height: 100px;
            border: 7px solid rgba(255, 255, 255, 0.1);
            overflow: hidden;

            img {
                max-width: 100%;
                height: 100px;
                object-fit: cover;
            }
        }
        .profile_content {
            font-size: 13px;
            color: rgba(255, 255, 255, 0.5);
            line-height: 1.7em;
            float: left;
            width: calc(100% - 130px);
        }

    }
    
    .profile_box__right {
        width: 200px;
        float: right;
        text-align: center;

        .profile_stats {

            & > div {
                width: 100px;
                height: 60px;
                display: inline-block;
                text-align: center;
                color: rgba(255, 255, 255, 0.68);
                margin-top: 18px;
            }

            & > div > span {
                display: block;
                font-size: 14px;
                line-height: 1.6em;
            }

            & > div > span:nth-child(1) {
                font-size: 20px;
                font-weight: bold;
            }

        }
    }

    .hide_profile_button {
        width: 36px;
        height: 20px;
        position: absolute;
        z-index: 9;
        margin: 0 auto;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        color: rgba(255, 255, 255, 0.72);
        background: rgb(44, 50, 81);
        transform: skewX(-10deg);
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.2s linear;
        
        &.active .hide_profile_button {
            color: rgba(255, 255, 255, 0.32);
            background: rgb(32, 35, 51);
        }
    }

    &:hover .hide_profile_button {
        opacity: 1;
    }
}


#publicProfile {
    //margin-bottom: 200px;
    width: 100%;
    float: left;
    
    .playlist__header {
        width: 88%;
        margin: 0px 6%;
        border-radius: 0;
        background: linear-gradient(135deg, #2a2f4e 0%, #3f344a 100%);
        opacity: 0.5;
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);
        cursor: pointer;
        &:hover{
            opacity: 0.8;
        }
    }

    a.allPlistBttn {
        font-size: 13px;
        display: block;
        position: absolute;
        margin-top: 135px;
        margin-left: 40px;
        text-decoration: none;
        color: #fff;
        opacity: 0.4;

        &:hover{
            opacity: 0.7;
        }
    }
    .active{
        .playlist__header {
            opacity: 1;
            border-radius: 7px;
            width: 90%;
            margin: 0px 5%;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
            border-bottom:none;

            .playAll{
                margin-right: 0;
            }
        }
    }
    .Playlist {
        width: 88%;
        margin: 0px 6%;
        height: auto;
        background: rgba(255, 255, 255, 0.03);
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);

        #playlist_songs {
            height: auto;
        }

        .publicPlaylis.customScrollbar > div {
            overflow: auto;
            will-change: transform;
            height: calc(100vh - 380px);
            width: 100% !important;
        }

        .songWrapper.customScrollbar > div > div {
            max-height: calc(100vh - 460px);
        }
    }

    .profile_private_msg, .profile_not_found {
        text-align: center;
        top: -20px;
        position: relative;
        color: #fff;
        font-size: 13px;
        opacity: 0.6;

        &.public_private{
            top: 50px;
        }
        svg {
            position: relative;
            top: -2px;
        }
    }

    .profile_not_found{
        top: 100px;
        svg {
            position: relative;
            top: -2px;
        }
    }

    .publicPlaylistError_Modal{
        text-align: center;

        .removeLikedPlaylist {
            padding: 12px 35px;
            margin: 7px;
            border-radius: 35px;
            border: none;
            background: #ad3d3d;
            outline: none;
        }
    }

    & > .loading {
        color: #fff;
        margin-top: 200px;
        opacity: 0.6;
    }
    
    .loading {
        text-align: center;
    }

    .publicPlaylist .songWrapper.customScrollbar {
      height: 300px;
      float: left;
      overflow: auto;
   }
}