#discover{
    width: 100%;
    float: left;


    .listViewBtn {
        color: #fff;
        opacity: 0.4;
        position: absolute;
        right: 320px;
        padding: 10px;
        margin-top: 16px;
        cursor: pointer;
        border: none;
        outline: none;
        &:hover {
            opacity: 1;
        }
        &.listViewBtn--active {
            color: #9180ff;
            opacity: 1;
        }
    }



    .discover__header{
        width: 100%;
        float: left;
        text-align: left;
        padding-left: 40px;
        

        .discover__header__wrap {
            width: 100%;
            float: left;
            color: #fff;
            height: 100%;
        }

        h1 {
            font-size: 18px;
            letter-spacing: 1px;
            font-weight: normal;
        }
        ul {
            margin: 0;
            padding: 0;
            margin-top: 5px;
            display: inline-block;
        }
        
        li {
            list-style-type: none;
            display: inline-block;
            margin: 0 5px;
            padding: 7px 15px;
            border-radius: 30px;
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 1px;
            text-transform: uppercase;
            cursor: pointer;
            opacity: 0.5;
            a{
                color: #fff;
                text-decoration: none;
            }
            
            &.active {
                opacity: 1;
            }
            &.active:after {
                content: "";
                width: 40px;
                height: 4px;
                background: #6459d9;
                display: block;
                top: 10px;
                position: relative;
            }
        }
    }

    #discover_content {
        width: 100%;
        float: left;
        margin-bottom: 70px;

        h2 {
            font-size: 12px;
            display: inline-block;
            color: #fff;
            letter-spacing: 0.6px;
            text-transform: uppercase;
            margin-bottom: 20px;
            span {
                color: #7666e0;
            }
            a {
                display: inline-block;
                font-size: 11px;
                margin-left: 10px;
                color: #fff;
                text-decoration: none;
                font-size: 10px;
                border-radius: 20px;
                border: 1px solid;
                padding: 3px 10px;
                position: absolute;
                margin-top: -3px;
                transition: all 0.2s linear;
                opacity: 0;
            }
            & a:hover {
                background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                border: 1px solid transparent;
            }
            
        }
        .discover_content__wrap {
            width: 92%;
            margin: 0 auto;
            padding: 20px 0 0px 0;
            
            .tracks_wrap:hover h2 a {
                opacity: 0.8;
            }
            
            .noTracks_found_error{
                text-align: center;
                margin-top: 100px;
                font-size: 14px;
                color: #7a7a8c;
            }
        }


        .discover_content__wrap-all{
        
            .tracks{
                height: auto;
            }
        
            .tracks__pagination {
                color: #fff;
               //  margin: 50px 0;

                ul {
                    list-style-type: none;
                    text-align: center;
                }
                li {
                    margin: 4px;
                    display: inline-block;
                    a{
                        display: inline-block;
                        padding: 18px 0;
                        width: 38px;
                        text-align: center;
                        border-radius: 50%;
                        font-size: 12px;
                        background: rgba(255, 255, 255, 0.051);
                        line-height: 2px;
                        cursor: pointer;
                        outline: none;
                        transition: all 0.2s linear;
                    }
                    
                    a:hover{
                        background: rgba(255, 255, 255, 0.1);
                    }
                    &.active a{
                        background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                    }
                    &.previous a, &.next a {
                        background: none;
                        padding: 10px 2px;
                    }
                    &.previous a:hover, &.next a:hover {
                        background: rgba(255, 255, 255, 0.1);
                    }
                }
                
            }


            .tracks_header{


                .tracks_header__inner {
                    color: #7a7c8a;

                    .tracks_header__right{
                        float: right;
                        padding-top: 5px;
                    }

                    .genChoices {

                        button{
                            background: #3c3c63;
                        }
        
                        ul {
                            right: 10px;
                            left: auto;
                            background: #292948;
                            a{
                                cursor: pointer;
                            }
                        }
                    }
            
                    button {
                        display: inline-block;
                        padding: 5px 20px;
                        border-radius: 25px;
                        border: none;
                        background: #303549;
                        color: #fff;
                        margin: 0 10px;
                        font-weight: bold;
                        font-size: 14px;
                        text-transform: capitalize;
                        min-width: 95px;
                        outline: none;
                    }

                    .playAll {
                        display: inline-block;
                        button {
                            background: transparent;
                            padding: 5px 10px;
                            min-width: auto;
                        }
                    }

                    .showGenreChoices button,  .showSortChoices button{
                        border-radius: 10px 10px 0 0;
                    }
                    ul {
                        position: absolute;
                        width: 300px;
                        margin: 0;
                        padding: 0;
                        background: #2c3145;
                        z-index: 5;
                        border-radius: 5px 0px 5px 5px;
                        left: 10px;
                        padding: 10px;
                        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.5);
                        display: none;
                    }
                    li {
                        display: inline-block;
                        width: 50%;
                        padding: 8px 15px;
                        font-size: 13px;
                        opacity: 0.6;
                        border-radius: 3px;
                        color: #fff;
                        cursor: pointer;
                        a{
                           text-decoration: none;
                           color: #fff;
                        }
                        span {
                            margin-left: 6px;
                            padding: 2px 4px;
                            background: rgba(177, 178, 188, 0.22);
                            font-size: 11px;
                            border-radius: 3px;
                            opacity: 0.9;
                        }
                    }
                    li:hover {
                        opacity: 1;
                        background: rgba(0, 0, 0, 0.2);
                    }
            
                    & > div {
                        display: inline-block;
                        position: relative;
                    }
    
                    .showGenreChoices ul, .showSortChoices ul {
                        display: block;
                    }
                    .showSortChoices ul {
                        width: 153px;
                        border-radius: 0px 0px 5px 5px;
                        a{
                            width: 100%;
                        }
                    }
                    .tracks_genre_count {
                        font-size: 14px;
                        margin-left: 10px;
                        display: inline-block;
                        position: relative;
                    }
                }

                &.artists_header{
                    .tracks_header__inner > div > div {
                        display: inline-block;
                        position: relative;
                    }
                    .tracks_header__right {
                        margin-top: -5px;

                        form#artists__form {
                            width: 170px;
                            display: inline-block;
                        }
                    }
                    
                    
                    input{
                        border-radius: 50px;
                        border: 1px solid #221f36;
                        background: rgba(0, 0, 0, 0.3);
                        padding: 8px 15px;
                        width: 100%;
                        outline: none;
                    }
            
                }


            }
            
            &.discover_artists{
                .tracks_wrap p {
                    color: #999;
                    margin: 50px auto;
                    text-align: center;
                    font-size: 14px;
                }
                .tracks_header__title {
                    display: inline-block;
                }
            }        
        }

        //POPULAR TRACKS
        .discover_popular {
            .tracks_header{
                margin-bottom: 0px;
            }
            .tracks_header .tracks_header__left {
                width: 50%;
                float: left;
                padding-bottom: 10px;

                h2 {
                    margin-bottom: 10px;
                    position: relative;
                    display: block;
                }

                button {
                    width: auto;
                    padding: 4px 13px;
                    border: none;
                    cursor: pointer;
                    opacity: 0.8;
                    margin-top: -5px;
                    background: #3c3c63;
                }

                ul {
                     width: 130px;
                     right: auto;
                     left: 110px;
                     padding: 0;
                     display: block;
                     margin-top: -5px;
                     background: #292948;
                     border-radius: 5px;
                     padding: 10px;

                    li {
                        list-style-type: none;
                        font-size: 14px;
                        padding: 6px 0;
                        text-align: center;
                        cursor: pointer;
                        display: inline-block;
                        width: 50%;
                    }
                    
                    li:hover {
                        color: #fff;
                        background: rgba(0, 0, 0, 0.3);
                    }
                }

            }

            .tracks_header__right {
                width: 50%;
                float: left;
                div{
                    float: right;
                }

                .englishOnlyButton {
                    margin-top: 4px;
                    cursor: pointer;
                    span {
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 1px;
                        display: inline-block;
                        padding: 2px 4px;
                    }
                    &.englishOnlyButton--on {
                        color: #8f7eff;
                    }
                }
                
  
            }
            

        }

    }



    
    
}