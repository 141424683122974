.mobile{
    float: left;
    width: 100%;
    height: 100vh;

    //DISABLE TAP HIGHLIGHT COLOR ON MOBILE
        .player__controls_inner > div {
            -webkit-tap-highlight-color: transparent;
        }
        button{
            -webkit-tap-highlight-color: transparent;
        }

        button:active,
        button:focus,
        button.active {
            background-image: none;
            outline: 0;
            -webkit-box-shadow: none;
                    box-shadow: none;
        }

    .siteBG{
        display: none;
    }

    main {
        width: 100%;
        margin-right: auto;
        float: left;
        margin-top: 85px;
    }
    
    #now_playing { display: none; }
    #discover{ display: none }

    .logo {
        max-width: 50px;
        padding-left: 20px;
        margin-top: 20px;
        margin-bottom: 0px;

        img {
            width: 100%;
        }
    }

    //Header
    header {
        width: 100%;
        padding: 10px;

        .upgrade_button {
            top: 3px;
            left: auto;
            z-index: 14;
            font-size: 11px;
            right: 130px;
            width: auto;
        }

        .install-button {
            margin-right: 92px;
            width: 30px;
            border-radius: 50%;
            padding: 6px 0px;
            font-size: 13px;

            &.install-button--active-user {
                margin-right: 130px;
            }
        }

        .mobile_help {
            position: fixed;
            left: 60px;
            top: 22px;
            width: 25px;
            text-align: center;
            border: none;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            padding: 3px 0;
            
            a {
                padding: 2px;
                letter-spacing: normal;
                margin: 0;
                display: block;
                position: relative;
                z-index: 4;
            }

            &.notChecked:after{
                content: "";
                border: 3px solid rgba(255, 255, 255, 0.3);
                border-radius: 30px;
                height: 30px;
                width: 30px;
                position: absolute;
                margin-top: -25px;
                margin-left: -15px;
                animation: blinky 1.5s ease-out;
                animation-iteration-count: infinite;
                opacity: 0;
            }
        }

        #searchBar {
            width: 40px;
            float: right;
            min-width: initial;
            display: none;
        }
        .menu {
            right: 0px;

            button.searchButton {
                border-radius: 50%;
                padding: 5px 6px;
                border: none;
                background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                margin-right: 3px;
                top: 0px;
                position: relative;
            }
            li.menu-item--friends {
                margin-right: 20px;
                width: 40px;
            }

            li.menu-item--library a {
                margin-right: 2px;
                margin-left: 7px;
                background: rgba(255, 255, 255, 0.2);
                padding: 6px 7px;
                svg {
                    position: relative;
                    top: -2px;
                }
            }
            li{
                .menu__account{
                    margin-left: 10px;
                }
            }

            li.menu-item--signup{
                display: none;
                margin-left: 0;
                position: absolute;
                width: 165px;
                right: 60px;
                top: 3px;
            }

        }

    }
    

    #createPlaylistModal {
        .select_wrapper .rc-select-selection {
            max-width: 70%;
        }
    }


    //PlaylistGROUP
    .playlists_wrapper {
        width: 100%;
        position: fixed;
        float: left;
        height: auto;
        background: none;
        margin-top: -20px;
        background: #191c2c;
        z-index: 7;
        .siteBG {
            display: block;
            height: 112px;
            top: 0;
            .siteBG_fade{
                height: 152px; 
            }
        }

        #playlists{
            height: auto;
            .playlists__menu {
                padding: 0px;
                text-align: center;
                margin-top: 20px;
                width: 100%;
                float: left;
                button {
                    text-transform: none;
                    font-size: 12px;
                    padding: 12px 5px;
                    margin-bottom: 10px;
                    letter-spacing: 0;
                    width: 25%;
                    background: rgba(0, 0, 0, 0.3);
                    border: none;
                    color: #bebbd5;
                    font-size: 11px;
                    letter-spacing: 0.4px;
                    font-weight: bold;
                    outline: none;
                    opacity: 0.5;
                    &.mobile-tab--active{
                        opacity: 1;
                        background: linear-gradient(to bottom, rgba(72, 53, 232, 0.21) 0%, #362f84 100%);
                    }

                    &.mobile-tab--channels{
                        font-size: 11px;
                    }
                }
            }
            #mobile-tab-saved{
                position: absolute;
                top: 70px;
                width: 100%;
                height: calc(100vh - 230px);
            }
            
            .no_saved {
                margin-top: 100px;
                font-size: 14px;
                text-align: center;
                padding-left: 0;
            }
        }
    }

    //PAGE
    #page {
        top: 70px;
        width: 100%;
        left: 0px;
        height: calc(100vh - 178px);
        overflow: scroll;
    }

    
    #about .about__block{
        .about__block__content {
            width: 100%;
            margin: 0;
        }
        img {
            max-width: 100%;
        }
    } 

    //LIBRARY
    #SearchLibrary{
        .SearchLibrary__wrap {
            width: 92%;
            left: 4%;
            height: calc(100vh - 150px);
        }
        .searchbar{
            padding-left: 20px;
        }
        .searchresults__right {
            width: 60px;
            padding-top: 0px;
            overflow: hidden;
            opacity: 0.4;
            box-shadow: 0 0 50px rgba(0, 0, 0, 0.8) inset;
            position: relative;

            .searchresults__right__content {
                min-width: 220px;
                width: 100%;
                button {
                    display: none;
                }
            }
        }
        .searchresults__left {
            width: 60px;
            opacity: 0.4;
            position: relative;
        }

        .activeMobileLibTab{
            width: calc(100% - 60px);
            opacity: 1;
        }

        .searchresults__left, .searchresults__right{
            transition: all 0.2s linear;
        }

        .libMobileOverlay {
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
        }

        .libSong{
            span.songMsg {
                position: absolute;
                width: 92%;
                left: 10px;
                top: 5px;
                padding: 7px 10px;
                background: rgba(103, 79, 224, 0.8);
                color: #fff;
                border-radius: 3px;
            }
        }

        .libSong.swipeAnim, .libSong.swipeAnimLeft{
            background: transparent!important;
        }
        .libSong.swipeAnim:after, .libSong.swipeAnimLeft:after{
            content:'';
            top:0;
            transform:translateX(100%);
            width:100%;
            height:38px;
            position: absolute;
            z-index:1;
            animation: slide 0.5s;
            background: linear-gradient(to right, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.06) 50%, rgba(128, 186, 232, 0.04) 99%, rgba(125, 185, 232, 0.03) 100%);            
        }
        &.swipeAnimLeft:after{
            transform:translateX(-100%);
            animation: slideLeft 0.5s;
        }
        
    }


    //PLAYLISTS
    #now_playing{
        .Playlist{

            .playlists__Button {
                position: fixed;
                z-index: 99;
                margin-top: 57px;
                left: 0px;
                padding-left: 2px;

                button {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 32px 32px 0 0;
                    border-color: #5353b0 transparent transparent transparent;
                    padding: 0;
                    border-radius: 0;
                }
                span {
                    background: none;
                    padding: 0;
                    position: absolute;
                    top: 0px;
                    margin-left: 4px;
                    font-size: 17px;
                }
            }

            .playlist__header{
                padding: 10px;
                position: fixed;
                z-index: 2;
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);

                .playlist__title{
                    max-width: 140px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-size: 12px;
                    position: relative;
                    top: 7px;
                    margin-right: 3px;
                }
                
                .playlist__title > svg {
                    display: none;
                }

                .playAll button{
                    font-size: 12px;
                }

                .playlist__header__left{
                    width: calc(100% - 100px);

                    .playAll {
                        margin-right: 0px;
                    }
                }
                
                .playlist__header__right {
                    width: 100px;
                    float: right;
                    .playlist__add {
                        display: none;
                    }
                }
                .playlist__category {
                    display: none;
                }
                .playlist__category {
                    display: none;
                }
            }

            .playlist_songs {
                margin-top: 67px;
                max-height: calc(100vh - 280px);
                overflow: hidden;
            }

            .playlistSelector {
                position: fixed;
                width: 85%;
                margin: 0;
                padding: 0;
                list-style-type: none;
                background: #222536;
                z-index: 99999;
                margin-top: 55px;
                color: #fff;
                font-size: 13px;
                padding: 20px;
                box-shadow: 0 17px 30px rgba(0, 0, 0, 0.1);
                margin-left: 10px;
                border-radius: 0 0 5px 5px;
            
                li {
                    display: block;
                    padding: 10px 0px;
                    opacity: 0.5;
                    span {
                        padding: 1px 7px;
                        font-size: 11px;
                        background: rgba(255, 255, 255, 0.14);
                        border-radius: 3px;
                        margin-left:  7px;
                    }
                }
                li.active-playlist-item {
                    opacity: 1;
                    font-weight: bold;
                }
            }
            .playlistSelectorButton {
                padding: 5px;
                border: none;
                opacity: 0.6;
            }
            .playlistActionModals .removePlaylistAction button {
                width: 100%;
                margin-left: 0;
            }
            .playlist_songs .songWrapper {
               height: calc(100vh - 280px);
            }
        }
    }

    
    //PLAYER
    #player {
        width: 100%;
        z-index: 999;

        .player__video__wrap {
            left: 0;
            bottom: 110px;
            height: auto;
            min-height: auto;
            position: fixed;
            z-index: 999999999999;
            width: 94%;
            margin: 0 auto;
            left: 0;
            top: auto;
            right: 0;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.7);

            &.player__video-hide {
                display: none;
            }
            .player__mode_switch{
                display: none;
            }
        }
        .player__nowplaying {
            display: none;
        }
        .player__duration {
            font-size: 12px;
            right: 20px;
            top: 15px;
            opacity: 0.35;
        }
        .player__controls {
            padding: 20px 0;
        }
        .player_seek {
            bottom: 107px;
        }
        .player__controls_inner {
            width: 400px;
            margin: 0 auto;
            text-align: center;
            color: #3f3576;
            position: relative;
            transform: scale(0.85);
            //left: 0;
            left: -8px;
            margin-top: 10px;

            .player__shuffle{
                display: none;
            }
            .player__video__button-active {
                color: #6e64d9;
            }
        }
        .player__nowplaying__songInfo__mobile {
            position: absolute;
            left: 15px;
            top: 15px;
            opacity: 0.55;
            color: #fff;
            font-size: 10px;
            width: 220px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            .player__songInfo__count {
                margin-right: 5px;
            }
            .player__songInfo__title {
                margin-right: 5px;

                dl {
                    display: inline-block;
                    margin: 3px;
                    background: rgba(255, 255, 255, 0.3);
                    border-radius: 3px;
                    padding: 0 2px;
                }

                &:after {
                    content: " -";
                }
            }
        }


        @media screen and (max-width: 340px) and (min-width: 300px){
            .player__controls_inner {
                margin-left: -36px;
            }
            .player__controls__small > div {
                margin-right: 4px;
            }
            
            .player__controls_inner > div {
                margin-right: 5px;
            }
        }

    }
    

    //CONTENT

        //QUEUE
        #RightSidebar {
            display: none;
            top: 0px;
            position: relative;
            width: 100%;
            height: auto;
            float: left;

            #playlist_mini {
                top: 5px;
                height: calc(100vh - 250px);
                width: 100%;

                .playlistQueue{
                    width: 100%;

                  //   .playlist_songs-mini .songWrapper > div {
                  //       padding-bottom: 30px;
                  //   }
                }

                #queueMenu {
                    color: #fff;
                    margin-bottom: 10px;
                    position: relative;
                    padding: 10px;
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
                }

                .song{
                    width: 100%!important;
                    .song__title{
                        overflow: initial;
                    }
                    .songActionsWrapper{
                        width: 65px;
                    }
                    &.swipeAnim:after, .swipeAnimLeft:after{
                        height:38px;
                    }
                } 
            }
        }

        //SONG
        .song{
            opacity: 1!important;
            padding: 10px 15px;

            .song_inner {
                width: 95%;
            }

            .song__title {
                max-width: 180px;
                white-space: nowrap;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .songPresser{
               width: 100%;
               float: left;
            }
            .song__duration{
                padding: 12px 5px;
            }
            .songMsg{
                position: absolute;
                background: rgba(103, 79, 224, 0.8);
                padding: 15px;
                width: 92%;
                color: #fff;
                text-align: center;
                font-size: 13px;
                z-index: 9;
                border-radius: 3px;
            }
            .mobileSelectedSongIcon {
                position: absolute;
                margin-left: -20px;
            }
            &.swipeAnim, &.swipeAnimLeft{
                background: transparent!important;
            }
            &.swipeAnim:after, &.swipeAnimLeft:after{
                content:'';
                top:0;
                transform:translateX(100%);
                width:100%;
                height:65px;
                position: absolute;
                z-index:1;
                animation: slide 0.5s;
                background: linear-gradient(to right, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.06) 50%, rgba(128, 186, 232, 0.04) 99%, rgba(125, 185, 232, 0.03) 100%);            
            }
            &.swipeAnimLeft:after{
                transform:translateX(-100%);
                animation: slideLeft 0.5s;
            }

            &.pressAnim{
                background-color: #5549b1;
                transition: .5s ease-in-out;
            }

            &.song-drag{
                width: 100%;
            }
        } 


        //Mobile Song Actions
        .mobile_actions {
            width: 100%;
            position: fixed;
            left: 0;
            z-index: 9999;
            bottom: 110px;
            background: linear-gradient(135deg, #664480 0%, #3a4c8d 100%);
            color: #fff;

            .reorder_btn {
                transform: rotate(-90deg);
            }
            p {
                margin: 0;
                font-size: 13px;
                padding: 10px;
            }

            & > div {
                float: left;
                padding-left: 10px;
            }
            & > div:nth-child(2) {
                float: right;

            }

            .mobile_actions__all button {
                border: none;
                padding: 8px;
                margin-right: 10px;
            }

            &.no_song_selected .reorder_cancel{
                position: absolute;
                margin-top: -33px;
                right: 10px;
            }

            .mobile_actions__cancel {
                position: absolute;
                right: 0px;
            }
            
            .mobile_actions__reorder {
                width: 100%;
                position: absolute;
                background: linear-gradient(135deg, #664480 0%, #3a4c8d 100%);
                text-align: right;

                &:before {
                    content: "Reordering";
                    font-size: 12px;
                    opacity: 0.6;
                    position: absolute;
                    left: 0;
                    padding: 7px;
                }
                button.mobile_actions__done {
                    margin-right: 45px;
                }
            }
            
        }

        .Modal.mobileAddSongModal {
            .Modal__content {
                padding: 0;
            }
            .Modal__inner {
                margin-top: 110px;
            }
            .Modal__inner .Modal__header__title {
            color: #e3ecff;
            }

            .LibSongScroll_wrap a {
                display: block;
                width: 100%;
                padding: 8px 20px;
                color: #848ca5;

                svg {
                    position: absolute;
                    margin-left: -18px;
                }

                &:nth-child(even) {
                    background: rgba(0, 0, 0, 0.2);
                }
            }
            
            .mobile_addSong__buttons {
                button {
                    border: none;
                    width: 50%;
                    padding: 15px;
                }
                button.libConform {
                    background: #6b4cbc;
                    border-radius: 5px 0 0 5px;
                }
                button.libCancel {
                    background: rgba(255, 255, 255, 0.13);
                    border-radius: 0 5px 5px 0;
                }

            }
        }

        //TRACKS
        #addSong .tracks {
            padding-left: 0;
        }
    
        .tracks .track {
            width: 89%!important;
            min-width: 250px;
            margin: 15px!important;
            
            .track_img_wrap {
                margin-top: 0px;
                text-align: left;
                margin-left: 0px;

            }
            .track_img_wrap img {
                width: 100%;
                min-width: 250px;
                margin-top: -52px;
            }
            .track_content_wrap {
                width: 100%;
                margin-left: 0px;
            }

            .songMsg{
                position: absolute;
                background: rgba(103, 79, 224, 0.8);
                padding: 15px;
                width: 100%;
                height: 110px;
                box-sizing: border-box;
                padding-top: 50px;
                color: #fff;
                text-align: center;
                font-size: 13px;
                z-index: 9;
                border-radius: 3px;
            }

            &:hover {
                transform: none;
                .track_img_wrap{
                    transform: none;
                }
            }
            .track__addToPlaylist {
                width: 100%;
                margin: 0;
                height: 175px;
                
                ul {
                    height: auto;
                    padding-bottom: 30px;
                }

            }

            &.swipeAnim, &.swipeAnimLeft{
                background: transparent!important;
            }
            &.swipeAnim:after, &.swipeAnimLeft:after{
                content:'';
                top:0;
                transform:translateX(100%);
                width:90%;
                height:175px;
                position: absolute;
                z-index:1;
                animation: slide 0.5s;
                background: linear-gradient(to right, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.06) 50%, rgba(128, 186, 232, 0.04) 99%, rgba(125, 185, 232, 0.03) 100%);            
            }
            &.swipeAnimLeft:after{
                transform:translateX(-100%);
                animation: slideLeft 0.5s;
            }

            &.pressAnim .track_content_wrap{
                background-color: #5549b1;
                transition: .5s ease-in-out;
            }
 
        }

        .tracks--listView {
            padding-top: 10px!important;

            .track {
                width: 100%!important;
                margin: 0!important;
                overflow: hidden;
    
                &.activeSong.track--Playlist_active {
                    overflow: visible;
                }
    
                .track_content_wrap {
                    width: calc(100% - 80px);
                    margin-left: 5px;
                    h3 {
                        width: 160px;
                    }
                }
                .track_img_wrap{
                    height: 60px;
                    img {
                        margin-top: 10px;
                        min-width: initial;
                        opacity: 1;
                    }
                }
                .track__actions {
                    right: 40px;
                    top: 15px;
                    .track__play_button {
                        display: none;
                    }
                }
    
                .track__addToPlaylist {
                    right: 0px;
                    margin-left: 0px;
                }
        }

        }
        
        #discover a.listViewBtn {
            position: fixed;
            right: 90px;
            margin-top: 0;
            top: 100px;
        }
        #discover.discover--popular{
            a.listViewBtn {
                right: 140px;
            }
        }

        #discover {
            .discover__header{
                padding-left: 0px;
            }
            .discover_latest_header .discover_latest_header__left {
                h1{
                    font-size: 13px;
                    margin: 13px 0;
                    padding-left: 8px;
                }
                .playAll{
                    display: none;
                }
            }
            

            #discover_mobile_header {
                position: fixed;
                margin-left: 20px;
                color: #fff;
                margin-top: 10px;
                z-index: 99;

                .discover_mobile_header__trigger {
                    width: 120px;
                    opacity: 0.6;
                }

                .discover_mobile_header__menu {
                    background: #252740;
                    border-radius: 3px;

                    ul {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                        margin-top: 10px;

                        li {
                            padding: 10px 15px;
                            font-size: 13px;

                            a {
                                color: #fff;
                                opacity: 0.5;
                                text-decoration: none;
                            }

                            &.activeDiscover a {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            &.discover--latest #discover_content{
               .tracks {
                  padding-top: 40px!important;
              }
            }
            #discover_content{
                .tracks_wrap {
                    h2 a{
                        opacity: 1;
                    }
                }
                .discover_content__wrap{
                    width: 90%;
                    padding-top: 0px;
                }
                .discover_latest_header {
                    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);
                }
                //Tracks
                .tracks {
                  //   padding-top: 40px!important;
                    .track .track__addToPlaylist{
                        margin-left: 10px;
                    }
                }

                .trackScroll_wrap.trackScroll_wrap--pagination > div {
                    height: 100%;
                    width: 100%!important;
                    margin-top: 50px;
                }
                
                .tracks__pagination ul {
                    padding: 0;
                    padding-bottom: 40px;
                    li a{
                        padding: 17px 0;
                        width: 36px;
                    }
                }

                //Disocver Header Sort
                .discover_content__wrap-all {

                    .tracks_header {
                        top: 105px;
                        left: 0;
                        width: 100%;
                        background: #191c2c;
                        z-index: 9;
                        position: fixed;
                        padding-bottom: 5px;
                        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);

                        .tracks_header__inner {
                            width: 90%;
                            margin: 0 auto;
                        }

                        .playAll{
                            display: none;
                        }

                        .tracks_header__inner {
                            font-size: 14px;
                            text-align: left;
    
                            button{
                                font-size:12px
                            }
                            .genChoices{
                                button{
                                    padding: 5px 10px;
                                }
                                ul {
                                    width: 92%;
                                    box-sizing: border-box;
                                    left: 0;
                                    margin: 0 4%;
                                }
                            }
    
                            
    
                            .tracks_header__left {
                                h2 {
                                    margin-top: 13px;
                                    margin-bottom: 10px;
                                    padding-left: 22px;
                                    text-transform: none;
                                }
                                a {
                                    padding: 4px;
                                    font-size: 10px;
                                    width: 52px;
                                    margin-top: -2px;
                                }
                            }
    
                            .tracks_header__right {
                                float: right;
                                .genChoices {
                                   position: static;
                                }
                            }
    
                            & > div{
                                position: static;
                            }
                            .showSortChoices ul {
                                width: 94%;
                                border-radius: 5px;
                            }
        
                            button{
                                padding: 5px 0px;
                                margin: 0px 5px;
                                min-width: 0;
                            }
                            .tracks_genre_count {
                                font-size: 13px;
                                float: right;
                                padding-top: 12px;
                                color: #7f7e9c;
                            }
        
                        }
                    }

                    
                }



                //Popular Header
                .discover_popular .tracks_header__right{
                    .englishOnlyButton {
                        span {
                            font-size: 12px;
                        }
                    }
                } 


                //HOME
                .slick-initialized .slick-slide {
                    text-align: center;
                }
                
                
                .tracks .slick-slide {
                    text-align: center;
                    
                    .track_img_wrap, .track_content_wrap{
                        margin-top: 0px;
                        margin-left: 0px;
                        text-align: left;
                    }
                    .track_img_wrap img {
                        margin-top: -60px;
                    }
                }
                .slick-next {
                    right: -15px;
                    top: 40%;
                }
                
                .slick-prev {
                    left: -15px;
                    top: 40%;
                }
    
                //Artists
                .discover_artists {
                    width: 100%;

                    .tracks_header.artists_header {
                        width: 100%;

                        .tracks_header__inner {
                            .tracks_genre_count {
                                font-size: 12px;
                            }
                            .tracks_header__left{
                                width: auto;
                                float: none;
    
                                h2{
                                    margin-left: 20px;
                                }
    
                                .tracks_genre_count{
                                    margin-top: 2px;
                                }
                            }
                            .tracks_header__right {
                                width: auto;

                                #artists__form{
                                    width:100px;
                                }
                            }
                        }

                    }


                    
                    .artists > a:nth-child(odd) .artist {
                        background: #202438;
                    }
                    .artists .artist {
                        width: 100%;
                        height: auto;
                        padding: 12px 20px;
                        margin: 0px;
                        vertical-align: bottom;
    
                        .artists > a {
                            display: block;
                            width: 100%;
                            float: left;
                        }

                    }
                    .tracks_wrap {
                        width: 100%;
                    }
                }

            }


        }
        //ARTIST
        #artist{
            .artist_tracks .artist_content .artist_bio {
                text-align: center;
            }
            
            .artist_header{
                .artist_left {
                    width: 100%;
                    text-align: center;
                    .artist_left__img {
                        float: none;
                    }
                }
                .artist_right {
                    width: 100%;
                    text-align: center;
                }
            } 
            .artist_tracks .artist_content{
                h2, .artist_bio{
                    display: none;
                }
            } 

            .artist_back_button {
                left: auto;
                top: 110px;
                right: 15px;
            }
        }
                

    //ADD SONG MODAL
    #addSong {
        width: 100%;
        left: 0;

        .searchbar {
            padding: 0 10px;
            .importSongInfoCheck.custom_checkbox {
                text-align: center;
                margin-left: 0px;
            }
        }
        .addSong__header {
            padding: 10px;
            ul li {
                padding: 10px;
                margin: 0 5px;
                text-transform: none;
                letter-spacing: 0px;
                font-size: 13px;
            }
            svg {
                right: 10px;
                top: 18px;
            }
        }

        #SearchArtists{
            .searchresults__left {
                width: 100%;
            }
            .searchresults__middle {
                position: absolute;
                width: 100%;
                display: none;
                background: #1b2031;
    
                button {
                    position: absolute;
                    top: 20px;
                    border: none;
                    left: 15px;
                }

                &.showSongsTab {
                    display: block;
                }
            }
            .importSongInfoCheck.custom_checkbox {
                display: none;
            }
            .searchresults__left__head {
                padding: 8px;
            }
            .customScrollbar.addSongScroll_wrap > div {
                height: calc(100vh - 315px);
            }
            .overlay_msg_inner{ 
                height: calc(100vh - 400px);
                button{
                    position: relative;
                }
                p{
                    margin: 9px !important;
                }

            }

        } 

    }

    //USER
    #User {
        width: 100%;
        padding-top: 10px;
        padding-left: 0;
        padding-right: 0;
        min-height: 290px;
        min-height: 290px;
        height: 100%;
        overflow-x: scroll;

        .UserInner {
            width: 80%;
            margin: 0 auto;
        }
        button {
            margin-top: 0px;
        }
        .customRadio .control {
            margin-right: 15px;
        }
        .custom_checkbox span {
            width: auto;
            margin-right: 15px;
        }        
        .submit__wrap {
            width: 100%;
            left: 0;
            margin-left: 0;
        }
        #UserSettings {
            margin-top: 40px;
        }

        & > .custom-scrollbars {
            width: 100%!important;
        }

        .customScrollbar.userScroll_wrap > div {
            min-height: 200px;
        }
        
    }

    //PUBLIC PROFILE
    #publicProfile {
        padding-bottom: 150px;
        .playlist__header {
            padding: 12px;
            font-size: 12px;
            position: relative;

            .playlist__header__left {
                width: 100%;
            }

            .playlist__header__right {
                width: auto;
                position: absolute;
                right: 10px;
            }
        }
        a.allPlistBttn {
            margin-top: 297px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            width: 240px;
            opacity: 0.7;
            background: rgba(255, 255, 255, 0.2);
            padding: 4px 0;
            border-radius: 3px;
        }
        #profile_box{
            margin-bottom: 10px;
            padding: 10px;

            .profile_box__inner {
                height: auto;
                padding: 20px;
            }
            .profile_box__right {
                width: 100%;
                .profile_stats > div{
                    width: 90px;
                }
            }
            .profile_box__left {
                width: 100%;

                .profile_content {
                    text-align: center;
                    width: 100%;
                }

                .profile_image {
                    margin: 0 auto;
                    float: none;
                }
            }

        }
        
    }


    //FRIENDS
    #friends {
        .the_firends__wrapper {
            .the_firends__wrapper__inner {
                .friend {
                    width: 50%;

                    .friend_actions > span {
                        opacity: 1;
                    }
                }
            }
        }
    }
    
    //MOBILE Tab ACTIVE
    &.mobile-tab-queue #RightSidebar{ display: block} 
    &.mobile-tab-playlists #now_playing{ display: block} 
    &.mobile-tab-saved .playlists_wrapper #mobile-tab-saved{ display: block} 
    &.mobile-tab-discover #discover{ display: block} 

    //Disable Tooltip
    .__react_component_tooltip {
        display: none;
    }


    .welcomeTour {
        width: 94%;
        right: 0;
        left: 3%;
        padding: 15px;

        .welcomeTour__inner h4 {
            margin-bottom: 10px;
        }
        .welcomeTour__inner p {
            max-width: 100%;
        }
        img{
            max-width: 100%;
        }
    }
    
    .proModal_wrapper .proModal__heading {
        padding: 10px 20px;
        background:url('../assets/welcomebg.png') no-repeat 10px -60px, linear-gradient(to left, rgba(103,68,157,1) 0%, rgba(103,68,157,1) 8%, rgba(59,37,102,1) 35%, rgba(65,54,145,1) 77%, rgba(92,86,200,1) 100%);
        border-radius: 5px 5px 0 0;
        p{
            color: #d0d4de;
        }
    }

    .compare_pro {
        top: 35px;
        min-height: 280px;
        .compare_pro__inner{
            text-align: left;
            font-size: 12px;
        }

        .pricing_table__body{
            p:before {
                content: '✓';
                margin-left: -18px;
                color: #6effe1;
                position: absolute;
            }

            span {
                font-size: 11px;
                display: block;
                opacity: 0.5;
            }

            button {
                background: linear-gradient(135deg, #704293 0%, #5160d0 100%);
                border-radius: 30px;
                border: none;
                padding: 10px 30px;
                margin-top: 15px;
                width: 108%;
                box-sizing: border-box;
                outline:none;
            }
        }
        

    }

   //  .songWrapper.customScrollbar > div {
   //      height: calc(100vh - 280px)!important;
   //      color: #474a5d;
   //      overflow: auto;
   //      will-change: transform;
   //  }
    
    //LANDSCAPE MODE
    @media screen and (min-width: 620px){
        // main {
        //     margin-top: 35px;
        // }
        header .menu {
            width: 130px;
        }
        #player {
            height: 70px;
            .player__controls {
                margin-top: -30px;
            }
            .player_seek {
                bottom: 78px;
            }
            .player__video__wrap {
                width: 250px;
                left: 20px;
                margin: 0;
                bottom: 80px;
                .player__mode_switch{
                    opacity: 1;
                    display: block;
                }
                &.player__video__wrap-full {
                    width: 95%;
                }
            }
        }
        // .playlists_wrapper #playlists .playlists__menu {
        //     margin-top: 0;
        //     position: fixed;
        //     z-index: 9;
        //     top: 18px;
        // }
        .playlists_wrapper .siteBG {
            height: 90px;
        }

        // #RightSidebar #playlist_mini .playlistQueue {
        //     height: calc(100vh - 190px);
        // }

        #now_playing .Playlist {
            .mobile_actions {
                bottom: 70px;
            }
            .playlist__header {
                padding: 0 10px;
                margin-bottom: 0px;
                z-index: 9;
            }
            .playlistSelector{
                margin-top: 35px;
            }
            .playlist_songs {
                margin-top: 45px;
                max-height: calc(100vh - 180px);
            }
        }

        

        
        
        .song {
            padding: 7px 15px;
        }
        #discover{
            #discover_mobile_header {
                left: -200px;
                right: 0;
                margin: 0 auto;
                width: 120px;
                margin-top: 10px;
            }

            #discover_content .discover_content__wrap-all {
                width: 98%;

                .tracks.tracks--listView{
                    margin-top: 0px;
                    .track {
                        width: 80%!important;
                    }
                } 

                .tracks_header {
                    //top: 45px;
                    background: transparent;
                    width: 100%;
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);

                    .tracks_header__inner {
                        width: 320px !important;
                        margin: 0 auto;
                        
                    }

                    &.artists_header .tracks_header__inner .tracks_header__right {
                        margin-top: 0px;
                    }

                    .tracks_header__inner .genChoices ul {
                        position: fixed;
                        a {
                            width: 33%;
                            padding: 6px 15px;
                        }
                    }

                }
                .trackScroll_wrap > div {
                    height: 100%;
                    width: 100%!important;
                }
                .tracks {
                    margin-top: 30px;
                    text-align: center;
                    .track {
                        width: 250px!important;
                        text-align: left;
                    }
                }
                
                &.discover_popular{
                    .tracks {
                        margin-top: 0px;
                    }
                }

                .tracks__pagination {
                    margin: 0px 0;
                }
            }


        }

        .tracks .track {
            width: 250px!important;
        }



        #artist {
            .trackScroll_wrap.trackScroll_wrap--pagination > div {
                height: 100%;
                padding-bottom: 60px;
                padding-top: 30px;
            }
        }
        
        
        #addSong {
            height: 100vh;

            .tracks .track {
                width: 250px!important;
            }

            .searchbar {
                margin: 10px 0;

                input[type="text"] {
                    padding: 12px 30px;
                }

                .importSongInfoCheck.custom_checkbox {
                    display: none;
                }

            }

            .searchresults {
                height: 100%;

                .searchresults__left__head, .searchresults__middle__head, .searchresults__right__head{
                    padding: 10px;
                }
            }

            .customScrollbar.addSongScroll_wrap > div {
                height: 100%;
            }
            
        }


        #SearchLibrary{
            .SearchLibrary__wrap {
                height: calc(100vh - 30px);
            }
            .searchbar {
                margin: 10px 0;
            }
            .searchresults__right {
                width: 220px;
                opacity: 1;
                overflow: auto;
                height: calc(100vh - 110px);
            }
            .searchresults__left {
                width: calc(100% - 220px);
            }

        }

        .compare_pro {
            top: 15px;
        }
    }

    #help{
        video{
            margin-left: -18%;
            max-width: 136%;
        }

        .mobile_help_plus {
            border-radius: 50%;
            padding: 1px 6px;
            border: none;
            background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
            margin-right: 3px;
            top: 0px;
            position: relative;
            color: #fff;
        }
    }

    .trackScroll_wrap > div {
        height: calc(100vh - 260px);
    }


    #footer{
        bottom: -155px;

        .footer_tourBtn{
            display: none;
        }

        &.footerActive {
            bottom: 0;
        }
    }



    //CHANNELS

    &.page-channel {

        // .footerWrapper {
        //     display: none;
        // }

        #chatbox {
            margin-top: 50px;
    
            .channels__chat__msgs > div {
                height: calc(100vh - 360px);
            }

            .channels__chat__form {
                bottom: 180px;
            }
        }

        .channels__right {
            width: 100%;
            padding: 0;
            z-index: auto;
            top: 70px;

            h4 {
                a, button{
                    color: #fff;
                    border: none;
                    outline: none;
                }
                svg {
                    background: rgba(255, 255, 255, 0.1);
                    border-radius: 50%;
                    padding: 3px;
                }
            }
            .mobileChannelSelector {
                position: absolute;
                z-index: 9;
                background: #12121b;
                width: 180px;
                font-size: 14px;
                margin-left: 20px;

                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 10px 0;

                    li{
                        a {
                            color: #999;
                            text-decoration: none;
                            display: block;
                            padding: 5px 15px;
                        }
                    }
                }
            }

            #chatbox {
                .overlay_msg.chat_block {
                    margin-top: 38px;

                    .overlay_msg_action {
                        margin: 7px 10px 0 0;
                        float: right;
                    }
                    .overlay_msg_inner {
                        background: rgba(0, 0, 0, 0.3);
                        padding: 10px 0;
                        margin: 0px;
                    }
                    .overlay_msg_content {
                        width: 215px;
                        float: left;
                        text-align: left;
                        padding-left: 10px;
                        p {
                            font-size: 13px;
                            color: #b4bad0;
                        }
                    }

                }
                
            }

        }

        .channels__left {
            width: 100%;
            position: fixed;
            bottom: 0px;
            z-index: 99999;

            h4 {
                display: none;
            }

            .channels__playlist {
                display: none;
            }

            .channels__player {
                height: 110px;

                .channelPlayer__volume__control{
                    display: none;
                }

                .channels__player__songInfo {

                    h3 {
                        margin: 0;
                        margin-bottom: 20px;
                    }

                    .song__actions__confirm {
                        width: 100%;
                    }
                }

                .channels__player__inner .playerBG {
                    position: absolute;
                    height: 109px;
                    left: 0;
                    right: auto;
                    z-index: 1;
                    width: 100%;
                    &:before{
                        display: none;
                    }
                    img {
                        max-height: initial;
                        margin-top: -70px;
                    }
                }

                .channels__player__overlay {
                    height: 110px;
                    background: #131521;
                    border-top: 1px solid #333;
                }

                .channelFullScreen__btn{
                    z-index: 5;
                }
                
                &.channels__player--mobileFull  {
                    height: 200px;

                    .channels__player__songInfo{
                        padding-bottom: 0;
                    }
                    .channels__player__overlay {
                        background: none;
                        bottom: 0;
                        border: none;
                        height: 200px;
                    }

                    .channelFullScreen__btn {
                        bottom: 170px;
                        top: auto;
                    }
                }

            }

        }
    }


    //STATION
    &.page-station{

        &.station-active{
            main {
                margin-top: 45px;
                height: calc(100vh - 65px);
                overflow: hidden;
                position: relative;
                z-index: 22;
            }
            .playlists__menu {
                display: none;
            }
        }

        #RightSidebar {
            display: none;
        }
        .station {
            width: 100%;
            float: left;

            .station__guest .station__right .stationUsers {
                right: 15px;
            }

            .stationActiveCheck.custom_checkbox {
                right: 5px;
                width: 50px;
                margin-top: 25px;
                span {
                    width: 110px;
                    display: none;
                }
                label {
                    margin-left: 0;
                }                
            }
            .mobile_actions{
                .mobile_actions__up {
                    margin-right: 10px;
                }
                .mobile_actions__down {
                    margin-right: 50px;
                }

                .song__actions__confirm.song__actions__confirm-copy {
                    position: fixed;
                    top: 30px;
                    width: 92%;
                    background: #2a2e41;
                    top: 20%;
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                    padding: 20px;
                    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
                    border-radius: 5px;

                    .rc-select-selection {
                        width: 100%;
                        height: 34px;
                    }
                    .minPlaylistSelect {
                        width: 100%!important;
                        margin-top: 20px;
                        padding: 10px;
                    }
                    .rc-select-selection div {
                        float: none;
                    }
                    .rc-select-selection .rc-select-selection-selected-value {
                        padding: 2px;
                    }
                    button {
                        background: rgba(0, 0, 0, 0.2);
                        padding: 8px 20px;
                        border-radius: 30px;
                        min-width: 80px;
                        margin-top: 20px;
                        float: right;
                    }
                    button.confirm_yes {
                        background: linear-gradient(135deg, #704293 0%, #2a56e3 100%);
                    }
                    & > span {
                        font-size: 13px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        padding-left: 10px;
                        font-weight: bold;
                    }
                }
                

            }
            .station__left {
                width: 100%;
                
                .stationMessage {
                    width: 70%;
                    margin: 0 auto;
                    margin-top: 100px;
                    line-height: 1.6em;
                }

                .station__playlist {
                    display: none;
                    box-shadow: -4px 3px 10px rgba(0, 0, 0, 0.2) inset;
                    .song {
                        .song__selected__icon {
                            position: absolute;
                            width: 35px;
                            height: 35px;
                            background: #fff;
                            border-radius: 50%;
                            padding-top: 6px;
                            text-align: center;
                            margin-top: -2px;
                            margin-left: -2px;
                            color: #6ee7b8;
                        }
                        &.song--mobileSelected {
                            background: rgba(173, 160, 255, 0.21);
                        }
                        .song__actions{
                            right: 50px;
                            .song__actions__more {
                                display: none;
                            }
                            .song__actions__all {
                                right: -40px;
                                top: 35px;
                            }

                        }
                    }
                }
            }
            
            .station__right {
                width: 100%;
                float: left;
                position: relative;
                z-index: auto;

                .stationNotif{
                    position: fixed;
                    bottom: 150px;
                }

                .stationUsers {
                    position: fixed;
                    z-index: 99;
                    right: 55px;
                    top: 92px;
                    .usersMini{
                        border-radius: 4px 4px 0 0;
                    }
                    .stationUsers__all{
                        right: -45px;
                    }
                }

                #chatbox{
                    display: none;
                    
                    h4 {
                        display: none;
                    }
                }


                #chatbox {
                    .channels__chat__msgs > div {
                        height: calc(100vh - 370px);
                    }
                    .channels__chat__form {
                        bottom: 0;
                    }
                }
            }

            .station__inner--mobile__chat{
                .station__right {
                    padding-top: 0;
                    height: calc(100vh - 280px);
                    #chatbox{
                        display: block;
                        .emoji-mart{
                            z-index: 999;
                        }
                    }
                }

            }

            .station__inner--mobile__queue{
                .station__left {
                    .station__playlist{
                        display: block;
                    }
                    
                }
            }

            .station__mobile__tabs {
                position: fixed;
                bottom: 110px;
                width: 100%;
                background: #141622;
                button {
                    width: 50%;
                    padding: 12px;
                    text-align: center;
                    border: none;
                    color: rgba(255, 255, 255, 0.6);
                    font-size: 12px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    span {
                        width: 20px;
                        height: 13px;
                        background: #d3496c;
                        display: inline-block;
                        font-size: 10px;
                        border-radius: 2px;
                        color: #fff;
                        position: relative;
                        top: -1px;
                    }
                }
                &.station__mobile__tabs__queue .station__mobile__tabs__queue, &.station__mobile__tabs__chat .station__mobile__tabs__chat {
                    background: #191c2c;
                    border-bottom: 2px solid #5f56c0;
                }
            }
            

            .station__player{
                z-index: 9999;
                
                .player__nowplaying {
                    display: none;
                }

                .stationPuseInfo__mobile {
                    position: absolute;
                    font-size: 12px;
                    color: #776ec6;
                    width: 180px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    text-align: center;
                    padding-top: 7px;
                    p {
                        margin: 0;
                    }
                }

                .station__player__wrap {
                    bottom: auto;
                    height: 180px;
                    width: 100%;
                    top: 0;
                    position: fixed;

                    .station__player__inner, .station__player__block__paused, .station__player__block {
                        height: 180px;
                        width: 100%;
                    }
                    &.station__player__wrap--hidden{
                        display: none;
                    }
                    .soundCloudCover {
                        width: 100%;
                    }
                    .channelMaxScreen__btn{
                        display: none;
                    }
                    .channelFullScreen__btn{
                        display: block;
                        right: 10px;
                    }
                } 

                .station__bottom{
                    .station__bottom__addModal {
                        width: 250px;
                        left: 20px;
                        button {
                            padding: 12px 6px;
                        }
                    }                    
                    .station__bottom__inner {
                        width: 100%;
                        left: 0;
                        padding: 25px 15px;

                        .player__video__button {
                            position: absolute;
                            top: 7px;
                            margin-left: 0px;
                            padding: 5px 10px;
                            left: 0;
                            background: rgba(215, 212, 255, 0.07);
                            border-radius: 0 4px 4px 0;
                        }
                    }
                    .player__duration {
                        top: 15px;
                        right: 15px;
                        font-size: 13px;
                    }

                    .PlaylistSelector {
                        left: -30px;
                    }

                    .SoundCloudImporter{
                        .Modal__content {
                            padding: 20px 15px;
                        }
                        
                        .SoundCloudImporter__song {
                            padding: 10px;
                            .SoundCloudImporter__song__cover{
                                width: 80px;
                                height: 80px;
                            }
                            button{
                                width: 100%;
                                padding: 5px 7px;
                            }
                        }
                    }
                }

            }
        }



    } 

    //ToastBox
    .ToastBox {
        width: 85%;
    }
  
}